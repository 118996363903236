import React, { createContext, ReactNode, useContext, useEffect } from "react";
import { useScript } from "../utils";

export type TrustPilotState = {
  isLoaded: boolean;
  isErrored: boolean;
  businessUnitId: string;
};

export type TrustPilotProps = {
  // identifies the business itself, passed from process.env
  businessUnitId: string;
  children: ReactNode;
};

const TrustPilotContext = createContext<TrustPilotState>({
  isLoaded: false,
  isErrored: false,
  businessUnitId: "",
});

// TrustPilot script should only be injected into the window scope _once_
// Use at entry-point of like ThemeProvider
export const TrustPilotProvider = ({
  children,
  businessUnitId,
}: TrustPilotProps) => {
  const { status } = useScript(
    "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
  );

  useEffect(() => {
    if (status === "error")
      console.error("An error occured attempting to load TrustPilot script");
  }, [status]);

  return (
    <TrustPilotContext.Provider
      value={{
        isLoaded: status === "ready",
        isErrored: status === "error",
        businessUnitId,
      }}
    >
      {children}
    </TrustPilotContext.Provider>
  );
};

export const useTrustPilot = () => useContext(TrustPilotContext);
