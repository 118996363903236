import { WidgetControl } from "../types";

export const controlChatWidget = (operation: WidgetControl) => {
  const chat = window["HubSpotConversations"];

  if (chat) {
    chat.widget[operation]();
  } else {
    window["hsConversationsOnReady"] = [() => chat.widget[operation]()];
  }
};
