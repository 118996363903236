import { User } from "@ecologi/shared";
import ShoppingBagIcon from "@ecologi/assets/icons/impact-shop/shopping-bag.svg";
import { NavItemType } from "@ecologi/react-components/src/NavigationDrawer/navigation";
import { accountDetailsPageTitle } from "../../features/profile/account-details/account-details-page-title";

const isProduction = process.env.NEXT_PUBLIC_DEPLOYMENT === "production";

export const privateProfileNavConfig = (
  user: User,
  productsInBasket?: number
): NavItemType[][] => {
  const subMenuItems = [
    {
      id: "account-details",
      label: accountDetailsPageTitle(user),
      path: `/account/details`,
      route: `/account/details`,
    },
    {
      id: "profile-settings",
      label: "Profile settings",
      path: `/account/settings`,
      route: `/account/settings`,
    },
    {
      id: "billing",
      label: "Billing",
      path: `/account/billing`,
      route: `/account/billing`,
    },
    {
      id: "manage-subscription",
      label: "Manage subscription",
      path: `/account/manage-subscription`,
      route: `/account/manage-subscription`,
    },
    {
      id: "certificates",
      label: "Certificates",
      path: `/account/certificates`,
      route: `/account/certificates`,
    },
  ];

  return [
    [
      {
        id: "profile",
        label: "Your profile",
        path: user.getProfilePath(),
        route: user.isChildProfile
          ? `/[usernameOrId]/[childUsernameOrId]`
          : `/[usernameOrId]`,
      },
      {
        id: "dashboard",
        label: "Dashboard",
        path: `/dashboard`,
        route: `/dashboard`,
      },
      {
        id: "project-insights",
        label: "Project insights",
        path: `/project-insights`,
        route: `/project-insights`,
      },
      {
        id: "impact-shop",
        label: "Impact shop",
        path: `/impact-shop`,
        route: `/impact-shop`,
        icon: {
          svg: <ShoppingBagIcon />,
          label: productsInBasket > 0 && productsInBasket,
          path: productsInBasket > 0 ? "/impact-shop/basket" : "/impact-shop",
          isHoverDisabled: productsInBasket === 0,
        },
      },
      {
        id: "badges",
        label: "Badges",
        path: `/${user.username}/badges`,
        route: `/[usernameOrId]/badges`,
      },
      ...(user.isBusinessType
        ? [
            {
              id: "business-toolkit",
              label: "Business toolkit",
              path: `/business-toolkit`,
              route: `/business-toolkit`,
            },
            {
              id: "impact-api",
              label: "Impact API",
              path: `/impact-api`,
              route: `/impact-api`,
            },
          ]
        : []),
      {
        id: "account-settings",
        label: "Account",
        path: `/account`,
        route: `/account`,
        subMenuItems,
      },
    ],
    ...(user.isBusinessType
      ? [
          [
            {
              id: "ecologi-zero",
              label: "Ecologi Zero",
              path: isProduction
                ? "https://zero.ecologi.com/api/landing-redirect"
                : "https://x.zero.ecologi.com/api/landing-redirect",
            },
          ],
        ]
      : []),
    [
      {
        id: "help",
        label: "Help centre",
        path: "https://help.ecologi.com/",
        route: "https://help.ecologi.com/",
      },
    ],
    [
      {
        id: "logout",
        label: " Logout",
        path: "/api/logout",
        route: `/api/logout`,
      },
    ],
  ];
};
