import React, { createContext, ReactNode, useContext, useEffect } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { UseLoadScriptOptions } from "@react-google-maps/api/dist/useJsApiLoader";

export type GoogleMapsState = {
  isLoaded: boolean;
  loadError?: Error;
};

const GoogleMapsContext = createContext<GoogleMapsState>({ isLoaded: false });

// Google Maps API should only be injected into the window scope _once_
// repeat calls of useJsApiLoader or LoadScript will crash the application
// Use at entry-point of like ThemeProvider
export const GoogleMapsProvider = ({
  children,
  ...loadScriptOptions
}: UseLoadScriptOptions & { children: ReactNode }) => {
  const { isLoaded, loadError } = useJsApiLoader(loadScriptOptions);

  useEffect(() => {
    if (loadError)
      console.error(
        "An error occured attempting to load Google Maps API",
        loadError
      );
  }, [loadError]);

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);
