import React, { useState } from "react";
import classnames from "classnames";
import { TextField } from "../../TextField";
import { Button } from "../../Button";
import styles from "../styles/EmailPopup.module.scss";

type Props = {
  isVisible: boolean;
  hide: () => void;
  isAddingEmail: boolean;
  addEmailToNewsletter: (props: { email: string }) => Promise<any>;
};

export const EmailPopup: React.FC<Props> = ({
  isVisible,
  addEmailToNewsletter,
  hide,
  isAddingEmail,
}) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [inputError, setInputError] = useState("");

  return (
    <div
      className={classnames(styles.background, { [styles.show]: isVisible })}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h3 className={styles.title}>Stay in touch with Ecologi</h3>
          <p className={styles.text}>
            From thought leadership to impact updates and information about
            climate solutions for our planet. This includes our monthly
            newsletter. Unsubscribe anytime.
          </p>

          <form
            className={styles.form}
            onSubmit={async (e) => {
              e.preventDefault();
              const email = e.currentTarget.email.value;

              if (email.length < 3) {
                setInputError("Please enter a valid email address");
                return;
              }

              await addEmailToNewsletter({ email });
            }}
          >
            <TextField
              label="Email address"
              type="email"
              name="email"
              isInvalid={!!inputError}
              errorText={inputError}
              value={emailAddress}
              onChange={(event) => {
                setEmailAddress(event.target.value);
                setInputError("");
              }}
            />
            <Button
              blockOnMobile
              disabled={isAddingEmail}
              extra={{ style: { marginTop: "14px", height: "45px" } }}
              loading={isAddingEmail}
              primary
              small
              submit
              text="Subscribe and plant a tree"
            />
          </form>
        </div>
        <div className={styles.treePlanted}>
          <p className={styles.treePlantedText}>
            By subscribing we'll plant a tree on your behalf
          </p>
        </div>
        <button
          aria-label="popup close"
          onClick={hide}
          className={styles.closeButton}
        />
      </div>
    </div>
  );
};
