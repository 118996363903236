import React from "react";
import LargeSpinnerSvg from "@ecologi/assets/icons/spinner-large.svg";
import SmallSpinnerSvg from "@ecologi/assets/icons/spinner-small.svg";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const SpinnerBaseCss = css`
  @keyframes infiniteRotate {
    to {
      transform: rotate(360deg);
    }
  }
  animation: infiniteRotate 0.8s linear infinite;
`;

const LargeSpinner = styled(LargeSpinnerSvg)`
  fill: ${(props) => props.theme.colors.jungle};
  height: 4.4rem;
  width: 4.4rem;

  ${SpinnerBaseCss}
`;

const SmallSpinner = styled(SmallSpinnerSvg)`
  fill: ${(props) => props.theme.colors.jungle};
  height: 2rem;
  width: 2rem;

  ${SpinnerBaseCss}
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999998;
`;

const Wrapper = styled.div<{ isOverlay?: boolean }>`
  ${(props) =>
    props.isOverlay &&
    css`
      position: fixed;
      top: 50%;
      left: 50%;
      translate3d(-50%, -50%, 0);
      z-index: 1000;
    `}
`;

export type SpinnerProps = {
  isLarge?: boolean;
  isOverlay?: boolean;
  className?: string;
};

export const Spinner = ({ isLarge, isOverlay, className }: SpinnerProps) => {
  return (
    <>
      {isOverlay && <Overlay />}
      <Wrapper isOverlay={isOverlay} role="status" aria-busy="true">
        {isLarge ? (
          <LargeSpinner className={className} />
        ) : (
          <SmallSpinner className={className} />
        )}
      </Wrapper>
    </>
  );
};
