import { Location, User } from "@ecologi/shared";
import { Stripe } from "@stripe/stripe-js";
import create, { SetState, UseBoundStore } from "zustand";
import { persist } from "zustand/middleware";
import { isBrowser } from "../lib/utils";
import { CookieConsentStatus, CreateSetters, State, Store } from "./index.d";

export const initialState: State = {
  affiliateClickId: "",
  referrerUserId: undefined,
  location: { countryCode: "GB" },
  user: undefined,
  parentUsername: undefined,
  isCheckingUserSession: true,
  hasDoneV2ReauthCheck: false,
  stripe: null,
  deferredPrompt: null,
  hasSeenEmailPopup: false,
  cookieConsentStatus: CookieConsentStatus.UNKNOWN,
  refetchUser: () => {},
  flags: null,
};

const createSetters: CreateSetters = (set) => ({
  setLocation: (location) => set({ location }),
  setUser: (user, extra = {}) => set({ user, ...extra }),
  setStripe: (stripe) => set({ stripe }),
  setRefetchUser: (refetchUser) => set({ refetchUser }),
  setHasSeenEmailPopup: (hasSeenEmailPopup) => set({ hasSeenEmailPopup }),
  setReferrerUserId: (referrerUserId) => set({ referrerUserId }),
  setDeferredPrompt: (deferredPrompt) => set({ deferredPrompt }),
  setCookieConsent: (cookieConsentStatus) => set({ cookieConsentStatus }),
  setFlags: (flags) => set({ flags }),
});

let storeInitialised = false;
let useStore = (() => ({
  ...initialState,
})) as UseBoundStore<Store>;

export const partializeStoreState = (state: Store) => {
  // Limit which keys are placed into localstorage
  let result: Record<string, string | Location> = {};

  if (state.referrerUserId) {
    result = {
      ...result,
      referrerUserId: state.referrerUserId,
    };
  }

  return {
    ...result,
    location: state.location,
    hasSeenEmailPopup: state.hasSeenEmailPopup,
  };
};

const useCreateStore = (
  preloadedState: {
    user?: User;
    isCheckingUserSession?: boolean;
    location?: Location;
    stripe?: Stripe | null;
  } = initialState,
  resetState?: boolean
) => {
  if (!storeInitialised || resetState) {
    const persistMiddleware = isBrowser() ? persist : (arg: any) => arg;

    storeInitialised = true;
    useStore = create<Store>(
      persistMiddleware(
        (set: SetState<Store>) => ({
          ...initialState,
          ...preloadedState,
          ...createSetters(set),
        }),
        {
          name: "ecologi-store",
          partialize: partializeStoreState,
        }
      )
    );
  }

  return useStore;
};

export { useStore, useCreateStore };
