import React from "react";
import ReactCountryFlag from "react-country-flag";

import {
  locationCurrencyCodeMapper,
  Country,
  locationNames,
} from "@ecologi/shared";

import styles from "./CountryChangeButton.module.scss";

interface Props {
  countryCode: Country;
  isSelected?: boolean;
  onClose?: (update: { countryCode: string }) => void;
}

// TODO MOVE TO SHARED PACKAGE
const locationCodeKey = "location-code";

export const CountryChangeButton: React.FC<Props> = (props) => {
  const { countryCode, isSelected, onClose } = props;

  const name = locationNames[countryCode];

  return (
    <button
      className={styles.locationButton}
      data-is-selected={isSelected}
      onClick={(e) => {
        e.preventDefault();
        if (!!onClose) {
          localStorage.setItem(locationCodeKey, countryCode);
          onClose({ countryCode });
        }
      }}
    >
      <ReactCountryFlag
        countryCode={countryCode}
        style={{
          height: "18px",
          width: "24px",
          position: "relative",
          margin: "0 8px 0 4px",
          bottom: "2px",
        }}
        svg
      />{" "}
      {name}{" "}
      <span className={styles.currency}>
        ({locationCurrencyCodeMapper[countryCode]})
      </span>
    </button>
  );
};
