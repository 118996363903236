import React, { useState } from "react";
import { CookieBanner } from "./CookieBanner";
import { ManagePreferences } from "./ManagePreferences";

export interface CookieConsentProps {
  isOpen: boolean;
  onReject: () => void;
  onAccept: () => void;
}

export function CookieConsent({ isOpen, ...props }: CookieConsentProps) {
  const [preferencesOpen, setPreferencesOpen] = useState(false);

  return isOpen ? (
    preferencesOpen ? (
      <ManagePreferences
        {...props}
        onSavePreferences={(preferencesChecked) => {
          if (preferencesChecked) {
            props.onAccept();
          } else {
            props.onReject();
          }
        }}
        setPreferencesOpen={setPreferencesOpen}
      />
    ) : (
      <CookieBanner
        {...props}
        onViewPreferences={() => setPreferencesOpen(true)}
      />
    )
  ) : null;
}
