import React from "react";
import CloseIcon from "@ecologi/assets/icons/close-light.svg";
import styled from "@emotion/styled";

const StyledButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  user-select: none;
  color: ${({ theme }) => theme.colors.charcoal};
  line-height: 1;

  &:hover {
    color: ${({ theme }) => theme.colors.charcoalLight};
  }

  svg {
    vertical-align: middle;
    margin-top: -4px;
    margin-left: 4px;
    width: 24px;
    height: 24px;
  }
`;

export const CloseButton = ({
  closeModal,
  testId = "forest-modal",
  title = "Close tree information box",
}: {
  closeModal: () => void;
  testId?: string;
  title?: string;
}) => (
  <StyledButton
    aria-label="close"
    data-testid={`${testId}-close-button`}
    onClick={(e) => {
      e.preventDefault();
      e.currentTarget.blur();
      closeModal();
    }}
    title={title}
  >
    Close
    <CloseIcon />
  </StyledButton>
);
