import { Banner as BannerComponent } from "@ecologi/react-components/src/Banner";
import { useRouter } from "next/router";
import { forwardRef, Ref } from "react";
import { useQuery } from "react-query";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { APIError, getBanners } from "../../lib";
import { useStore } from "../../store";

const BANNER_HAS_DIMISSED_KEY = "ecologi-banner-has-dismissed-11-04-2023";

export const Banner = forwardRef(function Banner(_, ref: Ref<HTMLDivElement>) {
  const router = useRouter();
  const { user, isCheckingUserSession } = useStore();
  const [hasDismissedBanner, setHasDismissedBanner] = useLocalStorage(
    BANNER_HAS_DIMISSED_KEY,
    false
  );

  const handleClose = () => {
    setHasDismissedBanner(true);
  };

  const result = useQuery<
    { content: string; isGuestOnly?: boolean; hideOnPages?: string[] },
    APIError
  >("banners", getBanners, {
    retry: false,
    refetchOnWindowFocus: false,
    initialStale: true,
  });

  // Do not return null because we need the ref to track changes to the banner's height
  if (
    !result.data ||
    (result.data.isGuestOnly && (isCheckingUserSession || user)) ||
    (result.data.hideOnPages &&
      result.data.hideOnPages.includes(router.asPath.substring(1))) ||
    hasDismissedBanner
  ) {
    return <div ref={ref} />;
  }

  return (
    <BannerComponent
      content={result.data.content}
      onClose={handleClose}
      ref={ref}
    />
  );
});
