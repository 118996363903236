import { Location } from "@ecologi/shared";
import fetch from "cross-fetch";

export async function getCountryCode(): Promise<Location | undefined> {
  try {
    const response = await fetch(
      `https://pro.ip-api.com/json?key=YjDyXsuus4CB9Zz&fields=countryCode`
    );

    if (!response.ok) throw Error("Failed to retrieve country from Pro IP");

    const data = await response.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}
