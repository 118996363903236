import React from "react";
import { useRouter } from "next/router";
import NextLink, { LinkProps } from "next/link";

type Props = {
  children?: any;
  activeClassName?: string;
  className?: string;
  id?: string;
  title?: string;
  rel?: string;
  target?: string;
  style?: object;
  "data-cog"?: boolean;
  "data-back-arrow"?: boolean;
  onClick?: (e: any) => void;
} & LinkProps;

export const Link = React.forwardRef<HTMLAnchorElement | null, Props>(
  (
    {
      children,
      activeClassName,
      className,
      href,
      as,
      replace = false,
      scroll = true,
      shallow,
      passHref,
      prefetch = false,
      ...props
    }: Props,
    ref
  ) => {
    const router = useRouter();

    const updatedClassName =
      router?.asPath === href || router?.asPath === as
        ? `${className} ${activeClassName}`.trim()
        : className;

    if (typeof href === "string" && href.startsWith("/api/")) {
      return (
        <a href={href} {...props} ref={ref} className={updatedClassName}>
          {children}
        </a>
      );
    }

    return (
      <NextLink
        href={href}
        as={as}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
        prefetch={prefetch}
        legacyBehavior
      >
        <a {...props} ref={ref} className={updatedClassName}>
          {children}
        </a>
      </NextLink>
    );
  }
);
