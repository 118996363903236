import { init } from "./datadog";
import { datadogLogs } from "@datadog/browser-logs";
import { serializeError } from "serialize-error";
import { isBrowser } from "../utils";

interface Logger {
  error(message: string, meta?: any): void;
  warn(message: string, meta?: any): void;
  info(message: string, meta?: any): void;
  addLoggerMetadata(meta: Record<string, unknown>): void;
}

// In local development, log to the console for ease of debugging.
// In production, log to Datadog from the browser, and to the console in SSR.
// SRR logs will go to Cloudwatch where they will get forwarded to Datadog.
const logger: Logger =
  isBrowser() && process.env.NODE_ENV !== "development"
    ? createDatadogLogger()
    : createConsoleLogger();

function createDatadogLogger(): Logger {
  init();
  return {
    info: (message, meta) => {
      datadogLogs.logger.info(message, meta);
    },
    warn: (message, meta) => {
      datadogLogs.logger.warn(message, meta);
    },
    error: (message, meta) => {
      datadogLogs.logger.error(message, meta);
    },
    addLoggerMetadata(meta: Record<string, unknown>) {
      Object.entries(meta).forEach(([key, value]) =>
        datadogLogs.logger.setContextProperty(key, value)
      );
    },
  };
}

function createConsoleLogger(): Logger {
  const baseMetadata: Record<string, string> = {};
  return {
    error(message, meta) {
      console.error(
        `[ERROR] ${message} ${JSON.stringify(
          processErrorsInMeta(baseMetadata, meta)
        )}`
      );
    },
    info(message, meta) {
      console.log(
        `[INFO] ${message} ${JSON.stringify(
          processErrorsInMeta(baseMetadata, meta)
        )}`
      );
    },
    warn(message, meta) {
      console.warn(
        `[WARN] ${message} ${JSON.stringify(
          processErrorsInMeta(baseMetadata, meta)
        )}`
      );
    },
    addLoggerMetadata(meta: Record<string, string>) {
      Object.assign(baseMetadata, meta);
    },
  };
}

function processErrorsInMeta(baseMetadata: Record<string, string>, meta?: any) {
  return {
    ...baseMetadata,
    ...(meta && {
      ...meta,
      ...(meta.error && { error: serializeError(meta.error) }),
    }),
  };
}

export default logger;
