import { fetchFromAPI } from "./fetch-from-api";

export const addEmailToNewsletter = async ({
  email,
}: {
  email: string;
}): Promise<any> => {
  return await fetchFromAPI(`/newsletter`, {
    method: "POST",
    body: JSON.stringify({ emailAddress: email }),
  });
};
