import React from "react";
import styled from "@emotion/styled";
import { Button } from "../Button";
import { Typography } from "../Typography";

const BannerContainer = styled.div`
  position: fixed;
  z-index: 99999998;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  width: 100%;
  max-height: 100%;
  overflow: auto;
`;

const BannerInner = styled.div<{ light?: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) =>
    props.light ? props.theme.colors.snowdrop : props.theme.colors.charcoal};
  font-size: 15px;
  line-height: 1.4;
  padding: 24px;

  @media ${(props) => props.theme.bp.desktopUp} {
    max-width: 1000px;
    margin: auto;
    margin-bottom: 32px;
  }
`;

const AcceptButton = styled(Button)`
  padding-left: 30px;
  padding-right: 30px;
`;

const RejectButton = styled(Button)`
  color: ${(props) => props.theme.colors.snowdrop};
  margin-right: 16px;
  opacity: 0.6;

  &:hover {
    opacity: 0.8;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
`;

const FooterRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  @media ${(props) => props.theme.bp.tabletUp} {
    justify-content: flex-end;
  }
`;

const Text = styled(Typography)<{ light?: boolean }>`
  color: ${(props) =>
    props.light ? props.theme.colors.charcoal : props.theme.colors.snowdrop};
`;

type BannerProps = {
  header: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
  light?: boolean;
};

export function Banner(props: BannerProps) {
  return (
    <BannerContainer>
      <BannerInner light={props.light}>
        <HeaderRow>{props.header}</HeaderRow>
        {props.children}
        <FooterRow>{props.footer}</FooterRow>
      </BannerInner>
    </BannerContainer>
  );
}

Banner.AcceptButton = AcceptButton;
Banner.RejectButton = RejectButton;
Banner.Text = Text;
