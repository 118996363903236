import * as bp from "./ecologi-breakpoints";
import * as colors from "./ecologi-colors";
import * as typography from "./ecologi-typography";
import * as variables from "./ecologi-variables";

export interface EcologiTheme {
  bp: {
    extraWideDesktopUp: string;
    veryWideDesktopUp: string;
    widerDesktopUp: string;
    wideDesktopUp: string;
    wideDesktopDown: string;
    desktopUp: string;
    desktopDown: string;
    tabletUp: string;
    tabletDown: string;
    mobileUp: string;
    mobileDown: string;
    breakExtraWideDesktop: string;
    breakVeryWideDesktop: string;
    breakWiderDesktop: string;
    breakWideDesktop: string;
    breakDesktop: string;
    breakTablet: string;
    breakMobile: string;
    breakSmallMobile: string;
    smallMobileUp: string;
    /* WRAPPER COMPONENT BREAKPOINTS */
    extraSmallUp: string;
    extraSmallDown: string;
    breakExtraSmall: string;
    dashboardPageDesktop: string;
  };
  colors: {
    // Colours
    arctic: string;
    arcticDark: string;
    arcticLight: string;
    arcticLighter: string;
    buttercup: string;
    buttercupDark: string;
    buttercupLight: string;
    buttercupLighter: string;
    charcoal: string;
    charcoalLight: string;
    cloud: string;
    cloudDark: string;
    cloudDarker: string;
    fern: string;
    fernDark: string;
    forest: string;
    forestLight: string;
    forestDark: string;
    jungle: string;
    jungleDark: string;
    jungleLight: string;
    jungleLighter: string;
    lavender: string;
    lavenderDark: string;
    lavenderLight: string;
    lavenderLighter: string;
    mist: string;
    mistDark: string;
    mistDarker: string;
    mistLighter: string;
    mistLight: string;
    mushroom: string;
    mushroomDark: string;
    mushroomDarker: string;
    mushroomLight: string;
    mushroomLighter: string;
    night: string;
    rose: string;
    roseDark: string;
    roseLight: string;
    roseLighter: string;
    scarlet: string;
    scarletDark: string;
    snowdrop: string;
    spruce: string;

    transparent: string;
    lightGreen: string;
    darkBlue: string;
    midnight: string;
    // Need to decide replacement
    darkCream: string;
    darkGrey: string;
    turquoise: string;
    // Gradients
    profileSky: string;
    homepageSky: string;
    // Helper
    opacity: (color: string, opacity: number) => string;
  };
  typography: {
    heading: typography.Heading;
    fontStack: string;
  };
  variables: {
    lbCardWidth: string;
    extraLargeMaxWidth: string;
  };
}

export const theme = {
  bp,
  colors,
  typography,
  variables,
} as EcologiTheme;
