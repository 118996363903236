import styled from "@emotion/styled";
import React from "react";
import { NavigationTagType } from "../NavigationDrawer/navigation";

export type NavigationTagProps = Pick<NavigationTagType, "themeColor">;

const Tag = styled.strong<NavigationTagProps>`
  background-color: ${(props) =>
    props.theme.colors[props.themeColor ?? "jungleDark"]};
  color: ${({ theme }) => theme.colors.snowdrop};
  text-transform: uppercase;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 800;
  line-height: 16px;
`;

export const NavigationTag = ({
  children,
  themeColor,
}: NavigationTagProps & React.PropsWithChildren) => {
  return <Tag themeColor={themeColor}>{children}</Tag>;
};
