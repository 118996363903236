import React from "react";
import { Transition } from "react-transition-group";
import styled from "@emotion/styled";

const ANIMATION_TIME_MS = 150;

const ScrimStyles = styled.div`
  z-index: 998;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.night};
  transition: opacity ${ANIMATION_TIME_MS}ms linear;
  opacity: 0;
`;

const transitionStyles = {
  entering: { opaticy: 0 },
  entered: { opacity: 0.4 },
  exiting: { opacity: 0.4 },
  exited: { opaticy: 0 },
};

export function Scrim({ isOpen, ...other }: any) {
  return (
    <Transition in={isOpen} unmountOnExit timeout={ANIMATION_TIME_MS}>
      {(state) => (
        <ScrimStyles
          style={transitionStyles[state]}
          role="presentation"
          aria-hidden={isOpen ? "false" : "true"}
          {...other}
        />
      )}
    </Transition>
  );
}
