import { useCallback, useEffect, useState, useMemo } from "react";
import { useMutation } from "react-query";
import { ToastContainer, Slide, toast } from "react-toastify";
import { useRouter } from "next/router";
import useMeasure from "react-use/lib/useMeasure";
import { EmailPopup } from "@ecologi/react-components/src/EmailPopup";
import { NavigationDrawer } from "@ecologi/react-components/src/NavigationDrawer";
import { Banner } from "./banner";
import { UnderMaintenance } from "@ecologi/react-components/src/UnderMaintenance";
import {
  APIError,
  addEmailToNewsletter,
  pushAddToMailingList,
  parseQueryObject,
} from "../../lib";
import { useStore } from "../../store";
import { useEmailPopup } from "./use-email-popup";
import {
  loggedInMarketingNavConfig,
  publicMarketingNavConfig,
} from "./marketing-navigation-config";
import { privateProfileNavConfig } from "./private-profile-navigation-config";
import { useFetchImpactShopStore } from "../../store/impact-shop";
import { openChatWidget } from "../../lib/hubspot";
import { NavItemType } from "@ecologi/react-components/src/NavigationDrawer/navigation";
import dynamic from "next/dynamic";

// Dynamic import the Footer component with ssr: false to avoid hydration issues
const Footer = dynamic(
  () => import("@ecologi/react-components/src/Footer").then((m) => m.Footer),
  {
    ssr: false,
  }
);

// List of routes in which we hide the footer and header
export const footerDenyList = [
  "/thanks",
  "/login",
  "/breathe",
  "/email-confirmation",
];

const useImpactShopStore = useFetchImpactShopStore();

type LayoutProps = {
  children: React.ReactNode;
  hideHeader?: boolean;
  isLightHeader: boolean;
  isProfile: boolean;
  isWhiteBody: boolean;
  navColour?: string;
};

export function Layout({
  children,
  hideHeader,
  isLightHeader,
  isProfile,
  isWhiteBody,
  navColour,
  ...props
}: LayoutProps) {
  const router = useRouter();
  const {
    location,
    setLocation,
    user,
    setHasSeenEmailPopup,
    setReferrerUserId,
  } = useStore();
  const [isEmailPopupVisible, setEmailPopupVisibility] = useEmailPopup();
  const [isLoggedViewingProfile, setIsLoggedViewingProfile] = useState(false);
  const [bannerRef, { height: bannerHeight }] = useMeasure();
  const { totalNumberOfProductsInBasket } = useImpactShopStore();
  const productsInBasket = totalNumberOfProductsInBasket();

  const { usernameOrId, childUsernameOrId } = router.query;
  const routeUsernameOrId = childUsernameOrId || usernameOrId;
  const isAnothersProfile = user
    ? isProfile && !!routeUsernameOrId && routeUsernameOrId !== user.username
    : false;

  useEffect(() => {
    /* Fixes issue with hamburger menu showing whilst the user profile is loading after logging in. */
    if (router.pathname.includes("/profile")) {
      setIsLoggedViewingProfile(false);
    } else {
      setIsLoggedViewingProfile(!!user && !!isProfile && !isAnothersProfile);
    }
  }, [router, user, isProfile, isAnothersProfile]);

  const closeEmailPopup = useCallback(() => {
    setHasSeenEmailPopup(true);
    setEmailPopupVisibility(false);
  }, [setHasSeenEmailPopup, setEmailPopupVisibility]);

  const { r: referrer } = parseQueryObject(router.query);

  useEffect(() => {
    if (referrer) {
      setReferrerUserId(referrer);
    }
  }, [referrer]);

  const [addEmailToNewsletterMutation, emailNewsletterResult] = useMutation(
    addEmailToNewsletter,
    {
      onSuccess: () => {
        toast.success("Your email address was added. Thank you!");

        closeEmailPopup();
        pushAddToMailingList();
      },
      onError: (error: APIError) => {
        toast.error(error.getFirstError());
      },
    }
  );

  const isFooterVisible = !footerDenyList.includes(router.pathname);
  const isMigrating =
    user?.isBeingMigratedToV2 && router.pathname.startsWith("/account");

  const navItems = useMemo(
    () =>
      isLoggedViewingProfile
        ? privateProfileNavConfig(user, productsInBasket)
        : user
        ? loggedInMarketingNavConfig(user, productsInBasket)
        : publicMarketingNavConfig(productsInBasket),
    [isLoggedViewingProfile, user, isProfile, productsInBasket]
  );

  const selectedNavItem: { id: string } = useMemo(() => {
    return navItems.flat().find(({ route, subMenuItems }: NavItemType) => {
      const pathWithoutQuery = router.asPath.split("?")[0];
      const isRouteMatch = route === pathWithoutQuery;
      const isSubMenuMatch = subMenuItems?.find(
        ({ route: subRoute }) => subRoute === pathWithoutQuery
      );
      return isRouteMatch || isSubMenuMatch;
    });
  }, [router.asPath, navItems]);

  return (
    <>
      <Banner ref={bannerRef} />

      <NavigationDrawer
        {...props}
        currentPath={router.pathname}
        displayName={user ? user.fullName() : null}
        isLight={isLightHeader}
        hideHeader={hideHeader}
        isLoggedViewingProfile={isLoggedViewingProfile}
        isOverlayNav={!isLoggedViewingProfile}
        location={location}
        navColour={isMigrating ? "white" : navColour}
        navItems={navItems}
        selectedRootNavItemId={selectedNavItem ? selectedNavItem.id : ""}
        setUserLocation={setLocation}
        offsetTop={bannerHeight}
      >
        {isMigrating ? <UnderMaintenance /> : children}
        {isFooterVisible && (
          <Footer
            {...props}
            user={user}
            setUserLocation={setLocation}
            location={location}
            openChatWidget={() => openChatWidget(router)}
          />
        )}
      </NavigationDrawer>

      <ToastContainer
        autoClose={4000}
        closeButton={false}
        hideProgressBar
        position="bottom-center"
        transition={Slide}
      />

      <EmailPopup
        isAddingEmail={emailNewsletterResult.isLoading}
        isVisible={isEmailPopupVisible}
        hide={closeEmailPopup}
        addEmailToNewsletter={addEmailToNewsletterMutation}
      />

      <div id="modal-root"></div>
    </>
  );
}
