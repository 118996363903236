import React, { useRef } from "react";
import { useButton } from "@react-aria/button";
import styled from "@emotion/styled";
import { Button } from "../Button";
import Link from "next/link";
import useMedia from "react-use/lib/useMedia";

export interface CookieConsentProps {
  onViewPreferences: () => void;
  onAccept: () => void;
}

export function CookieBanner(props: CookieConsentProps) {
  const preferencesButtonRef = useRef<HTMLButtonElement>(null);
  const acceptButtonRef = useRef<HTMLButtonElement>(null);
  const isMobile = useMedia("(max-width: 768px)");

  const { buttonProps: acceptButtonProps } = useButton(
    { onPress: props.onAccept },
    acceptButtonRef
  );

  const { buttonProps: preferencesButtonProps } = useButton(
    { onPress: props.onViewPreferences },
    preferencesButtonRef
  );

  return (
    <Container>
      <CookieWrapper>
        <Squirrel
          src="/images/app/squirrel.svg"
          alt=""
          width="100"
          height="100"
          loading="lazy"
        />

        <InfoTextWrapper>
          <Title>Our site runs on cookies. Much like we do.</Title>
          <InfoWrapper>
            <Info>
              {isMobile
                ? "See which cookies we use in our "
                : "Find out more about the cookies we use in our "}
            </Info>
            <Link href="/pages/cookie-policy">
              <Info pointer>cookie policy.</Info>
            </Link>
          </InfoWrapper>
        </InfoTextWrapper>
        <ButtonsWrapper>
          <Button
            {...acceptButtonProps}
            text="Accept all cookies"
            primary
            green
            small
            regular
            lightGreen
            ref={acceptButtonRef}
          />
          <Button
            {...preferencesButtonProps}
            text="Customise"
            border
            regular
            textOnly
            ref={preferencesButtonRef}
          />
        </ButtonsWrapper>
      </CookieWrapper>
    </Container>
  );
}

const Squirrel = styled.img`
  grid-area: squirrel;
  width: 100px;
  height: 100px;

  @media ${({ theme }) => theme.bp.tabletUp} {
    width: 120px;
    height: 120px;
  }
`;

const Container = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.mistDark};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.snowdrop};
  position: fixed;
  z-index: 99999998;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 24px;

  @media ${({ theme }) => theme.bp.desktopUp} {
    height: 144px;
  }
`;

const CookieWrapper = styled.div`
  width: fit-content;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 1fr 48px;
  grid-template-areas:
    "squirrel text"
    "button button";
  row-gap: 8px;

  @media ${({ theme }) => theme.bp.tabletUp} {
    grid-template-columns: 135px 1fr;
    grid-template-areas:
      "squirrel text"
      "squirrel button";
    row-gap: 16px;
  }

  @media ${({ theme }) => theme.bp.desktopUp} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  grid-area: button;
  justify-self: center;
  justify-content: space-around;

  button:first-of-type {
    width: 300px;
  }

  @media ${({ theme }) => theme.bp.tabletUp} {
    justify-content: initial;

    button:first-of-type {
      width: initial;
    }
  }

  @media ${({ theme }) => theme.bp.desktopUp} {
    width: fit-content;

    button:first-of-type {
      width: fit-content;
    }
  }
`;

const InfoTextWrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.fontStack};
  grid-area: text;
  align-self: center;
`;

const Title = styled.h3`
  font-weight: 800;
  font-size: 18px;

  @media ${({ theme }) => theme.bp.desktopUp} {
    font-size: 22px;
  }
`;

const InfoWrapper = styled.div`
  margin-top: 8px;
`;

const Info = styled("p")<{ pointer?: boolean }>`
  display: inline;
  cursor: ${(props) => (props.pointer ? "pointer" : "revert")};
  text-decoration: ${(props) => (props.pointer ? "underline" : "revert")};
  font-size: 14px;

  @media ${({ theme }) => theme.bp.desktopUp} {
    font-size: 16px;
  }
`;
