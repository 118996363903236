import { NextRouter } from "next/router";
import { controlChatWidget } from "./control-chat-widget";

const CONTACT_URL = "https://ecologi.com/contact";

export const openChatWidget = (router: NextRouter) => {
  const chat = window["HubSpotConversations"];

  if (!chat) {
    window.open(CONTACT_URL, "_blank", "noopener,noreferrer");
    return;
  }

  if (!chat.widget.status().loaded) {
    router.push("#help-chat");
    controlChatWidget("refresh");
  }

  controlChatWidget("open");
};
