import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { AriaDialogProps } from "@react-types/dialog";
import {
  useOverlay,
  usePreventScroll,
  useModal,
  OverlayContainer as OverlayContainerReactAria,
  OverlayProps,
} from "@react-aria/overlays";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import { useButton } from "@react-aria/button";
import CloseIconGraphic from "@ecologi/assets/icons/close-icon.svg";
import { isBrowser } from "../utils";

type Props = {
  title?: string;
  footnote?: string;
  useOnlyWrapper?: boolean;
  children: React.ReactNode | string;
  onClose: () => void;
  style?: {
    scrimOpacity?: number;
    backgroundColor?: string;
    maxWidth?: string;
  };
  isOpen: boolean;
} & AriaDialogProps &
  OverlayProps;

const ModalContainer = styled.div<{ scrimOpacity?: number }>`
  position: fixed;
  z-index: 9999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, ${(props) => props.scrimOpacity || 0.5});
`;

const Modal = styled.div<{ backgroundColor?: string; maxWidth?: string }>`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.snowdrop};

  @media ${(props) => props.theme.bp.mobileUp} {
    height: auto;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    border-radius: 6px;
    max-width: ${(props) => props.maxWidth || "62rem"};
    max-height: 100%;
    bottom: unset;
    max-height: calc(100vh - 60px);
    top: unset;
  }
`;

const ModalTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 1.6rem 2rem;
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.darkGrey};
`;

const ModalHeader = styled.h1`
  align-items: center;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
  color: ${(props) => props.theme.colors.charcoal};
  font-size: 1.6rem;
  line-height: 2.8rem;
`;

const CloseIconContainer = styled.button<{ isPressed: boolean }>`
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  position: relative;
  margin-left: 2rem;
  margin-right: -0.8rem;
  padding: 0.8rem;
  border-radius: 6px;
  outline: none;

  &:focus {
    background-color: ${(props) => props.theme.colors.mist};
  }

  ${(props) =>
    props.isPressed &&
    css`
      background-color: ${props.theme.colors.mist};
    `}
`;

const CloseIcon = styled(CloseIconGraphic)`
  display: block;
  height: 2rem;
  width: 2rem;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  fill: ${(props) => props.theme.colors.charcoal};
`;

const ModalScrollableWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-x: hidden;
`;

const ModalScrollable = styled.div<{ shadow: boolean; isHTML: boolean }>`
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  position: relative;

  margin: 0;
  font-size: 1em;
  font-weight: 400;

  ${({ isHTML, theme }) =>
    isHTML &&
    css`
      a {
        color: ${theme.colors.jungle};
      }
    `};

  &.shadow {
    box-shadow: inset 0 2rem 2rem -2rem rgba($night, 0.1),
      inset 0 -2rem 2rem -2rem rgba($night, 0.1);
  }

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: inset 0 2rem 2rem -2rem rgba(${props.theme.colors.charcoal}, 0.1),
        inset 0 -2rem 2rem -2rem rgba(${props.theme.colors.charcoal}, 0.1);
    `}
`;

const ModalScrollableInner = styled.div`
  flex: 0 0 auto;
  padding: 1.6rem 2rem;
`;

const ModalScrollableFootnote = styled.div`
  flex: 0 0 auto;
  padding: 1.6rem 2rem;
  span {
    color: $pink;
  }
  em {
    display: block;
    font-size: 14px;
    opacity: 0.6;
    padding-top: 10px;
  }
`;

function ModalInner(props: Props) {
  const { title, footnote, children, style } = props;
  const [shadow, setShadow] = useState(false);
  const closeButtonRef = useRef<any>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  /**
   * This useEffect is responsible for checking whether scrollable container
   * (ModalScrollable) has been scrolled. If yes, shadow is set to
   * true which applies a box shadow to the top of ModalScrollable
   */
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop } = scrollRef.current as HTMLDivElement;

      if (scrollTop > 0) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };

    scrollRef.current?.addEventListener("scroll", handleScroll);
    const scrollEl = scrollRef.current;

    return () => {
      scrollEl?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRef]);

  const { buttonProps, isPressed } = useButton(
    {
      onPress: () => {
        props.onClose();
      },
    },
    closeButtonRef
  );

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref: any = React.useRef();
  const { overlayProps } = useOverlay({ isDismissable: true, ...props }, ref);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, ref);

  const createMarkup = () => {
    return { __html: `${children}` };
  };

  const isHTML =
    typeof children === "string" && /<\/?[a-z][\s\S]*>/.test(children);

  return (
    <ModalContainer scrimOpacity={style?.scrimOpacity}>
      <FocusScope contain restoreFocus autoFocus>
        <Modal
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          backgroundColor={style?.backgroundColor}
          maxWidth={style?.maxWidth}
          ref={ref}
        >
          {props.useOnlyWrapper ? (
            <>{children}</>
          ) : (
            <>
              <ModalTopBar>
                <ModalHeader {...titleProps}>{title}</ModalHeader>
                <CloseIconContainer
                  {...buttonProps}
                  aria-label="Close"
                  isPressed={isPressed}
                  ref={closeButtonRef}
                >
                  <CloseIcon />
                </CloseIconContainer>
              </ModalTopBar>
              <ModalScrollableWrapper>
                <ModalScrollable
                  isHTML={isHTML}
                  shadow={shadow}
                  ref={scrollRef}
                >
                  {isHTML ? (
                    <ModalScrollableInner
                      dangerouslySetInnerHTML={createMarkup()}
                    />
                  ) : (
                    <ModalScrollableInner>{children}</ModalScrollableInner>
                  )}
                  {footnote && (
                    <ModalScrollableFootnote>
                      <em>
                        <span>*</span> {footnote}
                      </em>
                    </ModalScrollableFootnote>
                  )}
                </ModalScrollable>
              </ModalScrollableWrapper>
            </>
          )}
        </Modal>
      </FocusScope>
    </ModalContainer>
  );
}

type OverlayContainerProps = {
  children: any;
};

function OverlayContainer(props: OverlayContainerProps) {
  return isBrowser() ? (
    <OverlayContainerReactAria>{props.children}</OverlayContainerReactAria>
  ) : (
    props.children
  );
}

/**
 * @deprecated Use `<DialogModal>` instead
 */
export function ModalBase(props: Props) {
  return (
    <OverlayContainer>
      {props.isOpen && <ModalInner {...props} />}
    </OverlayContainer>
  );
}
