import React from "react";
import classnames from "classnames";
import styles from "./Wrapper.module.scss";

export type WrapperProps = {
  children: React.ReactNode;
  id?: string;
  isNewExtraLarge?: boolean;
  isExtraLarge?: boolean;
  isLarge?: boolean;
  isSmall?: boolean;
  isExtraSmall?: boolean;
  noPadding?: boolean;
  className?: string;
  style?: {};
};

export const Wrapper = (props: WrapperProps) => {
  const {
    children,
    isSmall,
    isExtraSmall,
    isLarge,
    isExtraLarge,
    isNewExtraLarge,
    noPadding,
    className,
    ...other
  } = props;

  const cls = classnames(className, styles.wrapper, {
    [styles.wrapperSmall]: isSmall,
    [styles.wrapperExtraSmall]: isExtraSmall,
    [styles.wrapperLarge]: isLarge,
    [styles.wrapperExtraLarge]: isExtraLarge,
    [styles.newWrapperExtraLarge]: isNewExtraLarge,
    [styles.wrapperNoPadding]: noPadding,
  });

  return (
    <div className={cls} {...other}>
      {children}
    </div>
  );
};
