import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useFocusWithin } from "@react-aria/interactions";
import DropdownIcon from "@ecologi/assets/icons/SmallIcon/Dropdown.svg";
import { Typography } from "../Typography";
import { NavItem, sharedMenuOptionStyles } from "./NavItem";
import { NavigationTag } from "../NavigationTag";
import { excludePropsFromForwarding } from "../utils";
import { NavigationTagType } from "./navigation";

const CollapsibleIconWrapper = styled.div`
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;

  &:hover {
    background: ${({ theme }) =>
      theme.colors.opacity(theme.colors.snowdrop, 0.03)};
  }
`;

export const CollapsibleIcon = styled(
  DropdownIcon,
  excludePropsFromForwarding("isOpen")
)<{ isOpen: boolean }>`
  transition: transform 250ms ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};

  path {
    stroke: ${({ theme }) => theme.colors.snowdrop};
  }
`;

export const CollapsibleContent = styled.div<{
  isOpen: boolean;
  scrollHeight: number;
}>`
  max-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) =>
    theme.colors.opacity(theme.colors.snowdrop, 0.05)};
  transition: max-height 250ms ease;

  ${({ isOpen, scrollHeight }) =>
    isOpen &&
    css`
      max-height: ${scrollHeight}px;
    `}
`;

export const CollapsibleParentMenuItem = styled.div`
  ${(props) => sharedMenuOptionStyles(props)}
  cursor: pointer;
  position: relative;
`;

interface CollapsibleNavItemProps {
  children: React.ReactNode;
  href: string;
  isActive: boolean;
  isOpen: boolean;
  label: string;
  tag?: NavigationTagType;
  onDropdownClick: () => void;
  onNavigationClick: () => void;
}

export function CollapsibleNavItem({
  children,
  isOpen,
  label,
  onNavigationClick,
  onDropdownClick,
  tag,
  ...navItemProps
}: CollapsibleNavItemProps) {
  const collapsibleRef = useRef<HTMLDivElement>(null);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [focusWithin, setFocusWithin] = useState(false);

  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => setFocusWithin(isFocusWithin),
  });

  useEffect(() => {
    if (collapsibleRef && collapsibleRef.current) {
      const { scrollHeight } = collapsibleRef.current;
      setScrollHeight(scrollHeight);
    }
  }, [collapsibleRef]);

  return (
    <>
      <NavItem {...navItemProps} onClick={onNavigationClick}>
        <Typography use="body1" as="p">
          {label}
        </Typography>
        {tag && (
          <NavigationTag themeColor={tag?.themeColor}>{tag.text}</NavigationTag>
        )}
        <CollapsibleIconWrapper
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget.blur();
            onDropdownClick();
          }}
        >
          <CollapsibleIcon isOpen={isOpen} />
        </CollapsibleIconWrapper>
      </NavItem>
      <CollapsibleContent
        {...focusWithinProps}
        ref={collapsibleRef}
        isOpen={focusWithin || isOpen}
        scrollHeight={scrollHeight}
      >
        {children}
      </CollapsibleContent>
    </>
  );
}
