import { colord } from "colord";

export const arctic = "#4bceeb";
export const arcticDark = "#44b7d6";
export const arcticLight = "#a5e7f5";
export const arcticLighter = "#d6f8ff";
export const buttercup = "#f5b90d";
export const buttercupDark = "#e3a900";
export const buttercupLight = "#fadc86";
export const buttercupLighter = "#fff1ca";
export const charcoal = "#222222";
export const charcoalLight = "#52585b";
export const cloud = "#cdd7dc";
export const cloudDark = "#9fa6ad";
export const cloudDarker = "#848a90";
export const darkBlue = "#345B68";
export const fern = "#0a9e76";
export const fernDark = "#0d9571";
export const forest = "#035f48";
export const forestLight = "#107D61";
export const forestDark = "#04513e";
export const jungle = "#28c382";
export const jungleDark = "#23a971";
export const jungleLight = "#91e3c1";
export const jungleLighter = "#c4f7e2";
export const lavender = "#b9a6df";
export const lavenderDark = "#a491c9";
export const lavenderLight = "#dcd3ef";
export const lavenderLighter = "#f2effb";
export const lightGreen = "#ECF4F4";
export const midnight = "#24373D";
export const mist = "#f2f2f2";
export const mistDark = "#e4e4e4";
export const mistDarker = "#d7d7d7";
export const mistLight = "#f9f9f9";
export const mistLighter = "#fcfcfc";
export const mushroom = "#e8e4df";
export const mushroomDark = "#e2dad1";
export const mushroomDarker = "#d4cec6";
export const mushroomLight = "#f4f2f0";
export const mushroomLighter = "#faf9f8";
export const night = "#000000";
export const rose = "#fd8d9c";
export const roseDark = "#ed7484";
export const roseLight = "#fec6ce";
export const roseLighter = "#fee8eb";
export const scarlet = "#f73a54";
export const scarletDark = "#b92b3f";
export const snowdrop = "#ffffff";
export const spruce = "#024232";

export const transparent = "transparent";

// Need to decide replacement
export const darkCream = "#DBD6D0";
export const darkGrey = "#BCC6CB";
export const turquoise = "#1DB6B2";

// Gradients
export const profileSky =
  "linear-gradient(180deg, #12ABDB 0%, #E9E3DC 83.04%);";
export const homepageSky =
  "linear-gradient(180deg, #222222 4.69%, #4BCEEB 59.9%, #FFFFFF 100%)";

export const opacity = (color: string, opacity: number) =>
  colord(color).alpha(opacity).toRgbString();

export enum EcologiColour {
  arctic = "#4bceeb",
  arcticDark = "#44b7d6",
  arcticLight = "#a5e7f5",
  arcticLighter = "#d6f8ff",
  buttercup = "#f5b90d",
  buttercupDark = "#e3a900",
  buttercupLight = "#fadc86",
  buttercupLighter = "#fff1ca",
  charcoal = "#222222",
  charcoalLight = "#52585b",
  cloud = "#cdd7dc",
  cloudDark = "#9fa6ad",
  cloudDarker = "#848a90",
  darkBlue = "#345B68",
  darkCream = "#DBD6D0",
  darkGrey = "#BCC6CB",
  fern = "#0a9e76",
  fernDark = "#0d9571",
  forest = "#035f48",
  forestLight = "#107D61",
  forestDark = "#04513e",
  jungle = "#28c382",
  jungleDark = "#23a971",
  jungleLight = "#91e3c1",
  jungleLighter = "#c4f7e2",
  lavender = "#b9a6df",
  lavenderDark = "#a491c9",
  lavenderLight = "#dcd3ef",
  lavenderLighter = "#f2effb",
  lightGreen = "#ECF4F4",
  midnight = "#24373D",
  mist = "#f2f2f2",
  mistDark = "#e4e4e4",
  mistDarker = "#d7d7d7",
  mistLight = "#f9f9f9",
  mistLighter = "#fcfcfc",
  mushroom = "#e8e4df",
  mushroomDark = "#e2dad1",
  mushroomDarker = "#d4cec6",
  mushroomLight = "#f4f2f0",
  mushroomLighter = "#faf9f8",
  night = "#000000",
  rose = "#fd8d9c",
  roseDark = "#ed7484",
  roseLight = "#fec6ce",
  roseLighter = "#fee8eb",
  scarlet = "#f73a54",
  scarletDark = "#b92b3f",
  snowdrop = "#ffffff",
  spruce = "#024232",
  teal10 = "#e1efee",
  teal80 = "#335f64",
  teal85 = "#345356",
  transparent = "transparent",
  turquoise = "#1DB6B2",
}
