import { ITokenCache } from "@ecologi/nextjs-auth0/src/tokens/token-cache";
import {
  UserType,
  ChecklistItem,
  CampaignSourceEnum,
  CampaignEntries,
  CursorPagingOptions,
  AggregateOfImpactByProject,
  ImpactType,
  ImpactTile,
} from "@ecologi/shared";
import { fetchFromAPI } from "./fetch-from-api";
import { UserinfoResponse } from "openid-client";
import { uploadS3Direct } from "./s3";

/**
 * @param key The key for use with React Query
 * @param tokenCache The Auth0 token cache (server only value)
 */
export const getLoggedInUser = async (
  key?: string | undefined,
  tokenCache?: ITokenCache
): Promise<UserType> =>
  fetchFromAPI(`/auth/login`, { method: "POST" }, tokenCache);

export interface FieldsToUpdate {
  billingAddress1?: string;
  billingAddress2?: string;
  billingCity?: string;
  billingCompanyName?: string;
  billingCountry?: string;
  billingEmail?: string;
  billingPostcode?: string;
  blurb?: string;
  checklistItems?: ChecklistItem[];
  city?: string;
  country?: string;
  firstName?: string;
  groupName?: string;
  email?: string;
  password?: string;
  isPrivate?: boolean;
  isTreeDonationsEnabled?: boolean;
  lastName?: string;
  link?: string;
  picture?: string;
  username?: string;
  forestName?: string;
  isBadgesHiddenFromProfile?: boolean;
  isEdenPriceMatchEnabled?: boolean;
}

export const updateUser = async ({
  userId,
  fieldsToUpdate,
}: {
  userId: string;
  fieldsToUpdate: FieldsToUpdate;
}): Promise<{ user: UserType }> => {
  return await fetchFromAPI(`/users/${userId}`, {
    method: "PATCH",
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const deleteUser = async ({
  userId,
  feedback,
}: {
  userId: string;
  feedback: string;
}): Promise<any> => {
  return await fetchFromAPI(`/users/${userId}`, {
    method: "DELETE",
    body: JSON.stringify({ feedback }),
  });
};

export const uploadProfileImage = async ({
  userId,
  file,
}: {
  userId: string;
  file: Blob;
}): Promise<{ user: UserType }> => {
  const fileUploader = uploadS3Direct(() =>
    fetchFromAPI("/file-upload/profile-image", {
      body: JSON.stringify({
        mimetype: file.type,
        size: file.size,
      }),
      method: "POST",
    })
  );

  const picture = await fileUploader(file);

  // Update DB copy of user with the response containing the new asset URL
  return updateUser({ userId, fieldsToUpdate: { picture } });
};

// FIXME: change any[]
export const getInvoices = async (_, userId: string): Promise<any[]> =>
  fetchFromAPI(`/users/${userId}/invoices`);

//  UserId here should refer to the standalone profileId or the child profileId ?
export const generateCert = async (userId: string) => {
  return await fetchFromAPI(`/users/${userId}/generate-certificate`, {
    method: "POST",
  });
};

export const createAPIKey = async ({
  userId,
  ...body
}: {
  userId: string;
  description: string;
}): Promise<UserType> => {
  return await fetchFromAPI(`/users/${userId}/create-api-key`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const verifyEmail = async (
  _,
  token: string
): Promise<{ updatedUser: UserType }> => {
  return await fetchFromAPI(`/auth/verify-email`, {
    method: "POST",
    body: JSON.stringify({ token }),
  });
};

export const getAuth0SessionData = async (): Promise<UserinfoResponse> => {
  return await fetchFromAPI(`/me`, {
    passthrough: "edge",
  });
};

export const getCampaignEntries = async (
  _: any,
  campaignSource: CampaignSourceEnum
): Promise<CampaignEntries> => {
  const params = new URLSearchParams({ campaignSource });

  return await fetchFromAPI(`/users/campaign-entries?${params.toString()}`);
};

export const getUserTilesPaginated = async (
  _: string,
  userId: string,
  cursor: CursorPagingOptions
) => {
  const query = new URLSearchParams({
    direction: cursor.direction,
    take: cursor.take.toString(),
  });

  // May have no initial cursor, in which case don't set ?cursor=undefined
  if (cursor.cursor) query.set("cursor", cursor.cursor);

  return await fetchFromAPI(`/users/${userId}/tiles?${query.toString()}`);
};

export const getAggregateOfCarbonOffsetsByProject = async (
  userId: string
): Promise<AggregateOfImpactByProject<ImpactType.CARBON_OFFSETS>> =>
  fetchFromAPI(`/users/${userId}/aggregate-of-carbon-offsets-by-project`);

export const getAggregateOfCarbonRemovalsByProject = async (
  userId: string
): Promise<AggregateOfImpactByProject<ImpactType.CARBON_REMOVAL>> =>
  fetchFromAPI(`/users/${userId}/aggregate-of-carbon-removals-by-project`);

export const getAggregateOfHabitatRestorationsByProject = async (
  userId: string
): Promise<AggregateOfImpactByProject<ImpactType.HABITAT_RESTORATION>> =>
  fetchFromAPI(`/users/${userId}/aggregate-of-habitat-restorations-by-project`);

export const getTile = async (
  userId: string,
  tileId: string
): Promise<ImpactTile> => fetchFromAPI(`/users/${userId}/tile/${tileId}`);
