import { Location, User } from "@ecologi/shared";
import { Stripe } from "@stripe/stripe-js";
import { SetState } from "zustand";
import { Flag } from "../lib/configcat";
export enum CookieConsentStatus {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CONSENT_REQUESTED = "CONSENT_REQUESTED",
  UNKNOWN = "UNKNOWN",
}

export type DeferredPrompt = {
  prompt?: () => void;
  userChoice?: { outcome: string };
};

type State = {
  affiliateClickId: string;
  referrerUserId: string;
  location: Location | null;
  user: User | null;
  parentUsername: string | undefined; //  We just need the name of the parent profile rather than the whole object
  isCheckingUserSession?: boolean;
  hasDoneV2ReauthCheck?: boolean;
  hasSeenEmailPopup: boolean;
  deferredPrompt: DeferredPrompt | null;
  cookieConsentStatus: CookieConsentStatus;
  stripe: Stripe | null;
  refetchUser: () => void;
  flags: Flag[] | null;
};

type Setters = {
  setLocation: (location: Location) => void;
  setUser: (
    user: User,
    extra?: { isCheckingUserSession?: boolean; hasDoneV2ReauthCheck?: boolean }
  ) => void;
  setStripe: (stripe: Stripe) => void;
  setRefetchUser: (refetchFn: () => void) => void;
  setHasSeenEmailPopup: (hasSeenEmailPopup: boolean) => void;
  setReferrerUserId: (referrerUserId: string) => void;
  setDeferredPrompt: (deferredPrompt: DeferredPrompt) => void;
  setCookieConsent: (cookieConsent: CookieConsentStatus) => void;
  setFlags: (flags: Flags[]) => void;
};

export type Store = State & Setters;
export type CreateSetters = (set: SetState<Store>) => Setters;
