import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { Link } from "../Link";
import { NavigationTag } from "../NavigationTag";
import { EcologiTheme } from "../theme";
import { NavigationTagType, NavItemIcon } from "./navigation";

const ItemContainer = styled.div<{ isActive: boolean }>`
  position: relative;
  display: flex;
  font-size: 12px;
  align-items: center;

  background-color: ${({ theme, isActive }) =>
    isActive
      ? theme.colors.opacity(theme.colors.snowdrop, 0.1)
      : "transparent"};

  :hover {
    background-color: ${({ theme }) =>
      theme.colors.opacity(theme.colors.snowdrop, 0.05)};
  }
`;

const StyledLink = styled(Link)`
  flex: 1;
  background-color: transparent;

  :hover {
    background-color: transparent !important;
  }

  p {
    font-weight: 400 !important;
  }
`;

const IconLink = styled(Link)<{ isHoverDisabled?: boolean }>`
  display: flex;
  width: 50px;
  height: 45px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.snowdrop};

  :hover {
    background-color: ${({ theme, isHoverDisabled = false }) =>
      isHoverDisabled
        ? "unset"
        : theme.colors.opacity(theme.colors.snowdrop, 0.03)};
  }
`;

const Icon = styled.div`
  min-width: 16px;
  height: 16px;
`;

export const Notification = styled.div`
  background-color: ${({ theme }) => theme.colors.jungleDark};
  color: ${({ theme }) => theme.colors.snowdrop};
  border-radius: 50%;
  width: 8px;
  height: 8px;
`;

type SharedMenuOptionProps = {
  theme: EcologiTheme;
  isSubNavItem?: boolean;
};

export const sharedMenuOptionStyles = ({
  theme,
  isSubNavItem,
}: SharedMenuOptionProps) => css`
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  padding: 8px 0;
  position: relative;
  text-decoration: none;

  p {
    color: ${theme.colors.mistDark};
    font-size: 14px;
    padding: 0 8px 0 24px;
    text-align: left;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    background-color: ${theme.colors.opacity(
      theme.colors.snowdrop,
      isSubNavItem ? 0.03 : 0
    )};

    p {
      color: ${theme.colors.snowdrop};
    }
  }
`;

type NavItemProps = {
  icon?: NavItemIcon;
  isActive: boolean;
  isSubNavItem?: boolean;
  onClick: (e: any) => void;
  href: string;
  isOpenInNewTab?: boolean;
  tag?: NavigationTagType;
  notification?: boolean;
  children: React.ReactNode;
};

export const NavItem = styled(
  ({
    href,
    onClick,
    icon,
    isOpenInNewTab,
    tag,
    notification,
    ...props
  }: NavItemProps) => {
    const { isActive, isSubNavItem, ...narrowedProps } = props;

    return href.startsWith("/api/") ? (
      <a href={href} onClick={onClick} {...narrowedProps}>
        {props["children"]}
      </a>
    ) : (
      <ItemContainer isActive={isActive}>
        <StyledLink
          href={href}
          onClick={onClick}
          target={isOpenInNewTab ? "_blank" : undefined}
          {...narrowedProps}
        >
          {props.children}
          {tag && (
            <NavigationTag themeColor={tag?.themeColor}>
              {tag.text}
            </NavigationTag>
          )}
          {notification && <Notification />}
        </StyledLink>
        {icon && (
          <IconLink
            href={icon.path || href}
            isHoverDisabled={icon.isHoverDisabled}
          >
            <Icon>{icon.svg}</Icon>
            {icon.label}
          </IconLink>
        )}
      </ItemContainer>
    );
  }
)`
  ${sharedMenuOptionStyles}

  ${({ isActive, theme }) =>
    !isActive &&
    css`
      &:focus::before {
        background-color: ${theme.colors.snowdrop};
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 8px;
      }
    `}

  ${({ isActive, theme }) =>
    isActive &&
    css`
      position: relative;

      > p,
      > a p {
        color: ${theme.colors.snowdrop};
        font-weight: bold;
      }

      &::before {
        background-color: ${theme.colors.jungle};
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 8px;
      }
    `}
`;

export const NavItemDivider = styled.div`
  border-top: 2px solid #000;
  margin: 16px 24px;
`;
