import React, { Ref } from "react";
import classnames from "classnames";
import styled from "@emotion/styled";
import { Link as NextLink } from "../Link";
import styles from "./Button.module.scss";
import { Spinner as SpinnerBase } from "../Spinner";
import CopyIcon from "@ecologi/assets/icons/Buttons/circle-tick-icon.svg";

// Variants are typed separately so other components can restrict input only to these
export type ButtonVariants = {
  darkBlue?: boolean;
  darkGreen?: boolean;
  green?: boolean;
  green2?: boolean;
  lightGreen?: boolean;
  primary?: boolean;
  small?: boolean;
  spruce?: boolean;
  textOnly?: boolean;
  textWithUnderline?: boolean;
  textOnlyWithArrow?: boolean;
  textOnlyWithBorder?: boolean;
  turquoise?: boolean;
  white?: boolean;
};

export type ButtonProps = {
  ariaLabel?: string;
  className?: string;
  component?: any;
  extra?: object;
  // NOTE: You cannot use href buttons with custom style props, it doesn't work!
  // This is something to do with the react-navi <Link> component. Just wrap the
  // <Button> in a <div> or something :)
  href?: string;
  icon?: any;
  onClick?: (e: React.MouseEvent) => void;
  role?: string;
  target?: string;
  text: string;
  // Booleans grouped together
  block?: boolean;
  blockOnMobile?: boolean;
  border?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  invert?: boolean;
  isCopied?: boolean;
  isIconCircle?: boolean;
  loading?: boolean;
  submit?: boolean;
  regular?: boolean;
} & ButtonVariants;

export const Button = React.forwardRef(
  (
    {
      block,
      blockOnMobile,
      border,
      className,
      darkGreen,
      disabled,
      extra,
      fullWidth,
      green,
      green2,
      href,
      icon,
      invert,
      isCopied,
      isIconCircle,
      loading,
      primary,
      spruce,
      small,
      submit,
      text,
      textOnly,
      textOnlyWithArrow,
      textWithUnderline,
      textOnlyWithBorder,
      turquoise,
      regular,
      lightGreen,
      darkBlue,
      white,
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const Icon = icon;

    // Build up the class for this button
    const cls = classnames(className, styles.button, {
      [styles.buttonBlock]: block,
      [styles.buttonBlockOnMobile]: blockOnMobile,
      [styles.buttonBorder]: border || textOnlyWithBorder,
      [styles.buttonDarkBlue]: darkBlue,
      [styles.buttonDarkGreen]: darkGreen,
      [styles.buttonDisabled]: disabled,
      [styles.buttonGreen]: green,
      [styles.buttonGreen2]: green2,
      [styles.buttonIconCircle]: isIconCircle,
      [styles.buttonLightGreen]: lightGreen,
      [styles.buttonLoading]: loading,
      [styles.buttonPrimary]: primary,
      [styles.buttonRegular]: regular,
      [styles.buttonSmall]: small,
      [styles.buttonSpruce]: spruce,
      [styles.buttonTextOnly]: textOnly || textOnlyWithBorder,
      [styles.buttonTextWithUnderline]: textWithUnderline,
      [styles.buttonTextOnlyWithArrow]: textOnlyWithArrow,
      [styles.buttonTurquoise]: turquoise,
      [styles.buttonWhite]: white,
      [styles.fullWidth]: fullWidth,
      [styles.invert]: invert,
    });

    const Spinner = styled(SpinnerBase)`
      fill: ${(props) => props.theme.colors.snowdrop};
    `;

    const content = loading ? <Spinner /> : text;

    if (href) {
      return (
        <NextLink href={href} className={cls} {...extra}>
          {icon && <Icon className={styles.icon} />}
          {content}
        </NextLink>
      );
    }

    return (
      <button
        ref={ref}
        type={submit ? "submit" : "button"}
        className={cls}
        disabled={disabled}
        {...props}
        {...extra}
      >
        {icon && !loading && <Icon className={styles.icon} />}
        {content}
        {isCopied && <CopyIcon className={styles.copyIcon} />}
      </button>
    );
  }
);
