import React, { useRef } from "react";
import { useTextField } from "@react-aria/textfield";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "../Typography";
import TickIcon from "@ecologi/assets/icons/tick.svg";
import CloseIcon from "@ecologi/assets/icons/close-icon.svg";

const ToggleTitle = styled(Typography)`
  padding-bottom: 20px;
`;

const StatusText = styled(Typography)`
  padding-left: 14px;
`;

const HelpText = styled(Typography)`
  color: ${({ theme }) => theme.colors.charcoal};
`;

const ToggleContainer = styled.div<{ $disableWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$disableWidth ? "fit-content" : "100%")};
  height: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
`;

const Input = styled.input`
  display: none;
`;

const InputButton = styled.label<{
  isDisabled?: boolean;
  isChecked: boolean;
  $disabledColorVariant?: "fern" | "cloudDarker";
}>`
  display: flex;
  align-items: center;
  background: ${({ theme, isChecked }) =>
    isChecked ? theme.colors.fern : theme.colors.cloudDarker};
  border-radius: 2em;
  cursor: pointer;
  height: 2em;
  outline: 0;
  padding: 2px;
  position: relative;
  transition: all 0.4s ease;
  user-select: none;
  width: 72px;
  height: 40px;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  &:after,
  &:before {
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    width: 50%;
  }

  &:after {
    background: ${({ theme }) => theme.colors.snowdrop};
    border-radius: 50%;
    left: ${({ isChecked }) => (isChecked ? "50%" : 0)};
    transition: all 250ms ease-in;
    width: 32px;
    height: 32px;
  }

  &:before {
    display: none;
  }
`;

const IconWrapper = styled.div<{ isChecked: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: ${({ isChecked }) => (isChecked ? "47%" : 1)};
  transition: all 250ms ease-in;

  svg {
    width: 12px;
    z-index: 1;
  }
`;

export type ToggleProps = {
  name: string;
  label?: string;
  help?: string | JSX.Element;
  status?: {
    on: string;
    off: string;
  };
  onChange: (value: any) => void;
  isDisabled?: boolean;
  value: boolean;
  disableWidth?: boolean;
  disabledColorVariant?: "fern" | "cloudDarker";
};

export const Toggle = ({
  name,
  label,
  help,
  status,
  onChange,
  disableWidth,
  disabledColorVariant,
  isDisabled = false,
  value = false,
}: ToggleProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { labelProps, inputProps } = useTextField(
    {
      "aria-describedby": `${label}-id`,
      "aria-label": `${label}`,
      onChange: () => onChange({ target: { value: !value, name } }),
    },
    inputRef
  );

  return (
    <ToggleContainer $disableWidth={disableWidth}>
      {label && (
        <ToggleTitle use="body2" as="h2" {...labelProps}>
          {label}
        </ToggleTitle>
      )}
      <InputWrapper>
        <InputButton
          htmlFor={name}
          isDisabled={isDisabled}
          isChecked={value}
          $disabledColorVariant={disabledColorVariant}
        >
          <IconWrapper isChecked={value}>
            {value ? <TickIcon /> : <CloseIcon />}
          </IconWrapper>
        </InputButton>
        <Input
          {...(inputProps as any)}
          ref={inputRef}
          id={name}
          type="checkbox"
          name={name}
          data-testid="input-element"
        />

        {status && (
          <StatusText use="label" as="p">
            {value ? status.on : status.off}
          </StatusText>
        )}
      </InputWrapper>
      {help && (
        <HelpText use="body1" as="p">
          {help}
        </HelpText>
      )}
    </ToggleContainer>
  );
};
