export const defaultErrorMessage =
  "Ooops! Something went wrong. Please try again.";

export interface APIErrorResponse {
  message: string;
  status: string;
  response: APIErrorBody;
}

export interface APIErrorBody {
  errors?: Array<{ messages: [] }>;
  error?: string;
}

export const defaultError: APIErrorBody = {
  error: defaultErrorMessage,
};

/**
 * Describes errors returned from an API call
 */
export class APIError extends Error {
  type: number;
  errors: string[];

  constructor(type: number, errorBody: APIErrorBody) {
    super("An Ecologi API error occurred");
    this.name = "APIError";
    this.type = type;
    this.errors = [];

    if (Array.isArray(errorBody.errors)) {
      errorBody.errors.forEach((x: { messages: string[] }) => {
        if (Array.isArray(x.messages) && x.messages.length > 0) {
          this.errors.push(x.messages[0]);
        }
      });
    } else if (errorBody.error) {
      this.errors.push(errorBody.error);
    }
  }

  static isType(type: number, error: APIError) {
    return error.type === type;
  }

  static isClientError(error: APIError) {
    return error.type >= 400 && error.type < 500;
  }

  static isNotFoundError(error: APIError) {
    return error.type === 404;
  }

  getFirstError(): string {
    if (this.errors.length > 0) {
      return this.errors[0];
    }

    return defaultErrorMessage;
  }
}
