export const uploadS3Direct =
  (
    presignedUrlResolver: () => Promise<{
      presignedUrl: string;
      assetUrl: string;
    }>
  ) =>
  async (body: Blob) =>
    presignedUrlResolver().then(({ presignedUrl, assetUrl }) =>
      fetch(presignedUrl, { body, method: "PUT" }).then(() => assetUrl)
    );
