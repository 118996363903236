import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const COMPRESS_HEADER_BP = "only screen and (min-width: 1920px)";

const BurgerWrapper = styled.div<{ isOpen: boolean; offsetTop?: number }>`
  position: fixed;
  right: 20px;
  top: ${({ offsetTop = 0 }) => offsetTop + 20}px;

  opacity: ${(props) => (props.isOpen ? 0 : 1)};

  border-radius: 50%;
  transition: border-color 250ms ease-in, opacity 0.25s;
  border: solid 4px
    ${({ theme }) => theme.colors.opacity(theme.colors.snowdrop, 0.1)};

  &:hover {
    cursor: ${(props) => (props.isOpen ? "none" : "pointer")};
    border-color: ${({ theme }) =>
      theme.colors.opacity(theme.colors.snowdrop, 0.2)};
  }

  @media ${COMPRESS_HEADER_BP} {
    right: 10%;
  }
`;

const Burger = styled.button`
  border: none;
  padding: 4px;
  background-clip: padding-box;
  background: ${({ theme }) => theme.colors.charcoal};
  border-radius: 59px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 59px;
  padding: 26px 11px;
  width: 59px;
  z-index: 9999;
`;

const layer = (color: string) => css`
  position: absolute;
  width: 30px;
  height: 2px;
  background: ${color};
`;

const BurgerLayers = styled.span<{ isOpen: boolean }>`
  ${({ theme }) => layer(theme.colors.snowdrop)};
  transition: transform 125ms ease-in;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(-45deg);
      transition: transform 125ms ease-in 125ms;
    `}

  &:before,
  &:after {
    display: block;
    content: "";
    ${({ theme }) => layer(theme.colors.snowdrop)};
  }

  &:before {
    top: -9px;
    transition: top 125ms ease-in 125ms;
    ${({ isOpen }) =>
      isOpen &&
      css`
        top: 0px;
        transition: top 125ms ease-in;
      `};
  }

  &:after {
    top: 9px;
    transition: top 125ms ease-in 125ms, transform 125ms ease-in;
    ${({ isOpen }) =>
      isOpen &&
      css`
        top: 0px;
        transform: rotate(90deg);
        transition: top 125ms ease-in, transform 125ms ease-in 125ms;
      `};
  }
`;

const BurgerClose = styled.button`
  background-clip: padding-box;
  background: ${({ theme }) => theme.colors.charcoal};
  border-radius: 59px;
  border: solid 4px
    ${({ theme }) => theme.colors.opacity(theme.colors.snowdrop, 0.1)};
  cursor: pointer;
  display: flex;
  height: 59px;
  left: 28px;
  padding: 26px 11px;
  transition: border-color 250ms ease-in;
  width: 59px;
  z-index: 9998;

  &:hover {
    border-color: ${({ theme }) =>
      theme.colors.opacity(theme.colors.snowdrop, 0.2)};
  }
`;

const BurgerLayersClose = styled.span`
  ${({ theme }) => layer(theme.colors.snowdrop)};
  transform: rotate(-45deg);

  &:before,
  &:after {
    display: block;
    content: "";
    ${({ theme }) => layer(theme.colors.snowdrop)};
  }

  &:before {
    top: 0px;
  }

  &:after {
    top: 0px;
    transform: rotate(90deg);
  }
`;

type HamburgerProps = {
  onClickMenuIcon: () => void;
  isOpen: boolean;
  offsetTop?: number;
};

export const Hamburger = ({
  onClickMenuIcon,
  isOpen,
  offsetTop,
}: HamburgerProps) => {
  return (
    <BurgerWrapper isOpen={isOpen} offsetTop={offsetTop} id="__ecologi-burger">
      <Burger
        tabIndex={1}
        aria-label="menu"
        type="button"
        onClick={(event: React.MouseEvent) => {
          onClickMenuIcon();
          event.preventDefault();
        }}
      >
        <BurgerLayers isOpen={isOpen} />
      </Burger>
    </BurgerWrapper>
  );
};

Hamburger.Closed = ({ onClickMenuIcon }: Omit<HamburgerProps, "isOpen">) => (
  <BurgerClose
    aria-label="close"
    type="button"
    onClick={(event: React.MouseEvent) => {
      onClickMenuIcon();
      event.preventDefault();
    }}
  >
    <BurgerLayersClose />
  </BurgerClose>
);
