import React, { useRef, useImperativeHandle } from "react";
import { useTextField, AriaTextFieldOptions } from "@react-aria/textfield";
import { EcologiTheme } from "../theme";
import { capitaliseFirstLetter, InputVariantTypes } from "@ecologi/shared";
import {
  Wrapper,
  LabelWrapper,
  Label,
  InputWrapper,
  Input,
  ErrorMessage,
  WarningIcon,
  SuccessIcon,
  HelpMessage,
  CharacterCount,
  SuccessMessage,
  SpinnerWrapper,
} from "./TextField.styles";
import { Spinner } from "../Spinner";

export type InputFieldBoxProps = {
  error: boolean;
  isInvalid?: boolean;
  isValid?: boolean;
  isSelected?: boolean;
  isMultiline?: boolean;
  isDisabled?: boolean;
};

export type InputFieldBoxCssArgs = InputFieldBoxProps & {
  variant?: InputVariantTypes;
  theme: EcologiTheme;
};

export type TextFieldChangeEvent = { target: { name: string; value: string } };

export type TextFieldProps = Omit<
  AriaTextFieldOptions<"input">,
  "onChange" | "value"
> & {
  className?: string;
  error?: boolean;
  dense?: boolean;
  errorText?: string | false;
  helpText?: string;
  isInvalid?: boolean;
  isLabelHidden?: boolean;
  isLoading?: boolean;
  isMultiline?: boolean;
  isValid?: boolean;
  label: string;
  max?: number | string;
  min?: number | string;
  name: string;
  onChange?: (event: TextFieldChangeEvent) => void;
  onClearButtonClick?: () => void;
  showCharacterCount?: boolean;
  step?: number;
  successText?: string;
  value: any;
  variant?: InputVariantTypes;
};

export const TextField = React.forwardRef(
  ({ className, ...props }: TextFieldProps, forwardRef) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(forwardRef, () => inputRef.current);
    const { labelProps, inputProps } = useTextField(
      {
        validationState: props.isInvalid ? "invalid" : "valid",
        "aria-describedby": `${props.label}-id`,
        "aria-label": `${props.label}`,
        ...props,
        onChange: (value) => {
          props.onChange &&
            props.onChange({ target: { value, name: props.name } });
        },
      },
      inputRef
    );

    const showCharacterCount = () => {
      if (props.showCharacterCount && !props.maxLength) {
        return props.value.length;
      } else if (props.showCharacterCount && props.maxLength) {
        return props.value.length + "/" + props.maxLength;
      }
      return "";
    };

    return (
      <Wrapper className={className}>
        <LabelWrapper hidden={props.isLabelHidden}>
          <Label
            use="textInputLabel"
            as="label"
            isDisabled={props.isDisabled}
            {...labelProps}
          >
            {capitaliseFirstLetter(props.label, true)}
          </Label>
        </LabelWrapper>

        {/* Getting Typescript errors for 'inputProps' so 'as any' for now */}
        <InputWrapper>
          <Input
            {...(inputProps as any)}
            ref={inputRef}
            isInvalid={props.isInvalid}
            isValid={props.isValid}
            isDisabled={props.isDisabled}
            as={props.isMultiline ? "textarea" : undefined}
            isMultiline={props.isMultiline}
            step={props.step}
            min={props.min}
            max={props.max}
            variant={props.variant}
            dense={props.dense}
          />
          {props.showCharacterCount && (
            <CharacterCount use="label" as="p">
              {showCharacterCount()}
            </CharacterCount>
          )}
          {props.type !== "date" && (
            <>
              {props.isInvalid && <WarningIcon />}
              {props.isValid && <SuccessIcon />}
            </>
          )}
          {props.isLoading && (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}
        </InputWrapper>
        {props.isInvalid && (
          <ErrorMessage use="label" as="label">
            {props.errorText
              ? props.errorText
              : `${capitaliseFirstLetter(
                  props.label,
                  true
                )} is a required field.`}
          </ErrorMessage>
        )}
        {props.isValid && (
          <SuccessMessage use="label" as="label">
            {props.successText}
          </SuccessMessage>
        )}
        {props.helpText && (
          <HelpMessage use="label" as="label">
            {props.helpText}
          </HelpMessage>
        )}
      </Wrapper>
    );
  }
);
