import ShoppingBagIcon from "@ecologi/assets/icons/impact-shop/shopping-bag.svg";
import { NavItemType } from "@ecologi/react-components/src/NavigationDrawer/navigation";
import { User } from "@ecologi/shared";

const subMenuItems = (productsInBasket?: number) => [
  {
    id: "ecologi-zero",
    label: "Ecologi Zero",
    path: process.env.NEXT_PUBLIC_ZERO_BASE_URL + "/signup",
    route: process.env.NEXT_PUBLIC_ZERO_BASE_URL + "/signup",
  },
  {
    id: "impact-shop",
    label: "Impact Shop",
    path: `/business/impact-shop`,
    route: `/business/impact-shop`,
    icon: {
      svg: <ShoppingBagIcon />,
      label: productsInBasket > 0 && productsInBasket,
    },
  },
  {
    id: "climate-action-workforce",
    label: "Climate Action Workforce",
    path: `/business/climate-action-workforce`,
    route: `/business/climate-action-workforce`,
  },
  {
    id: "ecommerce-integration",
    label: "Ecommerce & Integration",
    path: `/business/integrations`,
    route: `/business/integrations`,
  },
  {
    id: "individual-plans",
    label: "Individual & Family plans",
    path: "/individuals",
    route: "/individuals",
  },
];

const publicNavItems = (productsInBasket?: number): NavItemType[] => [
  {
    id: "home",
    label: "Home",
    path: "/",
    route: "/",
  },
  {
    id: "products",
    label: "Products",
    path: "/our-products",
    route: `/our-products/[slug]`,
    subMenuItems: subMenuItems(productsInBasket),
  },
  {
    id: "about",
    label: "About us",
    path: "/about",
    route: "/about",
  },
  {
    id: "projects",
    label: "Climate projects",
    path: "/projects",
    route: "/projects",
  },
  {
    id: "transparency",
    label: "Transparency",
    path: "/transparency",
    route: "/transparency",
  },
  {
    id: "blog",
    label: "Blog",
    path: "/articles",
    route: "/articles",
  },
  {
    id: "webinars",
    label: "Webinars",
    path: "https://info.ecologi.com/webinars",
    route: "https://info.ecologi.com/webinars",
  },
];

const publicNavFooterItems: NavItemType[] = [
  {
    id: "contact",
    label: "Speak to the team",
    path: "/business/business-signup",
    route: "/business/business-signup",
  },
  {
    id: "partnerships",
    label: "Partnerships",
    path: "https://info.ecologi.com/partnerships",
    route: "https://info.ecologi.com/partnerships",
  },
  {
    id: "help",
    label: "Help centre",
    path: "https://help.ecologi.com/",
    route: "https://help.ecologi.com/",
  },
  {
    id: "press",
    label: "Press",
    path: "pages/press",
    route: "pages/press",
  },
];

export const publicMarketingNavConfig = (
  productsInBasket?: number
): NavItemType[][] => [
  publicNavItems(productsInBasket),
  [
    {
      id: "sign-in",
      label: "Sign in 🌿",
      path: "/api/login",
      route: "/api/login",
    },
    {
      id: "get-started",
      label: "Get started",
      path: "/pay-as-you-go",
      route: "/pay-as-you-go",
    },
  ],
  publicNavFooterItems,
];

export const loggedInMarketingNavConfig = (
  user: User,
  productsInBasket?: number
): NavItemType[][] => [
  publicNavItems(productsInBasket),
  [
    {
      id: "your-profile",
      label: "Your profile 🌿",
      path: user.getProfilePath(),
      route: user.isChildProfile
        ? `/[usernameOrId]/[childUsernameOrId]`
        : `/[usernameOrId]`,
    },
    {
      id: "logout",
      label: " Logout",
      path: "/api/logout",
      route: `/api/logout`,
    },
  ],
  publicNavFooterItems,
];
