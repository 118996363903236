import * as configcat from "configcat-js-ssr";

export type Flag = {
  settingKey: string;
  settingValue: boolean;
};

export type ConfigCatUser = {
  /** Unique identifier for the User or Session. e.g. Email address, Primary key, Session Id */
  identifier: string;
  /** Optional parameter for easier targeting rule definitions */
  email?: string;
  /** Optional parameter for easier targeting rule definitions */
  country?: string;
  /** Optional dictionary for custom attributes of the User for advanced targeting rule definitions. e.g. User role, Subscription type */
  custom?: {
    [key: string]: string;
  };
};

const isDevelopment = process.env.NODE_ENV === "development";

// https://configcat.com/docs/sdk-reference/js-ssr#logging
const logger = configcat.createConsoleLogger(isDevelopment ? 3 : -1);

// https://configcat.com/docs/sdk-reference/js-ssr#creating-the-configcat-client
const configCatClient = configcat.getClient(
  process.env.NEXT_PUBLIC_CONFIGCAT_SDK_KEY,
  configcat.PollingMode.AutoPoll,
  { logger }
);

export const getFlagValue = (flags: Flag[], key: string) => {
  if (Array.isArray(flags)) {
    const flag = flags.find((flag) => flag.settingKey === key);
    if (flag) return flag.settingValue;
  }

  return false;
};

export default configCatClient;

export * from "./use-get-configcat-value";
