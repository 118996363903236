type Size = {
  mobile: number;
  desktop: number;
};

export type Heading = {
  sizes: Size[];
};

export const heading: Heading = {
  sizes: [
    {
      mobile: 40,
      desktop: 48,
    },
    {
      mobile: 36,
      desktop: 40,
    },
    {
      mobile: 28,
      desktop: 32,
    },
    {
      mobile: 26,
      desktop: 30,
    },
    {
      mobile: 22,
      desktop: 24,
    },
    {
      mobile: 18,
      desktop: 18,
    },
  ],
};

export const fontStack = "'Sofia Pro', Helvetica, Arial, sans-serif";
