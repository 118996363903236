import React, { Ref } from "react";
import ReactDOM from "react-dom";
import { usePreventScroll } from "@react-aria/overlays";
import classnames from "classnames";
import styled from "@emotion/styled";
import { css, SerializedStyles } from "@emotion/react";
import styles from "./Modal.module.scss";
import { useEscape } from "../utils/use-escape";
import { CloseButton } from "../ForestModals/CloseButton";

export type ModalProps = {
  onClose: () => void;
  onClick?: () => void;
  isOpen: boolean;
  id?: string;
  marginTop?: string;
  withCloseText?: boolean;
};

export const ModalContent = ({
  children,
  centered,
}: {
  children: React.ReactNode | React.ReactNode[];
  centered?: boolean;
}) => (
  <div
    className={classnames(styles.content, {
      [styles.contentCentered]: centered,
    })}
  >
    {children}
  </div>
);

type ModalHeaderSectionVariant = "dark" | "default" | "skinny";

type ModalHeaderSectionProps = {
  variant?: "dark" | "default" | "skinny";
};

type VariantsMap = { [name in ModalHeaderSectionVariant]: SerializedStyles };

const Scrim = styled.div`
  &:hover {
    cursor: pointer;
  }
  opacity: 0.3 !important;
`;

const containerVariants = (theme: any): VariantsMap => ({
  default: css`
    background: transparent;
  `,
  dark: css`
    background: ${theme.colors.mushroomLight};
  `,
  skinny: css`
    padding-top: 0;
  `,
});

export const ModalHeaderSection = styled.div<ModalHeaderSectionProps>`
  padding-top: 40px;

  ${(props) => containerVariants(props.theme)[props.variant || "default"]}
`;

ModalHeaderSection.defaultProps = {
  variant: "default",
};

const ModalRoot = styled.div`
  z-index: 99999999;
  position: fixed;
  display: flex;
  width: 100vw;
  left: 50vw;
  transform: translateX(-50%);
  height: 100vh;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
`;

const ModalRootContent = styled.div`
  border-radius: 5px;
  background: ${(props) => props.theme.colors.snowdrop};
  position: relative;
  overflow: auto;
  max-height: 100vh;

  @media only screen and (max-height: 640px) {
    height: 100%;
  }

  @media (${(props) => props.theme.bp.tabletUp}) {
    max-width: 800px;
  }
`;

const CloseButtonWrapper = styled.div`
  padding-top: 6px;
  padding-right: 6px;
`;

const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 15px;
  top: 15px;
  @media (${(props) => props.theme.bp.tabletUp}) {
    right: 20px;
    top: 20px;
  }
  z-index: 99;
`;

/**
 * @deprecated Use `<DialogModal>` instead
 */
export const Modal = React.forwardRef(
  (
    props: ModalProps & { children: React.ReactNode },
    ref: Ref<HTMLDivElement>
  ) => {
    const { onClose, children, isOpen, ...rest } = props;
    useEscape(onClose);
    usePreventScroll({ isDisabled: !isOpen });
    if (!isOpen) return null;

    const portalRoot = document.getElementById("modal-root");

    const modalElement = (
      <>
        <ModalRoot ref={ref} {...rest}>
          <ModalRootContent>
            <CloseWrapper>
              {props.withCloseText ? (
                <CloseButtonWrapper>
                  <CloseButton closeModal={onClose} title="Close window" />
                </CloseButtonWrapper>
              ) : (
                <button
                  className={styles.closeButton}
                  onClick={onClose}
                  title="Close window"
                  type="button"
                />
              )}
            </CloseWrapper>
            {children}
          </ModalRootContent>
        </ModalRoot>

        <Scrim className={styles.modalScrim} onClick={onClose} />
      </>
    );

    return portalRoot
      ? ReactDOM.createPortal(modalElement, portalRoot)
      : modalElement;
  }
);
