import {
  areImpactShopBasketProductsEqual,
  ImpactShopBasketProduct,
  Currency,
  ImpactShopProductPrice,
} from "@ecologi/shared";

export const findProduct = (
  basket: ImpactShopBasketProduct[],
  productToFind: ImpactShopBasketProduct
) =>
  basket.find((product) =>
    areImpactShopBasketProductsEqual(product, productToFind)
  );

/**
 * Return total quantity of a specific product within the basket
 */
export const getTotalProductQuantity = (
  basket: ImpactShopBasketProduct[],
  productToFind: ImpactShopBasketProduct
) =>
  basket.reduce(
    (acc, product) =>
      product.id == productToFind.id ? acc + product.quantity : acc,
    0
  );

export const getProductPrice = (
  prices: ImpactShopProductPrice[],
  currency: Currency
) => prices.find((price) => price.currency === currency).amount;

export const updateProductQuantity = (
  basket: ImpactShopBasketProduct[],
  productToUpdate: ImpactShopBasketProduct,
  amount: number,
  overwrite = false
) => [
  ...basket.map((product) =>
    areImpactShopBasketProductsEqual(product, productToUpdate)
      ? {
          ...product,
          quantity: overwrite ? amount : product.quantity + amount,
        }
      : product
  ),
];
