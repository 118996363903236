import Router from "next/router";
import { useRef, useEffect } from "react";
import NProgress from "nprogress";

export function useTopProgressBar() {
  const delay = 150;
  const timer = useRef(null);
  const state = useRef("");

  useEffect(() => {
    function load() {
      if (state.current === "loading") {
        return;
      }

      state.current = "loading";

      timer.current = setTimeout(function () {
        NProgress.start();
      }, delay);
    }

    function stop() {
      state.current = "stop";

      clearTimeout(timer.current);
      NProgress.done();
    }

    Router.events.on("routeChangeStart", load);
    Router.events.on("routeChangeComplete", stop);
    Router.events.on("routeChangeError", stop);

    return () => {
      Router.events.off("routeChangeStart", load);
      Router.events.off("routeChangeComplete", stop);
      Router.events.off("routeChangeError", stop);
    };
  }, [timer.current, state.current]);
}
