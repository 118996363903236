import { datadogLogs } from "@datadog/browser-logs";

export function init() {
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    env: process.env.NEXT_PUBLIC_DATADOG_ENV,
    forwardErrorsToLogs: true,
    service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
    site: "datadoghq.eu",
    sessionSampleRate: parseInt(process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE),
    telemetrySampleRate: 0,
  });
}
