import React, { useState, Fragment } from "react";
import { ModalBase } from "../ModalBase";
import { flags, Country, locationNames } from "@ecologi/shared";
import { TextField } from "../TextField";
import { Text } from "../Text";
import { CountryChangeButton } from "./components/CountryChangeButton";
import { ZoneCard } from "../ZoneCard";
import styles from "./CountryChangeModal.module.scss";

type Location = {
  countryCode: string;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  setUserLocation: (update: Location) => void;
  location: Location | null;
}

export const CountryChangeModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, location, setUserLocation } = props;
  const [searchText, setSearchText] = useState("");

  const countryCode = (location ? location.countryCode : "") as Country;
  const flagsToList = Object.keys(flags)
    .filter((flagCountryCode) => countryCode !== flagCountryCode)
    .filter((flagCountryCode) => {
      const name = locationNames[flagCountryCode as Country];
      return name.toLowerCase().includes(searchText.toLowerCase());
    });

  return (
    <ModalBase isOpen={isOpen} onClose={onClose} title="Select your location">
      <Text>
        Ecologi uses your location information to calculate the average carbon
        footprint needed to offset. For some locations you will be charged in US
        Dollars or Euros, not your native currency.
      </Text>
      <br />
      {!!countryCode && (
        <Fragment>
          <div className={styles.selectedText}>Selected:</div>
          <ZoneCard>
            <CountryChangeButton countryCode={countryCode} isSelected />
          </ZoneCard>
          <br />
        </Fragment>
      )}
      <TextField
        placeholder="Search..."
        label="Find your country"
        name="country-filter"
        onChange={(event) => setSearchText(event.target.value)}
        onClearButtonClick={() => setSearchText("")}
        value={searchText}
      />
      <br />
      <ZoneCard>
        {flagsToList.map((flagCountryCode) => (
          <CountryChangeButton
            key={flagCountryCode}
            countryCode={flagCountryCode as Country}
            onClose={(updatedCountry) => {
              setUserLocation(updatedCountry);
              onClose();
              setSearchText("");
            }}
          />
        ))}
      </ZoneCard>
    </ModalBase>
  );
};
