import { css } from "@emotion/react";
import { theme, EcologiTheme } from "../theme";
import { InputVariantTypes } from "@ecologi/shared";
import styled from "@emotion/styled";
import IconWarning from "@ecologi/assets/icons/FormFields/warning_icon.svg";
import IconSuccess from "@ecologi/assets/icons/FormFields/success_icon.svg";
import { Typography } from "../Typography";
import { InputFieldBoxProps } from ".";

type InputFieldBoxCssArgs = InputFieldBoxProps & {
  theme: EcologiTheme;
  variant?: InputVariantTypes;
  isFocused?: boolean;
  dense?: boolean;
};

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: end;
`;

export const LabelWrapper = styled.div<{ hidden?: boolean }>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-bottom: 10px;
`;

export const Label = styled(Typography)<{ isDisabled?: boolean }>`
  color: ${(props) => props.isDisabled && "#c9d0d8"};
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`;

export const BackgroundColourMapper = {
  [InputVariantTypes.SNOWDROP]: theme.colors.snowdrop,
  [InputVariantTypes.DEFAULT]: theme.colors.mist,
};

//-- Textfield variants

export const ErrorStyles = css`
  border: solid 2px ${theme.colors.scarlet};
  padding: 12px 13px;
`;

export const SuccessStyles = css`
  border: solid 2px ${theme.colors.charcoal};
  padding: 12px 13px;
`;

export const DisabledStyles = css`
  color: #c9d0d8;
  border-bottom: solid 2px #c9d0d8;
`;

export const SnowdropStyles = css`
  background-color: ${theme.colors.snowdrop};
`;

//this gets the selected styled for the input when isSelected is true
export const getSelectedCSS = (props: InputFieldBoxCssArgs) => css`
  border: solid 2px ${theme.colors.night};
  outline: none;
  padding-left: 14px; // take away some padding to accommodate left/right border
  padding-right: 14px;
`;

export const Input = styled.input<InputFieldBoxCssArgs>`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-size: 18px;

  margin-bottom: ${(props) => (props.dense ? "none" : "10px")};
  padding: 10px 16px 9px 16px;

  border-left: none;
  border-right: none;
  border-top: 2px solid transparent;
  border-bottom: 2px solid ${(props) => props.theme.colors.cloudDarker};
  cursor: text;

  ${(props) => props.isDisabled && DisabledStyles};
  ${(props) => props.isSelected && getSelectedCSS(props)}
  ${(props) => props.isInvalid && ErrorStyles}
  ${(props) => props.isValid && SuccessStyles}

  &:focus {
    ${(props) => getSelectedCSS(props)};
  }

  ${(props) =>
    props.isMultiline &&
    css`
      resize: none;
    `}

  background-color: ${(props) =>
    props.variant
      ? BackgroundColourMapper[props.variant]
      : BackgroundColourMapper.DEFAULT};

  &::-webkit-input-placeholder {
    font-size: 18px;
  }

  /* Multiline rezize */
  min-height: 48px;
  max-height: 127px;
  resize: vertical;
  &[type="date"] {
    -webkit-appearance: none;
    text-align: left;
    color: currentColor;
  }
`;

export const Message = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #52585b;
`;

export const ErrorMessage = styled(Message)`
  color: ${theme.colors.scarlet};
`;

export const SuccessMessage = styled(Message)`
  color: ${theme.colors.jungleDark};
`;

export const HelpMessage = styled(Message)`
  margin-bottom: 10px;
`;

export const WarningIcon = styled(IconWarning)`
  position: absolute;
  top: 15px;
  right: 20px;
`;

export const SuccessIcon = styled(IconSuccess)`
  position: absolute;
  top: 19px;
  right: 20px;
`;

export const CharacterCount = styled(Typography)`
  position: absolute;
  right: 0px;
  top: -30px;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 45%;
  right: 12px;
  transform: translateY(-50%);
`;
