import React, { useMemo, useEffect } from "react";
import { ReactQueryCacheProvider, QueryCache, useQuery } from "react-query";
import { Hydrate } from "react-query/hydration";
import { ThemeProvider } from "@emotion/react";
import { User } from "@ecologi/shared";
import { CookieConsent } from "@ecologi/react-components/src/CookieConsent";
import { theme } from "@ecologi/react-components/src/theme";
import Bowser from "bowser";

// Must be in this order --------------
import "plyr/dist/plyr.css";
import "../styles.css";
import "react-toastify/dist/ReactToastify.css";
import "@ecologi/react-components/src/styles/index.scss";
// Must be in this order --------------

import { useCreateStore } from "../store";
import { Store } from "../store/index.d";
import { Layout } from "../components";
import { useTopProgressBar } from "../components/top-progress-bar";
import {
  getLoggedInUser,
  getCountryCode,
  useTrackPageViews,
  useCookieConsent,
  useTrackChatVisibility,
} from "../lib";
import {
  GlobalStyles,
  GoogleMapsProvider,
} from "@ecologi/react-components/src";
import { TrustPilotProvider } from "@ecologi/react-components/src/TrustPilotTrustBox/TrustPilotProvider";
import { AppProps } from "next/dist/shared/lib/router/router";
import configCatClient from "../lib/configcat";
import logger from "../lib/logger";

const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!;

const TRUSTPILOT_BUSINESS_UNIT_ID =
  process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID!;

const queryCache = new QueryCache();

function useCheckLogin({
  setUser,
  setRefetchUser,
  user: initialUser,
  isCheckingUserSession,
  hasDoneV2ReauthCheck,
  setLocation,
  location,
}: Store) {
  const userLocationUnknown =
    !isCheckingUserSession && !initialUser && !location;

  useQuery("check-v2-reauth", getLoggedInUser, {
    retry: 1,
    enabled: initialUser && !hasDoneV2ReauthCheck,
    onSuccess: (user) => {
      if (
        (user?.isV2 && !initialUser.isV2) || // State switch, though I'm unsure if this might be temperamental
        (user.isV2 && user.customer == undefined) // Fallback, `customer` always exists on V2 hydrated response
      ) {
        logger.info("User was migrated from v1 to v2, triggering logout...");
        setUser(new User(user), { hasDoneV2ReauthCheck: true });
        window.location.href = "/api/logout?reauth=true";
      }
    },
  });

  const { refetch } = useQuery("check-login", getLoggedInUser, {
    retry: 1,
    refetchOnWindowFocus: false,
    enabled: !initialUser,
    onSuccess: (user) => {
      const userInstance = new User(user);
      setUser(userInstance, { isCheckingUserSession: false });
      setLocation({ countryCode: userInstance.location });
    },
    onError: () => {
      setUser(null, { isCheckingUserSession: false });
    },
  });

  useEffect(() => {
    setRefetchUser(refetch);
  }, [refetch, setRefetchUser]);

  useQuery("user-country", getCountryCode, {
    retry: 3,
    refetchOnWindowFocus: false,
    enabled: userLocationUnknown,
    onSuccess: (location) => {
      const countryCode = location?.countryCode || "GB";
      setLocation({ countryCode });
    },
  });
}

function useLoadStripe({ setStripe }: Store) {
  useEffect(() => {
    const stripeJs = document.createElement("script");
    stripeJs.src = "https://js.stripe.com/v3/";
    stripeJs.defer = true;
    stripeJs.onload = () => {
      setStripe(window.Stripe(process.env.NEXT_PUBLIC_STRIPE_KEY));
    };
    document.body && document.body.appendChild(stripeJs);
  }, [setStripe]);
}

function App({ Component, pageProps }: AppProps) {
  const initialState = useMemo(() => {
    return {
      ...pageProps.initialStoreData,
      ...(pageProps.initialStoreData?.user
        ? {
            user: new User(pageProps.initialStoreData.user),
            isCheckingUserSession: false,
            hasDoneV2ReauthCheck: false,
          }
        : {}),
    };
  }, [pageProps.initialStoreData]);
  const useStore = useCreateStore(initialState);
  const store = useStore();

  useCheckLogin(store);
  useLoadStripe(store);
  useTrackPageViews();
  useTopProgressBar();
  useTrackChatVisibility();

  useEffect(() => {
    const browser = Bowser.getParser(navigator.userAgent);

    async function fetchFlags() {
      try {
        const flags = await configCatClient.getAllValuesAsync({
          identifier: store.user?.userId,
          email: store.user?.email,
          custom: {
            browserName: browser.getBrowserName(),
            browserVersion: browser.getBrowserVersion(),
          },
        });

        store.setFlags(flags);
      } catch (e) {
        console.error(`Error fetching flags: ${e.message}`);
        store.setFlags([]);
      }
    }

    fetchFlags();
  }, [store.user]);

  const cookieConsentProps = useCookieConsent();
  const layoutProps = pageProps.layoutProps || Component["layoutProps"] || {};

  // Preventing unnecessary re-renders
  const component = useMemo(
    () => <Component {...pageProps} />,
    [Component, pageProps]
  );

  return (
    <ThemeProvider theme={theme}>
      <GoogleMapsProvider googleMapsApiKey={GOOGLE_MAPS_API_KEY} language="en">
        <TrustPilotProvider businessUnitId={TRUSTPILOT_BUSINESS_UNIT_ID}>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <Hydrate state={pageProps.dehydratedState}>
              <GlobalStyles />
              <Layout {...layoutProps}>{component}</Layout>
              <CookieConsent {...cookieConsentProps} />
            </Hydrate>
          </ReactQueryCacheProvider>
        </TrustPilotProvider>
      </GoogleMapsProvider>
    </ThemeProvider>
  );
}

export default App;
