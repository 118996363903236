import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { EcologiTheme } from "@ecologi/react-components/src/theme";
import { Wrapper } from "@ecologi/react-components/src/Wrapper";
// import { twitterHandle } from '@ecologi/react-components/src/consts';

import * as bp from "@ecologi/react-components/src/theme/ecologi-breakpoints";

const Header = styled.div`
  display: flex;
  padding: 220px 0;
  flex: 1;

  @media ${bp.desktopDown} {
    padding: 120px 0;
  }

  @media ${bp.extraSmallDown} {
    flex-direction: column-reverse;
    padding: 100px 0 60px 0;
  }
`;

const Left = styled.div`
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  padding-right: 40px;

  @media ${bp.extraSmallDown} {
    flex: 1 1 100%;
    padding-right: 0px;
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1 1 50%;

  @media ${bp.extraSmallDown} {
    display: flex;
    flex: 1 1 100%;
    padding-top: 300px;
  }
`;

const MeerCatsContainer = styled.div`
  width: 50%;
  height: 500px;
  position: absolute;
  top: 0;
  animation-name: slideDown, wobble;
  animation-duration: 3s, 2s;
  animation-delay: 1s, 4s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: 1, 1;
  animation-fill-mode: backwards;

  @keyframes slideDown {
    0% {
      transform: translateY(-500px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes wobble {
    12% {
      transform: skew(0.8deg, 0) translateX(4px);
    }
    36% {
      transform: skew(-0.6deg, 0) translateX(-3px);
    }
    60% {
      transform: skew(0.4deg, 0) translateX(2px);
    }
    84% {
      transform: skew(-0.2deg, 0) translateX(-1px);
    }
  }

  @media ${bp.extraSmallDown} {
    left: 0;
    right: 0;
    top: -0px;
    margin: 0 auto;
    width: 340px;
    height: 340px;
    position: absolute;
    animation-name: slideDown, wobble;
    animation-duration: 3s, 2s;
    animation-delay: 1s, 4s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: 1, 1;
    animation-fill-mode: backwards;
  }
`;

const MeerCats = styled.svg`
  height: 480px;

  @media ${bp.desktopDown} {
    height: 340px;
  }

  @media ${bp.extraSmallDown} {
    width: 340px;
    height: 340px;
  }
`;

const MeerCatHeadImage1 = styled.svg`
  height: 26px;

  @media ${bp.desktopDown} {
    height: 18px;
  }

  /* @media ${bp.extraSmallDown} {
    height: 26px;
  } */
`;

const MeerCatHeadImage2 = styled.svg`
  height: 40px;

  @media ${bp.desktopDown} {
    height: 28px;
  }

  /* @media ${bp.extraSmallDown} {
    height: 40px;
  } */
`;

const MeerCatHead1 = styled.div`
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  position: absolute;
  top: 264px;
  left: 214px;
  animation: fadeOut 0.2s;
  animation-delay: 7s;
  animation-fill-mode: forwards;

  @media ${bp.desktopDown} {
    top: 189px;
    left: 150px;
  }

  @media ${bp.extraSmallDown} {
    top: 188px;
    left: 173px;
  }
`;

const MeerCatHead2 = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  opacity: 0;
  position: absolute;
  top: 264px;
  left: 220px;
  animation: fadeIn 0.2s;
  animation-delay: 7s;
  animation-fill-mode: forwards;

  @media ${bp.desktopDown} {
    top: 187px;
    left: 156px;
  }

  @media ${bp.extraSmallDown} {
    top: 187px;
    left: 179px;
  }
`;

const headingBase = (props: { theme: EcologiTheme }) => css`
  color: ${props.theme.colors.night};
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 30px;

  @media ${props.theme.bp.mobileDown} {
    font-size: 32px;
  }
`;

export const Heading = styled.h1`
  ${headingBase};
`;

const Subheading = styled.h1`
  ${headingBase};
  font-size: 18px;
  font-weight: 100;

  @media ${bp.mobileDown} {
    font-size: 18px;
  }
`;

const TextLink = styled.a`
  ${headingBase};
  font-size: 18px;
  font-weight: 500;

  @media ${bp.mobileDown} {
    font-size: 18px;
  }
`;

const wrapperProps = { isLarge: true };

export const UnderMaintenance = () => {
  return (
    <Wrapper {...wrapperProps}>
      <Header>
        <Left>
          <Heading>Under Maintenance</Heading>
          <Subheading>
            We're currently working on this page and will have it back as soon
            as we can.
            {/* <br /> <br /> Follow us on Twitter for the latest updates // Commenting this out as we may need it soon */}
          </Subheading>
          {/* <TextLink href={`https://twitter.com/${twitterHandle}`}>Follow us on Twitter</TextLink>   // Commenting this out as we may need it soon*/}
        </Left>
        <Right>
          <MeerCatsContainer>
            <MeerCatHead1>
              <MeerCatHeadImage1
                viewBox="0 0 46 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M39.5564 21.2989C31.0449 25.2918 23.4195 25.6133 14.2563 24.3707C10.0896 23.8059 8.87745 22.1983 7.01353 20.7384C4.32843 20.1301 1.48273 18.9223 0.978728 17.1931C0.47473 15.4638 0.9438 14.4341 2.10821 13.9953C3.27262 13.5565 7.29601 12.2052 9.36414 9.21599C11.4323 6.22676 13.7089 3.4982 15.2774 2.9551C19.0096 1.65166 26.5739 0.126644 32.5915 1.42574C38.6091 2.72484 40.938 5.86179 42.0111 8.31226C44.618 9.67653 46.0606 10.6628 45.8563 13.8085C45.726 15.781 45.6087 18.4618 39.5564 21.2989Z"
                  fill="#D8C7BA"
                />
                <path
                  d="M28.6724 7.01313C26.9344 5.81396 22.6722 4.84072 19.8003 5.44031C18.2883 5.74879 15.2296 7.66918 14.5388 9.116C13.848 10.5628 13.5655 12.266 15.6466 12.7917C17.7278 13.3174 19.7785 13.9127 20.4737 13.387C20.9081 13.0611 20.4737 12.9916 20.8343 12.3051C20.9675 12.0676 21.165 11.8724 21.4039 11.7418C21.6429 11.6112 21.9136 11.5504 22.1855 11.5665C23.6671 11.5404 27.6991 10.5585 28.6724 9.77207C29.6456 8.98566 30.193 8.06457 28.6724 7.01313Z"
                  fill="black"
                />
                <path
                  d="M24.2843 8.52085C24.4711 7.97341 22.4291 7.061 20.8345 7.33038C19.24 7.59976 18.1277 8.27754 17.8496 10.5021C18.4813 10.8307 19.1783 11.0148 19.8898 11.0411C20.6014 11.0674 21.31 10.9353 21.9642 10.6542C23.259 10.1328 23.7759 9.99374 24.2843 8.52085Z"
                  fill="black"
                />
                <path
                  d="M38.4656 9.88071C41.5635 9.1725 43.1711 10.4716 43.788 12.4876C44.405 14.5036 43.3535 17.784 38.3786 19.8391C36.5364 20.5994 36.1151 15.2596 36.1455 14.13C36.176 13.0003 36.3106 10.376 38.4656 9.88071Z"
                  fill="black"
                />
                <path
                  d="M20.8564 9.7374C21.2355 9.7374 21.5429 9.44755 21.5429 9.09001C21.5429 8.73247 21.2355 8.44263 20.8564 8.44263C20.4773 8.44263 20.1699 8.73247 20.1699 9.09001C20.1699 9.44755 20.4773 9.7374 20.8564 9.7374Z"
                  fill="white"
                />
                <path
                  d="M7.13525 20.8601C8.99049 26.1521 21.6252 24.6749 26.5522 23.2324C20.5259 18.3097 21.5644 24.084 7.13525 20.8601Z"
                  fill="#BCA799"
                />
                <path
                  d="M6.15777 14.5601C6.50102 15.2075 4.65882 15.5508 3.77682 16.3545C3.19461 16.8803 3.88112 17.9925 3.04257 18.8615C1.2047 17.9404 -0.49422 15.0815 1.86068 13.9823C2.72964 13.8693 5.81888 13.9171 6.15777 14.5601Z"
                  fill="black"
                />
              </MeerCatHeadImage1>
            </MeerCatHead1>
            <MeerCatHead2>
              <MeerCatHeadImage2
                viewBox="0 0 38 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.9275 29.6844C33.4676 31.1573 28.4451 30.4056 20.694 36.4362C17.0921 39.2342 11.1353 34.329 10.1273 34.3333C10.3402 32.1609 11.2353 31.1355 10.4489 30.423C9.18889 29.302 5.90408 28.2506 5.20457 25.3004C4.61102 22.9587 3.54501 20.7631 2.07198 18.8484C1.20301 17.7535 -1.14306 12.1096 1.10755 10.5933C3.9056 8.70329 9.1453 1.54739 11.2786 0.869604C14.3591 -0.112323 16.1144 0.869604 17.6785 0.869604C19.7249 0.869604 20.8503 -0.494672 24.1393 0.482909C28.6362 1.82545 30.2872 5.87482 32.3944 6.4353C34.5017 6.99578 36.231 8.23404 37.0695 9.63307C37.9081 11.0321 38.5249 15.2248 37.0042 19.2264C35.8415 22.6022 35.1429 26.1204 34.9275 29.6844Z"
                  fill="#D8C7BA"
                />
                <path
                  d="M20.0164 14.2907C20.1251 15.655 22.0975 19.07 25.8514 21.2424C27.3112 22.0766 31.4997 23.341 31.5866 23.6581C31.6735 23.9753 30.2006 27.4034 24.409 28.2158C11.9003 29.9538 10.3666 33.9553 7.86838 39.6687C8.51575 35.2414 11.0834 33.4774 5.99998 26.9993C4.05351 24.5228 12.4738 22.85 16.1799 14.2951C17.227 11.9706 20.0164 14.2907 20.0164 14.2907Z"
                  fill="#C1AFA3"
                />
                <path
                  d="M12.8602 20.317C11.9913 20.1214 11.9348 19.2004 12.6343 18.7224C14.8849 17.1931 14.9806 15.7593 15.4455 15.1336C16.3709 13.8736 19.0733 14.8512 20.1855 15.3248C20.4282 15.4109 20.6288 15.5865 20.746 15.8158C21.0631 16.9577 21.7568 17.959 22.7144 18.6572C23.3921 19.196 23.818 20.1301 22.9577 20.443C18.5607 20.7341 16.6446 21.1772 12.8602 20.317Z"
                  fill="#9B8B83"
                />
                <path
                  d="M2.22888 11.0017C1.79439 11.3015 0.751465 12.2443 0.751465 13.4348C0.751465 14.7383 1.45098 18.4748 2.05492 18.8745C2.65885 19.2743 2.98486 17.8361 2.97183 16.0374C2.96748 14.4255 3.21081 10.7671 2.22888 11.0017Z"
                  fill="black"
                />
                <path
                  d="M33.6244 11.5579C33.5375 10.8236 33.1899 9.27687 33.5897 8.63384C33.9894 7.99081 35.4015 8.67295 35.9403 9.26819C36.479 9.86342 36.8397 12.3095 36.8092 13.1785C36.7875 14.2517 36.3965 16.8411 36.0489 17.3451C35.7013 17.8491 34.7846 17.4842 34.6803 16.1112C34.576 14.7383 33.6244 11.5579 33.6244 11.5579Z"
                  fill="black"
                />
                <path
                  d="M20.2248 14.8555C20.3986 14.4211 20.0422 13.5 19.4687 13.0351C19.0776 12.7179 18.5997 12.744 18.0392 12.7223C17.3918 12.6962 16.6619 12.5962 16.2101 12.9873C15.8712 13.2784 15.7279 13.9475 15.6453 14.534C15.5454 15.2466 16.6749 15.4508 17.8958 15.3378C18.7213 15.2639 19.9293 15.6246 20.2248 14.8555Z"
                  fill="black"
                />
                <path
                  d="M14.6112 6.40924C15.0674 6.84372 15.6451 7.96905 14.5893 9.60269C13.6074 11.119 11.8913 12.4616 11.409 13.8041C11.1005 14.6731 9.49721 15.0337 9.39294 13.9475C9.28866 12.8613 9.17133 11.0452 8.38926 10.7367C7.6072 10.4282 6.51661 9.9633 7.04233 8.85972C7.42023 8.05869 7.95489 7.34148 8.61456 6.75046C9.27424 6.15944 10.0457 5.7066 10.8833 5.41864C12.2476 4.91899 13.6944 5.562 14.6112 6.40924Z"
                  fill="black"
                />
                <path
                  d="M12.7689 8.72071C12.6994 9.55926 11.396 10.3674 10.7747 10.4586C9.72323 10.6368 7.55516 9.97201 7.62468 9.23774C7.6942 8.50347 8.64134 8.01249 9.44513 7.6258C10.0925 7.3347 12.3996 7.46071 12.7689 8.72071Z"
                  fill="black"
                />
                <path
                  d="M28.6665 7.565C27.728 6.60915 25.3645 4.81038 22.8793 5.21445C22.3644 5.3111 21.8837 5.54081 21.4851 5.88075C21.0864 6.2207 20.7837 6.65906 20.607 7.15224C20.4428 7.55199 20.3762 7.98505 20.4124 8.41567C20.4487 8.84629 20.5868 9.26212 20.8155 9.62879C21.7366 11.3667 23.2616 12.0749 23.9307 13.5174C24.4999 14.7339 26.6116 14.2864 26.3335 12.4616C26.138 11.2103 26.429 10.6455 27.6847 10.224C28.9403 9.80258 29.7136 8.63817 28.6665 7.565Z"
                  fill="black"
                />
                <path
                  d="M27.2679 8.19492C25.7254 7.42588 24.0136 7.07396 23.3575 7.84734C22.784 8.50776 22.6232 9.38543 23.4617 9.87205C24.3002 10.3587 25.582 10.6063 26.2945 9.94591C26.7983 9.48174 27.1396 8.86786 27.2679 8.19492Z"
                  fill="black"
                />
                <path
                  d="M24.513 9.13782C24.8034 9.13782 25.0387 8.90244 25.0387 8.61207C25.0387 8.3217 24.8034 8.0863 24.513 8.0863C24.2227 8.0863 23.9873 8.3217 23.9873 8.61207C23.9873 8.90244 24.2227 9.13782 24.513 9.13782Z"
                  fill="white"
                />
                <path
                  d="M11.7826 9.18121C12.0729 9.18121 12.3083 8.94583 12.3083 8.65549C12.3083 8.36514 12.0729 8.12976 11.7826 8.12976C11.4922 8.12976 11.2568 8.36514 11.2568 8.65549C11.2568 8.94583 11.4922 9.18121 11.7826 9.18121Z"
                  fill="white"
                />
              </MeerCatHeadImage2>
            </MeerCatHead2>
            {/* <MeerCatsBg /> */}

            <MeerCats viewBox="0 0 428 496">
              <path
                d="M120.161 411.623C127.073 412.531 128.438 412.927 128.438 412.927C123.637 404.328 106.427 401.152 103.081 399.688C103.081 399.688 96.4164 405.745 102.308 408.078C108.2 410.411 113.439 410.732 120.161 411.623Z"
                fill="#A07F67"
              />
              <path
                d="M24.7315 458.86C24.5773 458.859 24.4234 458.848 24.2709 458.825L25.014 454.102C24.7041 454.044 24.3836 454.084 24.0971 454.215C25.9524 453.108 32.1307 442.719 32.2698 427.847C32.348 419.665 29.3328 410.433 26.4174 401.5C25.114 397.455 23.8496 393.631 22.8633 389.882L27.4862 388.67C28.4334 392.28 29.6586 396.056 30.9621 400.018C34.0034 409.312 37.136 418.927 37.0448 427.89C36.9058 442.762 31.1575 454.507 27.3167 457.756C26.6153 458.423 25.6977 458.814 24.7315 458.86Z"
                fill="#173A18"
              />
              <path
                d="M391.711 495.826C391.554 495.826 391.397 495.812 391.242 495.786L391.981 491.068C391.688 491.011 391.384 491.046 391.112 491.168C393.054 490.034 401.005 476.63 401.179 458.082C401.274 448.246 397.664 437.192 394.175 426.504C392.598 421.681 391.112 417.128 389.938 412.666L394.561 411.454C395.695 415.799 397.168 420.269 398.715 425.023C402.326 436.076 406.058 447.507 405.958 458.13C405.78 476.947 398.185 491.455 394.348 494.7C393.633 495.38 392.697 495.78 391.711 495.826Z"
                fill="#173A18"
              />
              <path
                d="M13.1392 403.42L411.272 428.203V492.524L13.1392 452.782V403.42Z"
                fill="#7A6459"
              />
              <path
                opacity="0.08"
                d="M385.976 426.63L321.069 422.589C316.129 423.146 311.423 423.806 307.048 424.545C307.435 424.089 307.676 423.527 307.739 422.933C307.761 422.517 307.663 422.104 307.457 421.742L251.018 418.232L238.418 417.571C233.408 418.149 228.624 418.831 224.158 419.592C225.831 418.662 226.926 417.71 227.338 416.754L85.7539 407.943C67.749 410.324 54.9622 414.317 54.619 418.219C54.1541 423.471 76.4344 426.569 104.38 425.14C110.324 424.836 116.042 424.349 121.368 423.723C126.369 427.947 147.437 430.21 172.998 428.903C181.905 428.468 190.342 427.599 197.798 426.504C193.753 428.29 191.42 430.136 191.311 431.909C190.938 437.992 216.694 440.881 248.836 438.405C258.83 437.631 268.266 436.432 276.535 434.959C276.295 435.315 276.146 435.723 276.1 436.15C275.666 442.146 301.113 445.252 332.922 443.101C354.58 441.628 373.606 438.092 383.678 434.095C384.125 431.519 384.897 429.011 385.976 426.63Z"
                fill="black"
              />
              <path
                d="M82.3697 328.02C77.3818 331.149 70.6996 339.412 70.7518 344.904C70.8517 355.275 71.4122 365.555 72.7895 367.832C73.363 363.604 73.315 361.784 74.6055 361.006C75.8959 360.228 76.6042 358.143 76.5825 356.349C76.5607 354.554 75.3224 346.733 77.951 344.644C79.5306 343.387 81.2673 342.341 83.1171 341.533"
                fill="#BCA799"
              />
              <path
                d="M88.6086 295.634C88.9606 298.832 89.0431 299.584 85.2762 304.593C77.9769 314.321 70.1433 325.644 82.8562 368.896C78.3593 369.518 69.0788 371.503 69.1353 376.508C69.2439 386.25 74.5229 396.86 84.0771 405.31C80.8185 406.066 75.7612 407.887 70.1737 409.008C66.7326 409.698 65.164 412.514 64.1778 416.394C64.1512 416.49 64.1482 416.591 64.1686 416.689C64.1891 416.786 64.2326 416.878 64.2955 416.955C64.3584 417.032 64.439 417.094 64.5303 417.133C64.6217 417.173 64.7213 417.191 64.8208 417.185C67.1496 417.011 67.5668 417.228 69.2613 416.515C73.0369 414.921 76.9733 412.171 80.6925 411.458C86.2669 410.363 100.396 409.416 100.396 409.416L107.808 288.743L88.6086 295.634Z"
                fill="#D8C7BA"
              />
              <path
                d="M105.175 336.145C104.307 339.056 96.0513 346.647 90.0077 351.704C82.7649 357.765 84.2465 367.862 82.8561 368.888C81.4658 369.913 82.2131 372.946 81.9872 374.931C77.4729 370.547 78.5114 357.926 80.2015 350.674C81.4702 345.217 93.3271 339.004 94.7348 333.021C96.1425 327.038 90.2422 321.468 90.164 313.782C90.0858 306.096 93.7833 303.381 96.8247 301.943C110.185 295.612 98.4801 281.757 97.3461 286.354L113.057 288.291C113.083 296.516 109.147 300.861 110.289 305.801C111.432 310.741 113.904 317.389 112.583 328.016C111.262 338.643 112.405 345.704 113.452 351.543C114.499 357.383 114.182 377.994 110.776 395.465C113.452 398.206 114.617 399.071 115.668 405.458C116.103 408.152 114.664 410.789 111.08 410.88C108.204 410.954 95.7515 414.156 87.0836 420.239C85.198 421.542 83.4817 421.977 80.8791 422.811C80.7982 422.836 80.7123 422.84 80.6292 422.824C80.5461 422.808 80.4685 422.771 80.403 422.717C80.3376 422.663 80.2867 422.594 80.2545 422.516C80.2224 422.437 80.2099 422.352 80.2187 422.268C80.3968 420.017 80.5098 418.184 82.9777 415.842C85.5324 413.413 89.4949 409.155 95.7514 408.074C90.4073 406.066 76.9688 395.908 76.2258 383.743C75.8609 377.755 86.0798 375.883 90.0944 375.136C92.3494 371.781 91.2025 353.611 91.2373 348.063"
                fill="#B59578"
              />
              <path
                d="M112.753 379.002C110.963 390.52 101.456 387.627 92.6973 382.005C101.2 390.625 106.579 392.867 110.737 395.474"
                fill="#A07F67"
              />
              <path
                d="M106.227 335.189C104.645 337.77 97.1027 345.686 91.0591 350.748L91.0894 353.355C91.0894 353.355 102.951 343.132 106.227 335.189Z"
                fill="#A07F67"
              />
              <path
                d="M136.858 317.111C137.779 313.278 146.477 300.696 149.171 297.329C150.735 300.227 153.868 305.997 155.371 308.925C154.337 310.081 153.251 311.315 152.169 312.653C147.85 317.993 144.839 316.498 143.458 320.621C142.558 323.302 142.801 330.071 142.18 331.366C141.029 333.769 141.085 336.306 141.029 337.379C140.899 339.79 140.486 340.286 139.621 338.787C138.756 337.288 135.95 323.237 136.858 317.111Z"
                fill="#BCA799"
              />
              <path
                d="M171.847 347.698C173.254 354.854 179.958 379.824 183.969 390.868C187.979 401.913 190.108 414.947 200.579 416.937C207.192 418.18 207.835 418.371 210.676 417.858C212.084 417.602 216.694 414.317 219.023 414.817C221.351 415.316 222.472 418.045 220.278 418.844C218.084 419.644 211.619 421.017 208.547 420.778C205.476 420.539 194.001 420.904 189.917 415.338C185.833 409.772 172.537 375.7 171.204 373.272C169.248 369.735 167.476 366.263 164.899 365.507L171.847 347.698Z"
                fill="#B59578"
              />
              <path
                d="M177.03 341.689C176.595 338.574 174.753 314.478 174.779 302.286C174.805 290.095 173.38 281.844 172.798 277.521C172.329 274.019 170.574 270.969 170.786 264.464C170.83 263.161 168.614 251.191 168.575 249.783C163.153 250.713 159.772 256.057 154.328 256.822C153.498 256.939 145.048 257.643 144.213 257.743C144.253 258.073 152.108 264.134 152.091 264.495C151.826 270.048 145.278 275.192 143.909 281.005C142.919 285.228 143.605 300.122 143.384 305.666C143.162 311.21 144.187 341.972 143.623 347.524C143.058 353.077 138.465 361.793 139.252 373.771C139.408 376.204 139.652 380.949 140.486 383.33C140.972 384.707 145.265 395.752 145.965 397.368C146.277 398.16 146.423 399.008 146.396 399.859C146.368 400.71 146.167 401.546 145.804 402.317C145.139 403.677 141.554 411.836 140.012 413.409C138.47 414.982 134.538 414.947 133.495 417.258C132.965 418.414 131.8 420.087 132.604 420.713C133.408 421.338 136.289 421.325 138.009 420.747C142.788 419.148 144.283 418.249 145.495 417.271C146.707 416.294 146.542 414.274 147.072 412.927C147.602 411.58 150.527 405.58 151.995 402.855C153.464 400.131 153.325 400.136 152.764 398.107C152.204 396.077 151.096 393.11 150.379 388.783C149.662 384.455 153.077 373.723 154.155 371.612C154.954 370.048 156.509 369.9 157.743 371.716C161.901 377.834 163.296 388.274 163.557 397.012C165.666 397.293 167.794 397.417 169.922 397.381C169.948 396.104 169.996 394.991 170.07 394.161C170.439 389.96 173.437 377.065 175.644 366.072C177.851 355.08 177.464 344.8 177.03 341.689Z"
                fill="#D8C7BA"
              />
              <path
                d="M163.013 410.494C163.213 409.099 163.365 403.59 163.478 395.826C163.561 390.229 161.632 381.488 159.39 374.592C148.128 340.047 164.786 333.921 160.35 310.111C159.459 305.332 164.213 294.965 164.213 284.803L159.95 254.389C162.688 252.568 165.247 250.34 168.575 249.766C168.575 251.174 170.387 263.209 170.786 264.447C173.393 272.542 174.81 290.082 174.779 302.273C174.749 314.465 176.595 338.561 177.03 341.676C177.464 344.791 177.825 355.08 175.622 366.072C173.419 377.065 170.408 389.969 170.048 394.161C169.687 398.354 169.917 409.877 170.891 412.844C171.864 415.812 171.673 418.492 169.943 419.036C168.279 419.544 164.517 422.055 162.396 421.977C161.293 421.938 158.265 422.164 157.535 421.595C156.805 421.025 157.157 419.166 157.565 418.236C158.621 415.851 162.118 416.733 163.013 410.494Z"
                fill="#B59578"
              />
              <path
                d="M154.133 371.625C153.389 373.327 152.766 375.079 152.269 376.869C152.125 376.765 151.978 376.652 151.834 376.517C147.924 373.08 147.446 355.367 147.39 352.747C147.542 355.136 150.805 362.145 155.927 370.421C155.21 370.356 154.563 370.782 154.133 371.625Z"
                fill="#BCA799"
              />
              <path
                d="M160.98 313.313C162.566 311.293 166.194 305.836 167.932 300.657C170.065 309.694 163.001 315.32 161.319 316.646C161.328 315.933 160.937 314.004 160.98 313.313Z"
                fill="#A07F67"
              />
              <path
                d="M161.128 315.855C162.866 313.583 167.841 308.903 168.71 300.07C174.658 311.727 162.766 321.268 160.937 322.763C161.267 321.129 161.08 316.628 161.128 315.855Z"
                fill="#A07F67"
              />
              <path
                d="M167.645 280.193C167.124 287.362 169.018 301.991 169.24 306.726C169.461 311.393 164.895 314.929 160.524 320.534C156.926 325.127 155.275 325.131 154.207 328.659C153.49 331.031 153.451 336.614 151.7 338.439C151.091 339.074 150.783 342.845 150.765 343.653C150.722 345.582 150.196 345.53 149.462 344.296C148.728 343.062 146.538 329.037 147.072 323.78C147.776 320.039 158.195 307.704 159.238 305.966C160.281 304.228 160.767 301.752 159.885 299.557C157.626 293.961 153.181 280.44 155.866 271.625C157.213 267.21 160.646 262.153 159.937 257.743"
                fill="#B59578"
              />
              <path
                d="M164.834 258.399C156.879 261.788 151.422 261.523 143.11 260.102C138.896 259.381 137.566 258.395 135.172 256.778C132.765 256.053 130.358 254.128 129.998 252.434C129.637 250.739 130.119 249.788 131.201 249.427C132.283 249.066 136.015 247.98 138.062 245.213C140.108 242.445 140.234 240.763 143.71 239.799C147.186 238.834 154.176 237.396 159.59 238.96C165.004 240.525 166.928 243.648 167.771 246.055C170.057 247.498 171.321 248.523 170.956 251.53C170.743 253.416 170.491 255.996 164.834 258.399Z"
                fill="#D8C7BA"
              />
              <path
                d="M150.096 243.214C148.028 243.023 142.71 243.123 141.889 246.099C141.068 249.075 144.061 249.979 145.586 250.444C147.111 250.909 148.228 250.67 148.563 249.54C149.106 247.737 151.721 247.646 153.707 247.468C155.48 247.307 155.879 245.03 154.88 244.4C153.881 243.77 152.082 243.392 150.096 243.214Z"
                fill="black"
              />
              <path
                d="M151.661 245.347C151.865 244.83 150.057 243.848 148.593 244.044C147.129 244.239 146.082 244.809 145.708 246.929C146.258 247.275 146.882 247.488 147.529 247.551C148.177 247.614 148.829 247.524 149.436 247.289C150.639 246.851 151.135 246.733 151.661 245.347Z"
                fill="black"
              />
              <path
                d="M131.21 249.44C133.587 249.875 135.029 250.609 135.029 251.243C135.029 251.878 132.796 253.993 131.014 253.993C130.202 253.993 129.572 251.921 129.754 251.387C129.937 250.852 130.28 249.505 131.21 249.44Z"
                fill="black"
              />
              <path
                d="M164.473 247.394C167.328 246.872 168.714 248.206 169.166 250.192C169.617 252.177 168.484 255.249 163.843 256.957C162.127 257.587 162.04 252.447 162.127 251.365C162.214 250.283 162.488 247.767 164.473 247.394Z"
                fill="black"
              />
              <path
                d="M148.08 246.334C148.423 246.334 148.702 246.055 148.702 245.712C148.702 245.369 148.423 245.091 148.08 245.091C147.737 245.091 147.459 245.369 147.459 245.712C147.459 246.055 147.737 246.334 148.08 246.334Z"
                fill="white"
              />
              <path
                d="M135.181 256.779C141.263 262.07 153.073 260.628 154.068 259.003C152.625 255.214 147.633 258.099 135.181 256.779Z"
                fill="#BCA799"
              />
              <path
                d="M427.037 460.444C427.118 442.683 420.14 428.252 411.451 428.213C402.762 428.174 395.653 442.54 395.573 460.301C395.492 478.063 402.47 492.493 411.159 492.533C419.848 492.572 426.957 478.206 427.037 460.444Z"
                fill="#C7B299"
              />
              <path
                d="M25.1826 428.13C25.2444 414.498 19.8596 403.423 13.1553 403.392C6.45095 403.362 0.965859 414.388 0.903999 428.02C0.84214 441.652 6.22693 452.727 12.9313 452.758C19.6356 452.788 25.1207 441.762 25.1826 428.13Z"
                fill="#7A6459"
              />
              <path
                d="M391.464 495.813C389.035 495.661 386.741 494.279 384.638 491.707C378.89 484.646 374.189 467.228 376.818 448.845C378.277 438.587 380.845 433.612 383.565 428.346C385.763 424.08 388.04 419.657 389.869 411.784L391.581 -61.9785H396.36L394.623 412.318L394.566 412.575C392.593 421.212 390.039 426.157 387.788 430.519C385.151 435.628 382.874 440.043 381.527 449.501C379.185 465.92 383.148 482.309 388.331 488.67C389.522 490.125 390.734 490.964 391.746 491.025L391.464 495.813Z"
                fill="#173A18"
              />
              <path
                d="M24.4968 458.847C22.2723 458.712 20.1912 457.47 18.3099 455.158C12.6616 448.206 9.94174 433.46 11.8491 420.052C13.0874 411.389 15.351 407.018 17.5451 402.747C19.3743 399.201 21.2686 395.534 22.785 389L24.2231 -84.5411H29.0024L27.5643 389.53L27.5036 389.791C25.8395 397.077 23.6888 401.243 21.7944 404.919C19.6003 409.173 17.7016 412.844 16.5807 420.708C15.1078 431.066 16.7327 445.647 22.0117 452.121C22.9893 453.32 23.9756 454.011 24.7881 454.059L24.4968 458.847Z"
                fill="#173A18"
              />
              <path
                d="M27.0645 69.0392C26.4084 53.9627 32.3826 52.4854 32.3826 52.4854C32.1089 55.5007 33.4254 56.5391 33.4254 56.5391C33.1516 53.7454 35.0763 51.3862 35.0763 51.3862C39.5775 50.9517 42.0844 48.8662 42.0844 48.8662C43.4367 52.7163 43.4367 56.9122 42.0844 60.7623C42.0844 60.7623 41.2155 62.0657 37.2052 62.1874C37.2052 62.1874 38.196 63.1737 41.0462 62.9521C41.0462 62.9521 39.4559 69.9038 27.0645 69.8038V69.0392Z"
                fill="#28C382"
              />
              <path
                d="M42.0804 48.8662C41.5032 51.0068 40.6255 53.0547 39.4735 54.949C38.8999 55.898 38.2443 56.795 37.5141 57.6297C36.7789 58.4673 35.9476 59.2154 35.0375 59.8586C34.9202 59.9368 34.8073 60.0194 34.69 60.0932L34.3293 60.3148C34.0947 60.4582 33.8644 60.5929 33.6341 60.7493C33.1826 61.0455 32.7474 61.3661 32.3307 61.7095C31.9081 62.0517 31.5062 62.4188 31.1271 62.8087C30.7491 63.1998 30.3885 63.6082 30.0496 64.034C29.3764 64.894 28.7845 65.8147 28.2814 66.7842C27.7697 67.7538 27.3577 68.7728 27.0518 69.8256C27.1599 69.2862 27.3051 68.7549 27.4862 68.2354C27.6687 67.714 27.8771 67.2013 28.1074 66.7017C28.5736 65.6954 29.143 64.7402 29.8063 63.8515C30.4751 62.9618 31.2416 62.15 32.0916 61.4314C32.5194 61.0753 32.9678 60.7445 33.4343 60.4408C33.6645 60.2887 33.9122 60.1453 34.1382 60.0063L34.4857 59.7891C34.5986 59.7196 34.7073 59.637 34.8202 59.5631C35.7108 58.935 36.5257 58.206 37.2489 57.3907C37.977 56.5775 38.6368 55.7056 39.2215 54.7838C39.8111 53.8615 40.3436 52.9038 40.816 51.9163C41.3012 50.9272 41.7235 49.9085 42.0804 48.8662Z"
                fill="#0A9E76"
              />
              <path
                d="M25.6395 106.713C26.2956 91.6366 20.3214 90.1594 20.3214 90.1594C20.5951 93.1747 19.2786 94.2131 19.2786 94.2131C19.5567 91.4194 17.6319 89.0602 17.6319 89.0602C13.1307 88.6257 10.6194 86.5402 10.6194 86.5402C9.26946 90.3906 9.26946 94.5858 10.6194 98.4363C10.6194 98.4363 11.4883 99.7397 15.4986 99.8614C15.4986 99.8614 14.5125 100.848 11.6623 100.626C11.6623 100.626 13.2524 107.578 25.6395 107.482V106.713Z"
                fill="#035F48"
              />
              <path
                d="M10.6191 86.5402C11.1963 88.6807 12.074 90.7287 13.226 92.6229C13.7982 93.5719 14.4524 94.4689 15.1812 95.3036C15.9169 96.1423 16.7497 96.8906 17.6621 97.5325C17.775 97.6107 17.8881 97.6933 18.0098 97.7671L18.366 97.9887C18.6049 98.1365 18.8309 98.2668 19.0612 98.4232C19.5127 98.7196 19.9478 99.0401 20.3646 99.3834C20.7848 99.7268 21.1851 100.094 21.5637 100.483C21.9417 100.874 22.3023 101.282 22.6412 101.708C23.3174 102.566 23.9097 103.487 24.4097 104.458C24.9237 105.427 25.3358 106.446 25.6393 107.5C25.5337 106.958 25.3885 106.425 25.2048 105.905C25.0223 105.384 24.8182 104.871 24.5879 104.371C24.1185 103.366 23.5477 102.411 22.8845 101.521C22.2157 100.631 21.4492 99.8196 20.5993 99.101C20.173 98.7449 19.7261 98.4141 19.261 98.1104C19.0264 97.9583 18.7832 97.8149 18.5529 97.6759L18.2096 97.4587C18.0923 97.3891 17.9837 97.3066 17.8751 97.2327C16.9845 96.6046 16.1696 95.8756 15.4464 95.0603C14.7169 94.2472 14.0556 93.3753 13.4694 92.4534C12.8813 91.5301 12.3489 90.5726 11.8749 89.5859C11.3928 88.598 10.9734 87.5807 10.6191 86.5402Z"
                fill="#0A9E76"
              />
              <path
                d="M25.4352 43.5613C26.0956 28.4848 20.1172 27.0032 20.1172 27.0032C20.391 30.0185 19.0788 31.0612 19.0788 31.0612C19.3525 28.2675 17.4276 25.9083 17.4276 25.9083C12.9264 25.4738 10.4153 23.3883 10.4153 23.3883C9.06536 27.2388 9.06536 31.4339 10.4153 35.2844C10.4153 35.2844 11.2842 36.5878 15.2945 36.7095C15.2945 36.7095 14.3082 37.6958 11.458 37.4742C11.458 37.4742 13.0481 44.4259 25.4352 44.326V43.5613Z"
                fill="#0A9E76"
              />
              <path
                d="M10.415 23.3752C10.7666 24.4141 11.1831 25.4299 11.6621 26.4166C12.1352 27.4024 12.6676 28.3585 13.2566 29.2798C13.8429 30.2015 14.504 31.0734 15.2334 31.8867C15.9566 32.702 16.7715 33.431 17.662 34.0591C17.775 34.133 17.8793 34.2155 17.9966 34.2851L18.3399 34.498C18.5701 34.6457 18.8136 34.7891 19.0482 34.9324C19.513 35.2401 19.9611 35.5722 20.3907 35.9274C21.2382 36.6472 22.0033 37.4589 22.6717 38.3475C23.3349 39.2372 23.9055 40.1922 24.3749 41.1977C24.6051 41.6973 24.8093 42.21 24.9918 42.7314C25.1749 43.2502 25.3201 43.7817 25.4263 44.3216C25.1212 43.2685 24.7092 42.2494 24.1967 41.2802C23.6957 40.3109 23.1036 39.3914 22.4284 38.5343C22.0895 38.0998 21.7289 37.7001 21.3509 37.3091C20.9712 36.92 20.5708 36.5516 20.1516 36.2055C19.7336 35.8649 19.2986 35.5459 18.8482 35.2496C18.6179 35.1019 18.3921 34.9672 18.1532 34.8151L17.7968 34.5979C17.6751 34.524 17.5666 34.4415 17.4493 34.3633C16.5388 33.7189 15.7062 32.9709 14.9684 32.1344C14.24 31.2978 13.5858 30.3994 13.0132 29.4493C11.864 27.5578 10.9892 25.5127 10.415 23.3752Z"
                fill="#035F48"
              />
              <path
                d="M26.9557 127.655C26.2953 112.579 32.2739 111.101 32.2739 111.101C32.0002 114.112 33.3121 115.155 33.3121 115.155C33.0384 112.361 34.9633 110.002 34.9633 110.002C39.4645 109.568 41.9757 107.482 41.9757 107.482C43.3256 111.333 43.3256 115.528 41.9757 119.378C41.9757 119.378 41.1067 120.682 37.0965 120.803C37.0965 120.803 38.0828 121.79 40.933 121.568C40.933 121.568 39.3428 128.52 26.9557 128.42V127.655Z"
                fill="#0A9E76"
              />
              <path
                d="M41.9756 107.482C41.624 108.521 41.2077 109.537 40.7288 110.523C40.2547 111.51 39.7223 112.468 39.1343 113.391C38.5481 114.313 37.8867 115.185 37.1573 115.998C36.4341 116.813 35.6191 117.542 34.7286 118.17C34.6156 118.244 34.507 118.327 34.394 118.396L34.0508 118.613L33.3426 119.048C32.8778 119.356 32.4295 119.688 31.9999 120.043C31.1524 120.763 30.3874 121.574 29.7189 122.463C29.0558 123.353 28.4851 124.308 28.0158 125.313C27.784 125.814 27.5782 126.325 27.3988 126.847C27.2157 127.366 27.0705 127.897 26.9644 128.437C27.2684 127.384 27.6805 126.364 28.194 125.396C28.6939 124.424 29.286 123.503 29.9622 122.645C30.3011 122.211 30.6617 121.811 31.0397 121.42C31.4183 121.031 31.8188 120.664 32.239 120.321C32.6566 119.979 33.0916 119.658 33.5425 119.361C33.7727 119.213 34.0029 119.078 34.2419 118.926L34.5981 118.709C34.7154 118.635 34.8285 118.553 34.9458 118.474C35.8559 117.831 36.687 117.083 37.4222 116.246C38.1535 115.41 38.8093 114.512 39.3819 113.56C40.5299 111.667 41.4031 109.621 41.9756 107.482Z"
                fill="#035F48"
              />
              <path
                d="M25.7479 193.457C26.404 178.385 20.4297 176.904 20.4297 176.904C20.7035 179.919 19.387 180.962 19.387 180.962C19.6608 178.164 17.7361 175.809 17.7361 175.809C13.2392 175.374 10.7278 173.284 10.7278 173.284C9.37786 177.135 9.37786 181.33 10.7278 185.18C10.7278 185.18 11.5967 186.484 15.607 186.605C15.607 186.605 14.6209 187.592 11.7707 187.375C11.7707 187.375 13.3608 194.326 25.7479 194.226V193.457Z"
                fill="#28C382"
              />
              <path
                d="M10.728 173.271C11.3045 175.412 12.1822 177.46 13.3349 179.354C13.907 180.304 14.5612 181.203 15.2901 182.039C16.028 182.875 16.8605 183.623 17.7709 184.268C17.8839 184.346 17.997 184.429 18.1187 184.503L18.4749 184.72L19.1701 185.154C20.0828 185.745 20.9266 186.436 21.6857 187.214C22.0637 187.605 22.4243 188.013 22.7632 188.439C23.4395 189.297 24.0316 190.218 24.5315 191.189C25.0455 192.158 25.4576 193.177 25.7611 194.231C25.6555 193.691 25.5103 193.159 25.3266 192.64C25.1442 192.119 24.94 191.606 24.7097 191.102C24.2403 190.098 23.6697 189.145 23.0066 188.256C22.3401 187.365 21.5731 186.553 20.7211 185.836C20.2959 185.478 19.849 185.145 19.3829 184.841C19.1482 184.689 18.905 184.546 18.6747 184.407L18.3314 184.194C18.2141 184.12 18.1055 184.042 17.9969 183.964C17.1053 183.337 16.2902 182.608 15.5682 181.791C14.8399 180.977 14.1789 180.105 13.5914 179.185C13.0025 178.262 12.4698 177.304 11.9967 176.317C11.5102 175.329 11.0865 174.312 10.728 173.271Z"
                fill="#0A9E76"
              />
              <path
                d="M26.3653 292.054C25.7092 276.978 31.6832 275.5 31.6832 275.5C31.4095 278.516 32.7261 279.554 32.7261 279.554C32.4524 276.76 34.3771 274.401 34.3771 274.401C38.8783 273.967 41.3852 271.881 41.3852 271.881C42.7351 275.732 42.7351 279.927 41.3852 283.777C41.3852 283.777 40.5163 285.081 36.506 285.202C36.506 285.202 37.4923 286.189 40.3425 285.971C40.3425 285.971 38.7567 292.923 26.3653 292.823V292.054Z"
                fill="#28C382"
              />
              <path
                d="M41.385 271.881C40.8078 274.022 39.9301 276.07 38.7781 277.964C38.204 278.913 37.5485 279.81 36.8187 280.645C36.083 281.483 35.2502 282.232 34.3378 282.873C34.2249 282.952 34.1118 283.034 33.9901 283.108L33.6339 283.33C33.3993 283.477 33.169 283.608 32.9387 283.764C32.026 284.355 31.1821 285.045 30.423 285.824C30.045 286.215 29.6844 286.623 29.3455 287.049C28.6693 287.907 28.0772 288.828 27.5773 289.799C27.0633 290.768 26.6512 291.787 26.3477 292.84C26.4532 292.299 26.5985 291.766 26.7821 291.246C26.9646 290.729 27.1689 290.212 27.4035 289.712C27.8697 288.706 28.4389 287.751 29.1022 286.862C29.771 285.972 30.5377 285.161 31.3877 284.442C31.8147 284.085 32.2629 283.754 32.7302 283.451C32.9604 283.299 33.2038 283.156 33.4341 283.017L33.7773 282.8C33.8946 282.73 34.0032 282.648 34.1161 282.574C35.0054 281.945 35.8189 281.217 36.5406 280.401C37.2689 279.587 37.9299 278.715 38.5174 277.794C39.107 276.872 39.6397 275.914 40.1121 274.927C40.6001 273.939 41.0251 272.922 41.385 271.881Z"
                fill="#0A9E76"
              />
              <path
                d="M25.7132 265.903C26.3693 250.826 20.3951 249.349 20.3951 249.349C20.6688 252.364 19.3524 253.403 19.3524 253.403C19.6304 250.609 17.7057 248.25 17.7057 248.25C13.2044 247.815 10.6931 245.73 10.6931 245.73C9.34319 249.58 9.34319 253.775 10.6931 257.626C10.6931 257.626 11.5623 258.929 15.5725 259.051C15.5725 259.051 14.5862 260.037 11.736 259.816C11.736 259.816 13.3262 266.767 25.7132 266.667V265.903Z"
                fill="#035F48"
              />
              <path
                d="M10.6934 245.73C11.2706 247.87 12.1483 249.918 13.3002 251.812C13.8724 252.761 14.5266 253.658 15.2554 254.493C15.9911 255.332 16.8241 256.08 17.7365 256.722C17.8495 256.8 17.9623 256.883 18.084 256.957L18.4402 257.178C18.6792 257.322 18.9051 257.456 19.1354 257.613C19.5869 257.909 20.022 258.23 20.4388 258.573C20.859 258.916 21.2595 259.283 21.6381 259.672C22.0161 260.063 22.3767 260.472 22.7156 260.897C23.3919 261.755 23.984 262.676 24.4839 263.648C24.9974 264.616 25.4095 265.636 25.7135 266.689C25.6073 266.149 25.4621 265.618 25.279 265.099C25.0965 264.577 24.8924 264.065 24.6621 263.565C24.1928 262.56 23.6221 261.605 22.959 260.715C22.2902 259.825 21.5235 259.013 20.6735 258.295C20.2472 257.939 19.8003 257.608 19.3352 257.304C19.1006 257.152 18.8574 257.009 18.6271 256.87L18.2838 256.652C18.1665 256.583 18.0579 256.5 17.9493 256.427C17.0587 255.798 16.2438 255.069 15.5206 254.254C14.7911 253.441 14.1298 252.569 13.5436 251.647C12.9556 250.724 12.4231 249.766 11.9491 248.78C11.4668 247.79 11.0474 246.772 10.6934 245.73Z"
                fill="#0A9E76"
              />
              <path
                d="M26.3736 218.631C25.7175 203.555 31.6915 202.077 31.6915 202.077C31.4178 205.093 32.7344 206.131 32.7344 206.131C32.4607 203.337 34.3809 200.978 34.3809 200.978C38.8822 200.544 41.3935 198.458 41.3935 198.458C42.7434 202.309 42.7434 206.504 41.3935 210.354C41.3935 210.354 40.5246 211.658 36.5143 211.779C36.5143 211.779 37.5006 212.766 40.3508 212.548C40.3508 212.548 38.7606 219.5 26.3736 219.4V218.631Z"
                fill="#0A9E76"
              />
              <path
                d="M41.3936 198.458C41.042 199.497 40.6255 200.513 40.1465 201.499C39.6725 202.486 39.1401 203.444 38.552 204.367C37.9646 205.288 37.3035 206.16 36.5752 206.974C35.851 207.788 35.0362 208.517 34.1466 209.146C34.0379 209.22 33.9293 209.303 33.812 209.372L33.4687 209.59C33.2385 209.733 32.995 209.876 32.7604 210.024C32.2953 210.328 31.8486 210.659 31.4223 211.015C30.5724 211.733 29.8057 212.545 29.1369 213.435C28.4737 214.324 27.9031 215.279 27.4337 216.285C27.2035 216.785 26.9993 217.302 26.8168 217.819C26.6331 218.339 26.4879 218.872 26.3823 219.413C26.6859 218.36 27.098 217.34 27.6119 216.372C28.1119 215.4 28.704 214.479 29.3802 213.622C29.7191 213.187 30.0797 212.787 30.4577 212.396C31.2168 211.618 32.0606 210.927 32.9734 210.337C33.2036 210.189 33.434 210.059 33.6686 209.902L34.0248 209.681C34.1464 209.607 34.2593 209.524 34.3723 209.446C35.2847 208.804 36.1177 208.056 36.8534 207.217C37.5832 206.383 38.2374 205.486 38.8085 204.537C39.9526 202.642 40.8228 200.596 41.3936 198.458Z"
                fill="#035F48"
              />
              <path
                d="M26.8039 353.85C26.1435 338.778 32.1176 337.297 32.1176 337.297C31.8439 340.312 33.1605 341.355 33.1605 341.355C32.8868 338.557 34.8115 336.202 34.8115 336.202C39.3127 335.767 41.8196 333.677 41.8196 333.677C43.1719 337.527 43.1719 341.723 41.8196 345.573C41.8196 345.573 40.9508 346.877 36.9448 346.999C36.9448 346.999 37.9309 347.985 40.7811 347.768C40.7811 347.768 39.191 354.719 26.8039 354.619V353.85Z"
                fill="#035F48"
              />
              <path
                d="M41.8193 333.677C41.2429 335.818 40.3652 337.866 39.2124 339.76C38.6388 340.71 37.9832 341.609 37.253 342.445C36.5166 343.281 35.6854 344.029 34.7764 344.674C34.6591 344.748 34.5462 344.835 34.4289 344.904L34.0725 345.126C33.8335 345.274 33.6033 345.404 33.373 345.56C32.9215 345.857 32.4864 346.177 32.0696 346.521C31.649 346.865 31.2472 347.232 30.8661 347.62C30.4924 348.011 30.1318 348.419 29.7886 348.845C29.1153 349.705 28.5234 350.626 28.0203 351.595C27.5077 352.564 27.097 353.583 26.7949 354.637C26.9019 354.097 27.0471 353.566 27.2294 353.046C27.4119 352.525 27.6205 352.012 27.8508 351.508C28.3202 350.505 28.8894 349.552 29.5497 348.663C30.2197 347.773 30.986 346.96 31.8349 346.238C32.2627 345.882 32.7109 345.551 33.1774 345.248C33.4077 345.095 33.6554 344.952 33.8813 344.813L34.229 344.6L34.5636 344.37C35.4543 343.742 36.2692 343.013 36.9923 342.197C37.7192 341.383 38.3789 340.511 38.9648 339.591C40.1433 337.739 41.0972 335.754 41.8064 333.677H41.8193Z"
                fill="#0A9E76"
              />
              <path
                d="M26.8039 168.809C26.1435 153.733 32.1176 152.251 32.1176 152.251C31.8439 155.266 33.1605 156.309 33.1605 156.309C32.8868 153.515 34.8115 151.156 34.8115 151.156C39.3127 150.722 41.8196 148.636 41.8196 148.636C43.1719 152.486 43.1719 156.682 41.8196 160.532C41.8196 160.532 40.9508 161.836 36.9448 161.957C36.9448 161.957 37.9309 162.944 40.7811 162.722C40.7811 162.722 39.191 169.674 26.8039 169.574V168.809Z"
                fill="#035F48"
              />
              <path
                d="M41.8193 148.636C41.2433 150.777 40.3656 152.825 39.2124 154.719C38.6399 155.67 37.9842 156.569 37.253 157.404C36.5178 158.241 35.6864 158.99 34.7764 159.633C34.6591 159.711 34.5462 159.794 34.4289 159.867L34.0725 160.085C33.8335 160.232 33.6033 160.367 33.373 160.519C32.9225 160.815 32.4875 161.134 32.0696 161.475C31.6489 161.821 31.2472 162.189 30.8661 162.579C30.4924 162.97 30.1318 163.378 29.7886 163.804C29.1163 164.663 28.5244 165.582 28.0203 166.55C27.5085 167.519 27.0977 168.538 26.7949 169.591C26.9031 169.052 27.0482 168.52 27.2294 168.001C27.4119 167.48 27.6205 166.967 27.8508 166.467C28.3203 165.463 28.8894 164.508 29.5497 163.617C30.5484 162.274 31.7764 161.118 33.1774 160.202C33.4077 160.054 33.6554 159.911 33.8813 159.767L34.229 159.555C34.342 159.485 34.4506 159.403 34.5636 159.329C35.4542 158.701 36.2691 157.972 36.9923 157.156C37.7202 156.343 38.38 155.471 38.9648 154.549C40.1444 152.7 41.0983 150.716 41.8064 148.64L41.8193 148.636Z"
                fill="#0A9E76"
              />
              <path
                d="M25.4263 321.116C26.0823 306.04 20.1083 304.563 20.1083 304.563C20.382 307.578 19.0654 308.616 19.0654 308.616C19.3391 305.823 17.4145 303.463 17.4145 303.463C12.9132 303.029 10.4063 300.943 10.4063 300.943C9.05401 304.794 9.05401 308.99 10.4063 312.84C10.4063 312.84 11.2753 314.143 15.2855 314.265C15.2855 314.265 14.295 315.251 11.4448 315.029C11.4448 315.029 13.0349 321.981 25.4263 321.881V321.116Z"
                fill="#0A9E76"
              />
              <path
                d="M10.4067 300.943C10.7583 301.982 11.1748 302.998 11.6537 303.985C12.1261 304.972 12.6586 305.93 13.2483 306.852C13.833 307.774 14.4927 308.646 15.2208 309.459C15.944 310.275 16.7589 311.004 17.6495 311.632C17.7625 311.706 17.8711 311.788 17.9841 311.858L18.3316 312.075C18.5575 312.218 18.8052 312.362 19.0355 312.509C19.502 312.813 19.9502 313.144 20.378 313.5C21.2279 314.219 21.9946 315.03 22.6634 315.92C23.3268 316.809 23.8962 317.764 24.3623 318.77C24.5926 319.27 24.7967 319.783 24.9835 320.304C25.1646 320.823 25.3098 321.355 25.418 321.894C25.1139 320.841 24.7019 319.822 24.1884 318.853C23.6869 317.882 23.0949 316.962 22.4201 316.103C22.0812 315.668 21.7206 315.268 21.3426 314.877C20.9636 314.487 20.5617 314.12 20.1391 313.778C19.7223 313.435 19.2872 313.114 18.8356 312.818C18.6054 312.67 18.375 312.535 18.1404 312.383L17.7798 312.162C17.6625 312.088 17.5495 312.005 17.4365 311.927C16.5241 311.285 15.6913 310.537 14.9556 309.698C14.2268 308.862 13.5714 307.966 12.9962 307.018C11.851 305.125 10.9793 303.08 10.4067 300.943Z"
                fill="#035F48"
              />
              <path
                d="M393.723 187.322C393.067 172.25 399.041 170.769 399.041 170.769C398.768 173.784 400.084 174.827 400.084 174.827C399.81 172.029 401.735 169.674 401.735 169.674C406.232 169.239 408.743 167.149 408.743 167.149C410.093 171 410.093 175.195 408.743 179.045C408.743 179.045 407.874 180.349 403.864 180.471C403.864 180.471 404.85 181.457 407.7 181.24C407.7 181.24 406.11 188.191 393.723 188.091V187.322Z"
                fill="#28C382"
              />
              <path
                d="M408.743 167.149C408.167 169.29 407.289 171.338 406.136 173.232C405.564 174.182 404.91 175.081 404.181 175.917C403.443 176.754 402.611 177.502 401.7 178.146C401.587 178.224 401.474 178.307 401.352 178.381L400.996 178.598L400.301 179.032C399.388 179.623 398.544 180.314 397.785 181.092C397.407 181.483 397.047 181.891 396.708 182.317C396.032 183.175 395.44 184.096 394.94 185.067C394.426 186.036 394.013 187.055 393.71 188.109C393.816 187.569 393.961 187.037 394.144 186.519C394.327 185.997 394.531 185.484 394.766 184.98C395.232 183.976 395.801 183.022 396.465 182.135C397.131 181.243 397.898 180.431 398.75 179.715C399.176 179.344 399.624 179 400.092 178.685C400.327 178.533 400.57 178.389 400.801 178.25L401.144 178.037C401.261 177.964 401.37 177.885 401.478 177.807C402.37 177.18 403.185 176.451 403.907 175.635C404.635 174.821 405.297 173.949 405.884 173.028C407.063 171.177 408.016 169.191 408.726 167.115L408.743 167.149Z"
                fill="#0A9E76"
              />
              <path
                d="M392.298 252.855C392.954 237.783 386.98 236.301 386.98 236.301C387.253 239.317 385.937 240.359 385.937 240.359C386.211 237.561 384.286 235.207 384.286 235.207C379.785 234.772 377.278 232.682 377.278 232.682C375.926 236.532 375.926 240.728 377.278 244.578C377.278 244.578 378.147 245.882 382.157 246.003C382.157 246.003 381.171 246.99 378.316 246.772C378.316 246.772 379.906 253.724 392.298 253.624V252.855Z"
                fill="#035F48"
              />
              <path
                d="M377.278 232.682C377.854 234.823 378.732 236.871 379.885 238.765C380.46 239.714 381.115 240.613 381.844 241.45C382.581 242.286 383.414 243.033 384.325 243.675C384.438 243.753 384.551 243.84 384.668 243.909L385.029 244.131C385.264 244.278 385.494 244.409 385.724 244.565C386.635 245.159 387.478 245.849 388.24 246.625C388.618 247.016 388.978 247.424 389.317 247.85C389.992 248.709 390.584 249.63 391.086 250.6C391.6 251.569 392.012 252.588 392.315 253.642C392.208 253.102 392.063 252.57 391.881 252.051C391.698 251.53 391.49 251.017 391.259 250.513C390.794 249.508 390.225 248.554 389.56 247.667C388.893 246.775 388.126 245.962 387.275 245.243C386.847 244.887 386.399 244.556 385.933 244.252C385.702 244.1 385.455 243.957 385.229 243.818L384.881 243.605L384.547 243.375C383.656 242.747 382.841 242.018 382.118 241.202C381.391 240.388 380.731 239.516 380.145 238.595C378.967 236.744 378.013 234.759 377.304 232.682H377.278Z"
                fill="#0A9E76"
              />
              <path
                d="M392.094 158.338C392.75 143.262 386.776 141.78 386.776 141.78C387.05 144.795 385.733 145.838 385.733 145.838C386.007 143.044 384.082 140.685 384.082 140.685C379.581 140.251 377.074 138.165 377.074 138.165C375.724 142.016 375.724 146.211 377.074 150.061C377.074 150.061 377.943 151.365 381.953 151.486C381.953 151.486 380.967 152.473 378.117 152.251C378.117 152.251 379.702 159.203 392.094 159.103V158.338Z"
                fill="#0A9E76"
              />
              <path
                d="M377.065 138.165C377.416 139.204 377.833 140.22 378.312 141.207C378.784 142.194 379.317 143.152 379.906 144.074C380.493 144.996 381.154 145.868 381.883 146.681C382.605 147.496 383.419 148.225 384.308 148.853C384.421 148.927 384.529 149.01 384.647 149.079L384.99 149.292L385.694 149.727C386.16 150.032 386.608 150.364 387.036 150.722C387.886 151.44 388.653 152.252 389.322 153.142C390.314 154.476 391.096 155.955 391.642 157.526C391.825 158.044 391.97 158.576 392.076 159.116C391.772 158.062 391.36 157.043 390.847 156.074C390.347 155.103 389.755 154.182 389.078 153.324C388.739 152.89 388.379 152.49 388.001 152.099C387.622 151.71 387.222 151.343 386.802 151C386.385 150.656 385.95 150.336 385.498 150.04C385.268 149.892 385.038 149.757 384.803 149.605L384.447 149.388C384.325 149.314 384.212 149.231 384.099 149.153C383.188 148.51 382.355 147.762 381.618 146.924C380.888 146.088 380.233 145.189 379.659 144.239C378.511 142.348 377.638 140.302 377.065 138.165Z"
                fill="#035F48"
              />
              <path
                d="M393.614 127.655C392.954 112.579 398.928 111.097 398.928 111.097C398.654 114.112 399.971 115.155 399.971 115.155C399.697 112.361 401.622 110.002 401.622 110.002C406.123 109.568 408.63 107.482 408.63 107.482C409.983 111.331 409.983 115.525 408.63 119.374C408.63 119.374 407.761 120.677 403.755 120.799C403.755 120.799 404.741 121.79 407.592 121.568C407.592 121.568 406.002 128.52 393.614 128.42V127.655Z"
                fill="#0A9E76"
              />
              <path
                d="M408.63 107.482C407.922 109.558 406.968 111.542 405.788 113.391C405.204 114.313 404.544 115.185 403.816 115.998C403.093 116.813 402.278 117.542 401.387 118.17C401.274 118.249 401.166 118.331 401.053 118.401L400.705 118.613L400.001 119.048C398.6 119.964 397.372 121.12 396.373 122.463C395.711 123.353 395.142 124.308 394.674 125.313C394.444 125.813 394.24 126.326 394.053 126.847C393.872 127.366 393.727 127.898 393.619 128.437C393.921 127.384 394.332 126.365 394.844 125.396C395.35 124.424 395.943 123.501 396.617 122.637C396.96 122.202 397.32 121.807 397.694 121.416C398.075 121.027 398.477 120.658 398.898 120.312C399.315 119.972 399.751 119.653 400.201 119.356C400.431 119.209 400.661 119.074 400.9 118.922L401.257 118.705C401.374 118.631 401.487 118.548 401.604 118.47C402.514 117.827 403.346 117.079 404.081 116.241C404.812 115.406 405.468 114.507 406.04 113.556C407.187 111.664 408.059 109.619 408.63 107.482Z"
                fill="#035F48"
              />
              <path
                d="M392.407 101.252C393.063 86.1795 387.089 84.698 387.089 84.698C387.362 87.7133 386.046 88.756 386.046 88.756C386.32 85.958 384.395 83.6031 384.395 83.6031C379.894 83.1686 377.387 81.0787 377.387 81.0787C376.034 84.9288 376.034 89.1248 377.387 92.9748C377.387 92.9748 378.256 94.2783 382.266 94.3999C382.266 94.3999 381.275 95.3862 378.425 95.169C378.425 95.169 380.015 102.121 392.407 102.021V101.252Z"
                fill="#28C382"
              />
              <path
                d="M377.387 81.0787C377.963 83.2196 378.841 85.2676 379.994 87.1615C380.567 88.1119 381.223 89.0104 381.953 89.8465C382.691 90.683 383.523 91.431 384.434 92.0754C384.547 92.1536 384.66 92.2362 384.777 92.3101L385.138 92.5273L385.833 92.9618C386.285 93.258 386.72 93.5786 387.136 93.922C387.558 94.2654 387.96 94.6324 388.34 95.0212C388.718 95.4122 389.079 95.8207 389.417 96.2464C390.09 97.1072 390.682 98.0278 391.186 98.9967C391.698 99.9661 392.11 100.985 392.415 102.038C392.307 101.499 392.162 100.967 391.981 100.448C391.798 99.9265 391.59 99.4138 391.359 98.9098C390.893 97.9049 390.324 96.9512 389.661 96.064C388.994 95.1723 388.227 94.3603 387.375 93.6439C386.948 93.2864 386.499 92.9541 386.033 92.6489C385.803 92.4969 385.555 92.3535 385.329 92.2145L384.981 92.0016L384.647 91.7713C383.756 91.1433 382.941 90.4143 382.218 89.5989C381.491 88.7847 380.831 87.9129 380.245 86.992C379.067 85.1407 378.113 83.1556 377.404 81.0787H377.387Z"
                fill="#0A9E76"
              />
              <path
                d="M393.024 383.108C392.363 368.032 398.342 366.555 398.342 366.555C398.068 369.565 399.38 370.608 399.38 370.608C399.106 367.815 401.031 365.455 401.031 365.455C405.532 365.021 408.044 362.935 408.044 362.935C409.393 366.786 409.393 370.981 408.044 374.831C408.044 374.831 407.175 376.135 403.164 376.256C403.164 376.256 404.151 377.243 407.001 377.021C407.001 377.021 405.411 383.973 393.024 383.873V383.108Z"
                fill="#28C382"
              />
              <path
                d="M408.043 362.935C407.467 365.076 406.589 367.124 405.436 369.018C404.864 369.968 404.21 370.867 403.481 371.703C402.745 372.541 401.912 373.289 401 373.932C400.883 374.01 400.775 374.093 400.653 374.167L400.297 374.388C400.058 374.532 399.832 374.666 399.602 374.823C399.15 375.119 398.715 375.44 398.298 375.783C397.878 376.126 397.477 376.493 397.099 376.882C396.721 377.273 396.36 377.682 396.021 378.107C395.345 378.965 394.753 379.886 394.253 380.858C393.74 381.826 393.327 382.846 393.023 383.899C393.13 383.359 393.275 382.828 393.458 382.309C393.64 381.787 393.845 381.275 394.075 380.775C394.544 379.77 395.115 378.815 395.778 377.925C396.446 377.036 397.211 376.225 398.059 375.505C398.489 375.15 398.937 374.818 399.402 374.51L400.11 374.075L400.453 373.858C400.57 373.789 400.675 373.706 400.788 373.632C401.678 373.004 402.493 372.275 403.216 371.46C403.946 370.647 404.607 369.775 405.193 368.853C405.781 367.93 406.314 366.972 406.788 365.985C407.27 364.996 407.689 363.977 408.043 362.935Z"
                fill="#0A9E76"
              />
              <path
                d="M392.372 357.396C393.028 342.324 387.054 340.842 387.054 340.842C387.327 343.857 386.011 344.896 386.011 344.896C386.284 342.102 384.36 339.747 384.36 339.747C379.859 339.313 377.352 337.223 377.352 337.223C375.999 341.073 375.999 345.269 377.352 349.119C377.352 349.119 378.221 350.422 382.231 350.544C382.231 350.544 381.244 351.53 378.39 351.313C378.39 351.313 379.98 358.265 392.372 358.165V357.396Z"
                fill="#035F48"
              />
              <path
                d="M377.352 337.223C377.928 339.364 378.806 341.412 379.959 343.305C380.534 344.255 381.189 345.153 381.918 345.991C382.655 346.827 383.488 347.573 384.399 348.215C384.512 348.293 384.625 348.38 384.742 348.45L385.103 348.671C385.338 348.819 385.568 348.949 385.798 349.106C386.711 349.696 387.555 350.387 388.314 351.165C388.692 351.556 389.053 351.965 389.391 352.39C390.066 353.249 390.658 354.17 391.16 355.141C391.674 356.109 392.086 357.128 392.389 358.182C392.282 357.641 392.137 357.108 391.955 356.588C391.772 356.07 391.564 355.558 391.334 355.054C390.868 354.048 390.299 353.095 389.635 352.208C388.967 351.316 388.2 350.503 387.349 349.784C386.922 349.427 386.473 349.097 386.007 348.793C385.777 348.641 385.529 348.497 385.303 348.358L384.955 348.146L384.621 347.915C383.731 347.286 382.916 346.557 382.192 345.743C381.465 344.929 380.805 344.057 380.219 343.136C379.041 341.285 378.087 339.3 377.378 337.223H377.352Z"
                fill="#0A9E76"
              />
              <path
                d="M393.032 232.209C392.372 217.136 398.351 215.655 398.351 215.655C398.072 218.67 399.389 219.713 399.389 219.713C399.115 216.915 401.04 214.56 401.04 214.56C405.541 214.125 408.052 212.036 408.052 212.036C409.402 215.886 409.402 220.081 408.052 223.932C408.052 223.932 407.183 225.235 403.173 225.357C403.173 225.357 404.159 226.343 407.01 226.126C407.01 226.126 405.419 233.078 393.032 232.978V232.209Z"
                fill="#0A9E76"
              />
              <path
                d="M408.052 212.036C407.701 213.075 407.283 214.091 406.801 215.077C406.331 216.065 405.8 217.023 405.211 217.945C404.622 218.865 403.961 219.736 403.234 220.551C402.511 221.367 401.696 222.096 400.805 222.724L400.471 222.954L400.127 223.167C399.897 223.31 399.654 223.454 399.419 223.602C398.953 223.908 398.505 224.24 398.077 224.596C397.227 225.314 396.462 226.126 395.796 227.017C395.132 227.905 394.562 228.858 394.092 229.862C393.862 230.366 393.658 230.879 393.475 231.4C393.292 231.919 393.147 232.451 393.041 232.991C393.345 231.937 393.757 230.918 394.271 229.949C394.772 228.979 395.364 228.058 396.039 227.199C396.378 226.765 396.738 226.365 397.116 225.974C397.496 225.586 397.896 225.219 398.316 224.875C398.733 224.532 399.168 224.212 399.619 223.914L400.319 223.48L400.675 223.263C400.792 223.189 400.905 223.106 401.022 223.028C401.932 222.383 402.762 221.635 403.499 220.799C404.229 219.963 404.885 219.064 405.459 218.114C406.606 216.221 407.479 214.174 408.052 212.036Z"
                fill="#035F48"
              />
              <path
                d="M393.032 32.2344C392.372 17.1622 398.351 15.6806 398.351 15.6806C398.072 18.6959 399.389 19.7387 399.389 19.7387C399.115 16.9406 401.04 14.5857 401.04 14.5857C405.541 14.1513 408.052 12.0614 408.052 12.0614C409.402 15.9119 409.402 20.107 408.052 23.9575C408.052 23.9575 407.183 25.2609 403.173 25.3826C403.173 25.3826 404.159 26.3689 407.01 26.1516C407.01 26.1516 405.419 33.1033 393.032 33.0034V32.2344Z"
                fill="#0A9E76"
              />
              <path
                d="M408.052 12.0614C407.701 13.1011 407.283 14.117 406.801 15.1028C406.331 16.0907 405.8 17.0485 405.211 17.9703C404.622 18.8903 403.961 19.762 403.234 20.5772C402.511 21.3927 401.696 22.1216 400.805 22.7496L400.471 22.9799L400.127 23.1928C399.897 23.3362 399.654 23.4796 399.419 23.6273C398.016 24.5383 396.788 25.6952 395.796 27.0423C395.132 27.9305 394.562 28.884 394.092 29.8882C393.862 30.3922 393.658 30.9048 393.475 31.4262C393.291 31.9447 393.146 32.4763 393.041 33.0164C393.345 31.9628 393.757 30.9436 394.271 29.975C394.771 29.0037 395.363 28.0827 396.039 27.2248C396.378 26.7903 396.738 26.3906 397.116 25.9996C397.496 25.6119 397.896 25.2449 398.316 24.9003C398.733 24.5579 399.168 24.2374 399.619 23.9401C399.849 23.7924 400.08 23.662 400.319 23.5056L400.675 23.284C400.792 23.2145 400.905 23.132 401.022 23.0538C401.932 22.4092 402.762 21.6612 403.499 20.8249C404.229 19.9887 404.885 19.0902 405.459 18.1398C406.606 16.2465 407.479 14.2 408.052 12.0614Z"
                fill="#035F48"
              />
              <path
                d="M393.458 292.202C392.802 277.125 398.776 275.644 398.776 275.644C398.502 278.659 399.819 279.702 399.819 279.702C399.545 276.908 401.47 274.549 401.47 274.549C405.971 274.114 408.478 272.029 408.478 272.029C409.828 275.879 409.828 280.074 408.478 283.925C408.478 283.925 407.609 285.228 403.599 285.35C403.599 285.35 404.585 286.336 407.435 286.115C407.435 286.115 405.849 293.066 393.458 292.966V292.202Z"
                fill="#035F48"
              />
              <path
                d="M408.478 272.029C407.902 274.17 407.024 276.218 405.871 278.112C405.297 279.062 404.641 279.96 403.911 280.797C403.175 281.634 402.342 282.382 401.431 283.026C401.318 283.104 401.205 283.186 401.087 283.26L400.727 283.477C400.492 283.625 400.262 283.76 400.032 283.912C399.581 284.208 399.146 284.527 398.728 284.868C398.309 285.214 397.909 285.582 397.529 285.971C397.151 286.362 396.79 286.771 396.451 287.197C395.778 288.055 395.186 288.974 394.683 289.942C394.17 290.911 393.758 291.93 393.454 292.984C393.562 292.444 393.707 291.913 393.888 291.394C394.434 289.823 395.216 288.344 396.208 287.01C396.877 286.12 397.643 285.308 398.493 284.59C398.922 284.233 399.371 283.901 399.836 283.595L400.54 283.16L400.883 282.947C401 282.878 401.109 282.795 401.222 282.721C402.113 282.093 402.928 281.364 403.651 280.549C404.377 279.735 405.037 278.863 405.623 277.942C406.803 276.093 407.757 274.109 408.465 272.033L408.478 272.029Z"
                fill="#0A9E76"
              />
              <path
                d="M393.458 69.0392C392.802 53.9627 398.776 52.4854 398.776 52.4854C398.502 55.5007 399.819 56.5391 399.819 56.5391C399.545 53.7454 401.47 51.3862 401.47 51.3862C405.971 50.9517 408.478 48.8662 408.478 48.8662C409.828 52.7167 409.828 56.9118 408.478 60.7623C408.478 60.7623 407.609 62.0657 403.599 62.1874C403.599 62.1874 404.585 63.1737 407.435 62.9521C407.435 62.9521 405.849 69.9038 393.458 69.8038V69.0392Z"
                fill="#035F48"
              />
              <path
                d="M408.478 48.8662C407.901 51.0068 407.023 53.0547 405.871 54.949C405.296 55.897 404.64 56.7939 403.911 57.6297C403.176 58.4684 402.343 59.2166 401.431 59.8586C401.318 59.9368 401.205 60.0194 401.087 60.0932L400.727 60.3148C400.492 60.4582 400.262 60.5929 400.032 60.7493C399.58 61.0456 399.145 61.3662 398.728 61.7095C398.308 62.0529 397.908 62.4199 397.529 62.8087C397.151 63.1998 396.79 63.6082 396.451 64.034C395.777 64.893 395.185 65.8138 394.683 66.7842C394.17 67.753 393.758 68.7722 393.454 69.8256C393.562 69.2862 393.707 68.7549 393.888 68.2354C394.434 66.6644 395.216 65.186 396.208 63.8515C396.877 62.9618 397.643 62.15 398.493 61.4314C398.921 61.0753 399.37 60.7445 399.836 60.4408C400.066 60.2887 400.31 60.1453 400.54 60.0063L400.883 59.7891C401 59.7196 401.109 59.637 401.222 59.5631C402.113 58.935 402.928 58.206 403.651 57.3907C404.378 56.5764 405.037 55.7046 405.623 54.7838C406.213 53.8615 406.745 52.9038 407.218 51.9163C407.702 50.9271 408.122 49.9084 408.478 48.8662Z"
                fill="#0A9E76"
              />
              <path
                d="M392.081 313.609C392.741 298.532 386.767 297.051 386.767 297.051C387.041 300.066 385.724 301.109 385.724 301.109C385.998 298.315 384.073 295.956 384.073 295.956C379.572 295.521 377.061 293.436 377.061 293.436C375.711 297.286 375.711 301.481 377.061 305.332C377.061 305.332 377.93 306.635 381.94 306.757C381.94 306.757 380.954 307.743 378.103 307.522C378.103 307.522 379.694 314.473 392.081 314.373V313.609Z"
                fill="#0A9E76"
              />
              <path
                d="M377.065 293.436C377.416 294.475 377.834 295.491 378.316 296.477C378.786 297.464 379.317 298.42 379.906 299.34C380.493 300.262 381.154 301.134 381.883 301.947C382.606 302.762 383.422 303.491 384.312 304.12C384.425 304.193 384.534 304.276 384.647 304.345L384.99 304.558C385.22 304.706 385.468 304.849 385.698 304.993C387.099 305.909 388.327 307.065 389.326 308.408C389.986 309.299 390.555 310.254 391.025 311.258C391.255 311.758 391.459 312.27 391.646 312.792C391.827 313.311 391.972 313.843 392.081 314.382C391.777 313.329 391.366 312.31 390.855 311.341C390.351 310.373 389.759 309.454 389.087 308.595C388.744 308.16 388.383 307.76 388.009 307.369C387.629 306.981 387.228 306.614 386.806 306.27C386.388 305.928 385.953 305.607 385.503 305.31C385.272 305.162 385.042 305.028 384.803 304.876L384.447 304.658C384.33 304.584 384.216 304.502 384.099 304.424C383.189 303.78 382.358 303.032 381.623 302.195C380.892 301.359 380.236 300.461 379.663 299.51C378.514 297.618 377.639 295.573 377.065 293.436Z"
                fill="#035F48"
              />
              <path
                d="M305.376 301.43C306.226 299.871 306.565 298.083 306.344 296.321C309.92 295.756 313.587 295.195 317.341 294.583C317.799 296.293 318.121 298.036 318.306 299.796C318.61 304.745 303.372 315.464 302.364 319.066C301.217 323.193 302.282 328.385 302.247 330.362C302.212 332.339 300.075 332.643 298.489 337.005C298.352 338.029 298.159 339.044 297.911 340.047C297.646 340.833 297.242 340.916 297.173 340.321C296.616 335.394 294.852 320.704 295.687 316.911C296.521 313.118 304.372 303.255 305.376 301.43Z"
                fill="#BCA799"
              />
              <path
                d="M331.705 354.767C333.235 362.822 340.508 390.92 344.861 403.351C349.215 415.781 350.379 429.88 361.884 428.529C365.069 428.151 364.873 428.368 367.967 427.79C369.496 427.499 374.484 423.806 377.026 424.367C379.568 424.927 380.775 427.999 378.395 428.903C376.014 429.806 368.593 432.726 365.269 432.465C361.945 432.205 355.784 437.136 351.365 430.866C346.947 424.597 332.513 386.263 331.054 383.508C328.929 379.532 327.004 375.626 324.21 374.771L331.705 354.767Z"
                fill="#B59578"
              />
              <path
                d="M290.052 433.056C291.203 430.449 295.47 430.488 297.147 428.711C298.824 426.934 302.712 417.758 303.433 416.229C303.828 415.358 304.046 414.418 304.076 413.463C304.106 412.508 303.947 411.556 303.607 410.663C302.856 408.843 297.055 394.496 295.739 383.538C293.957 368.74 304.229 355.084 304.837 348.906C305.454 342.658 302.56 312.918 302.799 306.666C303.038 300.413 304.537 284.659 305.654 279.915C307.826 270.621 310.055 261.41 304.294 256.261C303.998 255.996 301.748 253.898 301.709 253.524C302.612 253.411 311.784 252.621 312.688 252.49C318.601 251.621 322.268 245.617 328.151 244.57C328.177 246.147 330.602 259.624 330.554 261.08C330.324 268.401 332.231 271.833 332.726 275.774C333.356 280.645 334.899 289.929 334.899 303.65C334.899 317.371 336.867 344.492 337.34 347.989C337.662 350.383 337.944 356.548 337.34 364.156C337.014 364.625 336.693 365.095 336.363 365.56C331.01 373.154 325.066 380.28 319.075 387.323C318.391 385.386 317.491 383.534 316.39 381.8C315.047 379.754 313.348 379.923 312.479 381.683C311.311 384.06 307.605 396.134 308.382 401.005C309.16 405.875 310.363 409.221 310.989 411.502C311.615 413.783 311.745 413.783 310.151 416.846C308.556 419.909 305.371 426.643 304.811 428.177C304.25 429.711 304.854 433.204 303.538 434.286C302.221 435.368 299.888 437.214 294.961 437.184C290.999 437.201 288.896 435.659 290.052 433.056Z"
                fill="#D8C7BA"
              />
              <path
                d="M312.588 356.709C311.779 358.627 311.102 360.599 310.564 362.609C310.412 362.492 310.251 362.362 310.081 362.21C305.819 358.343 304.794 340.994 304.733 338.048C304.898 340.738 309.634 352.56 314.522 355.349C313.761 355.28 313.053 355.758 312.588 356.709Z"
                fill="#BCA799"
              />
              <path
                d="M322.116 425.44C322.333 423.871 322.903 418.436 320.652 410.016C316.546 394.652 311.493 395.951 309.82 387.987C301.318 347.516 321.812 351.322 314.691 312.748C313.705 307.396 323.42 295.426 323.42 283.994L318.788 249.775C321.764 247.733 324.541 245.221 328.151 244.561C328.177 246.138 330.602 259.616 330.554 261.071C330.324 268.392 332.231 271.825 332.726 275.765C333.356 280.636 334.899 289.921 334.899 303.642C334.899 317.363 336.867 344.483 337.34 347.98C337.814 351.478 338.209 363.066 335.811 375.435C333.413 387.805 329.446 383.803 329.046 388.535C328.646 393.266 329.62 424.736 330.675 428.073C331.731 431.409 329.998 434.785 329.576 436.762C329.155 438.739 323.124 439.108 320.769 438.674C318.414 438.239 316.49 437.036 316.325 434.764C316.16 432.491 321.147 432.461 322.116 425.44Z"
                fill="#B59578"
              />
              <path
                d="M315.012 314.608C316.75 312.336 325.175 308.117 324.927 298.484C333.369 312.688 317.576 317.936 315.747 319.431C315.773 318.627 314.965 315.381 315.012 314.608Z"
                fill="#A07F67"
              />
              <path
                d="M324.215 276.552C322.959 284.833 326.387 301.113 326.135 306.483C325.909 311.736 309.664 319.422 308.321 323.041C306.766 327.173 307.452 332.804 307.27 334.842C307.087 336.879 303.316 338.691 302.808 341.072C302.434 342.81 301.687 345.791 301.161 344.283C300.926 339.13 300.075 323.18 301.283 319.218C302.491 315.255 311.819 306.461 313.014 304.728C313.707 303.705 314.174 302.546 314.383 301.329C314.593 300.111 314.541 298.863 314.23 297.668C312.366 291.003 310.937 280.162 314.491 270.295C316.298 265.273 321.421 260.949 318.071 248.754"
                fill="#B59578"
              />
              <path
                d="M303.937 247.989L303.203 245.595C303.488 246.38 303.733 247.179 303.937 247.989Z"
                fill="#B59578"
              />
              <path
                d="M324.25 254.35C315.816 258.069 309.803 257.765 301.001 256.201C296.539 255.406 295.179 254.345 292.815 252.564C290.265 251.773 287.714 249.657 287.328 247.806C286.941 245.956 287.458 244.9 288.605 244.504C289.752 244.109 293.706 242.919 295.874 239.877C298.042 236.836 298.172 234.989 301.87 233.929C305.567 232.869 312.966 231.287 318.706 233.004C324.445 234.72 326.483 238.161 327.395 240.824C329.816 242.41 331.158 243.535 330.776 246.838C330.515 248.893 330.246 251.708 324.25 254.35Z"
                fill="#D8C7BA"
              />
              <path
                d="M308.63 237.666C306.436 237.457 300.783 237.566 299.94 240.837C299.098 244.109 302.239 245.1 303.851 245.617C305.463 246.134 306.649 245.864 307.005 244.626C307.579 242.64 310.355 242.545 312.458 242.345C314.335 242.167 314.756 239.669 313.7 238.973C312.645 238.278 310.733 237.861 308.63 237.666Z"
                fill="black"
              />
              <path
                d="M310.285 240.012C310.503 239.443 308.587 238.365 307.035 238.556C305.484 238.748 304.372 239.399 303.994 241.728C304.572 242.109 305.234 242.344 305.922 242.412C306.611 242.481 307.306 242.382 307.948 242.123C309.204 241.685 309.721 241.528 310.285 240.012Z"
                fill="black"
              />
              <path
                d="M288.605 244.504C291.125 245 292.654 245.808 292.654 246.486C292.654 247.163 290.286 249.527 288.396 249.527C287.527 249.527 286.867 247.246 287.058 246.651C287.249 246.055 287.619 244.552 288.605 244.504Z"
                fill="black"
              />
              <path
                d="M323.867 242.258C326.908 241.689 328.364 243.127 328.842 245.33C329.32 247.533 328.121 250.887 323.194 252.764C321.377 253.459 321.282 247.806 321.378 246.616C321.473 245.426 321.764 242.654 323.867 242.258Z"
                fill="black"
              />
              <path
                d="M306.514 241.098C306.879 241.098 307.174 240.791 307.174 240.412C307.174 240.032 306.879 239.725 306.514 239.725C306.149 239.725 305.854 240.032 305.854 240.412C305.854 240.791 306.149 241.098 306.514 241.098Z"
                fill="white"
              />
              <path
                d="M292.815 252.547C299.254 258.364 311.78 256.779 312.836 254.993C311.307 250.848 306.014 254.02 292.815 252.547Z"
                fill="#BCA799"
              />
              <path
                d="M221.086 413.305C204.659 415.603 204.107 418.744 201.969 420.908C199.831 423.072 205.988 423.606 209.46 423.419C212.931 423.233 233.877 420.5 233.877 420.5L233.712 412.314L221.086 413.305Z"
                fill="#D8C7BA"
              />
              <path
                d="M229.137 392.775C233.534 390.568 239.313 383.352 239.565 380.406C239.817 377.46 241.173 364.877 241.173 357.113C241.173 349.349 231.266 358.721 231.266 358.721L228.855 387.636"
                fill="#D8C7BA"
              />
              <path
                d="M238.583 340.746C232.565 347.394 223.81 353.65 222.868 357.191C221.925 360.732 219.161 374.162 219.548 376.048C219.935 377.934 228.038 365.151 228.038 365.151L241.211 355.953L238.604 340.746"
                fill="#BCA799"
              />
              <path
                d="M250.675 336.045C249.775 326.317 256.175 315.125 255.432 305.41C255.528 303.468 256.249 298.332 255.867 295.821C255.602 295.886 255.337 295.956 255.067 296.016C252.026 296.725 248.95 297.35 245.895 298.032C244.157 298.415 242.45 298.819 240.734 299.232C241.042 301.321 235.129 311.358 234.651 315.642C234.277 319.118 233.782 326.07 233.782 326.07C233.578 329.923 232.413 351.925 233.061 355.362C233.708 358.799 233.495 382.213 233.274 385.433C233.052 388.652 220.817 390.16 218.236 392.954C215.655 395.747 215.66 404.12 217.593 412.505C218.566 416.72 223.181 418.814 227.334 420.048C227.448 419.802 227.634 419.597 227.866 419.458C228.099 419.319 228.367 419.254 228.638 419.27C230.962 419.331 233.217 420.369 235.307 421.286C238.374 422.637 241.351 424.193 244.34 425.7C245.695 426.383 247.051 427.069 248.42 427.729C253.633 425.157 259.521 420.826 264.352 416.324C261.584 400.683 258.334 385.116 254.941 369.587C252.534 358.508 251.717 347.303 250.675 336.045Z"
                fill="#D8C7BA"
              />
              <path
                d="M272.376 367.093C272.072 361.788 271.468 345.734 270.86 336.341C270.252 326.947 266.051 317.78 263.292 309.707C261.617 304.766 260.919 299.547 261.237 294.339C259.177 294.904 257.135 295.534 255.054 296.016L254.506 296.142C254.658 299.488 254.302 302.968 254.302 307.791C254.472 318.94 250.009 329.154 247.637 340.108C246.534 345.221 244.783 349.892 241.059 353.672C240.038 354.711 240.503 354.476 241.059 354.059C241.059 362.188 233.43 382.887 233.26 385.428C233.047 388.652 220.804 390.156 218.223 392.949C215.642 395.743 215.646 404.115 217.58 412.501C219.031 418.783 228.876 421.538 232.843 422.272C227.425 422.355 211.441 425.314 208.977 426.37C196.551 431.701 207.274 433.021 210.128 433.278C218.505 434.042 233.26 430.332 240.564 430.115C247.867 429.897 260.407 420.873 267.98 412.692C275.553 404.511 272.681 372.394 272.376 367.093Z"
                fill="#B59578"
              />
              <path
                d="M254.789 400.44C254.846 400.587 254.898 400.739 254.95 400.874C259.099 412.297 260.655 426.395 276.939 424.84C281.405 424.496 285.876 425.45 289.813 427.586C293.289 429.498 293.749 430.714 291.481 431.54C290.673 431.835 289.152 432.048 288.14 431.67C286.311 430.979 283.148 428.863 281.115 428.707C277.952 428.464 266.924 431.822 260.941 427.925C256.975 425.34 252.187 423.684 248.233 414.326C249.462 412.87 253.503 401.839 254.789 400.44Z"
                fill="#B59578"
              />
              <path
                d="M272.377 367.093C272.264 365.09 272.108 361.562 271.921 357.439C271.243 372.138 268.927 386.893 262.892 400.24C259.108 408.612 253.599 416.198 246.234 421.764C238.752 427.412 229.841 430.454 220.639 431.666C218.901 432.283 217.163 432.817 215.36 433.282C223.724 432.778 234.62 430.28 240.56 430.106C247.863 429.893 260.403 420.865 267.976 412.683C275.549 404.502 272.681 372.394 272.377 367.093Z"
                fill="#A07F67"
              />
              <path
                d="M240.147 360.233C241.885 357.965 254.411 350.931 254.163 341.302C260.155 353.807 240.456 365.699 238.626 367.193C238.9 365.464 240.104 361.01 240.147 360.233Z"
                fill="#A07F67"
              />
              <path
                d="M254.542 344.222C254.542 348.749 252.369 350.687 249.584 354.567C246.799 358.447 236.867 364.269 234.065 367.506C231.262 370.743 228.025 378.286 228.025 381.948C228.025 385.611 224.145 387.123 222.208 386.258C223.076 383.026 224.145 369.014 225.87 364.486C227.595 359.959 236.65 351.122 238.375 347.237C240.099 343.353 238.001 339.095 238.592 330.64C239.834 312.783 252.117 310.767 253.746 304.915C255.05 300.292 254.615 297.046 254.615 297.046"
                fill="#B59578"
              />
              <path
                d="M108.356 292.567C100.566 296.221 93.5834 296.516 85.2023 295.378C81.3919 294.861 80.2839 293.388 78.5764 292.054C76.1173 291.498 73.5104 290.39 73.0542 288.808C72.598 287.227 73.0194 286.288 74.0882 285.884C75.157 285.48 78.8329 284.246 80.7272 281.505C82.6215 278.763 84.7113 276.291 86.1364 275.778C89.5514 274.592 96.4553 273.171 101.978 274.379C107.5 275.587 109.616 278.437 110.602 280.679C112.979 281.926 114.304 282.852 114.117 285.711C114 287.518 113.891 289.969 108.356 292.567Z"
                fill="#D8C7BA"
              />
              <path
                d="M98.3933 279.484C96.7987 278.385 92.9016 277.508 90.2773 278.042C88.8913 278.324 86.0931 280.084 85.4588 281.405C84.8244 282.726 84.5899 284.29 86.4756 284.768C88.3612 285.246 90.2554 285.793 90.8898 285.315C91.2808 285.02 90.8898 284.955 91.2243 284.325C91.3456 284.107 91.5257 283.928 91.7439 283.808C91.9621 283.688 92.2098 283.632 92.4584 283.647C93.814 283.625 97.5026 282.726 98.3933 282.004C99.284 281.283 99.788 280.458 98.3933 279.484Z"
                fill="black"
              />
              <path
                d="M94.3786 280.875C94.5524 280.375 92.684 279.541 91.2241 279.784C89.7643 280.028 88.7476 280.653 88.4912 282.691C89.0694 282.99 89.7066 283.157 90.3571 283.181C91.0075 283.205 91.6552 283.085 92.2539 282.83C93.44 282.361 93.9137 282.222 94.3786 280.875Z"
                fill="black"
              />
              <path
                d="M107.357 282.122C110.19 281.47 111.658 282.661 112.227 284.524C112.796 286.388 111.836 289.369 107.274 291.25C105.593 291.941 105.202 287.057 105.232 286.036C105.262 285.015 105.384 282.574 107.357 282.122Z"
                fill="black"
              />
              <path
                d="M91.2419 281.991C91.5874 281.991 91.8675 281.725 91.8675 281.396C91.8675 281.067 91.5874 280.801 91.2419 280.801C90.8963 280.801 90.6162 281.067 90.6162 281.396C90.6162 281.725 90.8963 281.991 91.2419 281.991Z"
                fill="white"
              />
              <path
                d="M78.6851 292.163C80.3839 297.007 91.9454 295.638 96.4553 294.335C90.9374 289.829 91.8889 295.126 78.6851 292.163Z"
                fill="#BCA799"
              />
              <path
                d="M77.7946 286.401C78.1074 286.997 76.4217 287.309 75.6222 288.044C75.0921 288.522 75.7177 289.543 74.9486 290.338C73.2672 289.469 71.7162 286.862 73.8669 285.876C74.6663 285.767 77.4818 285.811 77.7946 286.401Z"
                fill="black"
              />
            </MeerCats>
          </MeerCatsContainer>
        </Right>
      </Header>
    </Wrapper>
  );
};
