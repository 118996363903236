import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { usePreventScroll } from "@react-aria/overlays";
import FocusTrap from "focus-trap-react";
import { Typography } from "../Typography";
import { Scrim } from "./Scrim";
import { COMPRESS_HEADER_BP, Hamburger } from "./Hamburger";

const OVERLAY_EXPANDED_WIDTH = (isLarge: boolean) =>
  isLarge ? 282 + 200 : 282;

export const EXPANDED_WIDTH = (isLarge: boolean) => (isLarge ? 208 + 200 : 208);

const RootContainerProfile = styled.aside<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: calc(100% - 91px);
  height: 100%;
  background-color: ${({ theme }) => theme.colors.charcoal};
  background-clip: padding-box;
  position: fixed;
  left: 0;
  transition: transform 250ms ease-in;
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : `translateX(-100%)`};
  z-index: 9999999;

  @media ${({ theme }) => theme.bp.tabletUp} {
    transition: none;
    transform: translateX(0);
    width: ${EXPANDED_WIDTH(false)}px;
  }

  @media ${COMPRESS_HEADER_BP} {
    width: ${EXPANDED_WIDTH(true)}px;
  }
`;

const RootContainer = styled.aside<{
  isOpen: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.charcoal};
  height: 100%;
  position: fixed;
  width: calc(100% - 91px);
  background-clip: padding-box;
  top: 0;
  right: 0;
  transition: transform 250ms ease-in;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : `translateX(100%)`)};
  z-index: 9999999;

  @media ${({ theme }) => theme.bp.tabletUp} {
    right: 0;
    height: 100%;
    position: fixed;
    width: ${OVERLAY_EXPANDED_WIDTH(false)}px;
    transform: ${({ isOpen }) =>
      isOpen
        ? "translateX(0)"
        : `translateX(${OVERLAY_EXPANDED_WIDTH(false)}px)`};
  }

  @media ${COMPRESS_HEADER_BP} {
    width: ${OVERLAY_EXPANDED_WIDTH(true)}px;
    transform: ${({ isOpen }) =>
      isOpen
        ? "translateX(0)"
        : `translateX(${OVERLAY_EXPANDED_WIDTH(true)}px)`};
  }
`;

const DrawerShadow = styled.div<{
  isOpen: boolean;
  isLeft: boolean;
  isMobile: boolean;
}>`
  opacity: ${({ isMobile, isOpen }) => (isMobile && !isOpen ? 0 : 1)};
  position: fixed;
  top: 0;
  bottom: 0;
  width: 4px;
  height: 100%;
  background-color: ${({ theme }) =>
    theme.colors.opacity(theme.colors.charcoal, 0.1)};
  z-index: 9999998;
  transition: transform 250ms ease-in;
  ${({ isLeft, isOpen }) =>
    isLeft
      ? css`
          right: -4px;
        `
      : css`
          left: ${isOpen ? "-4px" : "8px"};
        `}
`;

const ScrollContainer = styled.div`
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.colors.opacity(theme.colors.cloud, 0.2)};
    border-radius: 4px;
    border: 4px solid transparent;
    background-clip: content-box;
  }
`;

const WelcomeHeader = styled.div<{ isLeft: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.night};
  display: flex;
  justify-content: flex-start;
  min-height: ${({ isLeft }) => (isLeft ? "82px" : "114px")};
  padding: 12px 24px;
`;

const HeaderText = styled(Typography)<{ isLeft: boolean }>`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.colors.snowdrop};
  font-weight: 800 !important;
  padding-right: ${({ isLeft }) => (isLeft ? 0 : "8px")};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const HeaderTextName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const HeaderTextEmoji = styled.span`
  margin-left: 4px;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WelcomeShadow = styled.div`
  border-bottom: 4px solid
    ${({ theme }) => theme.colors.opacity(theme.colors.charcoal, 0.3)};
  mix-blend-mode: multiply;
`;

type DrawerProps = {
  children: React.ReactNode;
  displayName: string | null;
  isFocusTrapDisabled: boolean;
  isMobile: boolean;
  isOpen: boolean;
  isOverlayNav: boolean;
  onClose: () => void;
};

export const Drawer = ({
  children,
  displayName,
  isFocusTrapDisabled,
  isMobile,
  isOpen,
  isOverlayNav,
  onClose,
}: DrawerProps) => {
  const isOverlayDrawerOpen = isOverlayNav && isOpen;
  const _isOverlayNav = isMobile || isOverlayNav;
  const isLeft = !isOverlayNav || !_isOverlayNav;

  usePreventScroll({
    isDisabled: !isOverlayDrawerOpen,
  });

  const welcomeMessage = displayName || "Welcome";

  const render = (
    <>
      <DrawerShadow isLeft={isLeft} isOpen={isOpen} isMobile={_isOverlayNav} />
      <WelcomeHeader isLeft={isLeft}>
        <HeaderText as="h2" use="label" isLeft={isLeft}>
          <HeaderTextName>{welcomeMessage}</HeaderTextName>
          <HeaderTextEmoji>👋</HeaderTextEmoji>
        </HeaderText>
        {!isLeft && (
          <CloseButtonWrapper>
            <Hamburger.Closed onClickMenuIcon={onClose} />
          </CloseButtonWrapper>
        )}
      </WelcomeHeader>
      <WelcomeShadow />
      <ScrollContainer>{children}</ScrollContainer>
    </>
  );

  const RootComponent = isLeft ? RootContainerProfile : RootContainer;

  return (
    <>
      {_isOverlayNav && (
        <Scrim className="nav-drawer-scrim" isOpen={isOpen} onClick={onClose} />
      )}

      <FocusTrap
        active={isOpen && !isFocusTrapDisabled}
        focusTrapOptions={{ clickOutsideDeactivates: true }}
      >
        <RootComponent isOpen={isOpen}>{render}</RootComponent>
      </FocusTrap>
    </>
  );
};
