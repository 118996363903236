import React from "react";
import styled from "@emotion/styled";
import { Typography } from "../Typography";

export type ZoneCardProps = {
  title?: string;
  children: React.ReactNode;
  subdued?: boolean;
  isDisabled?: boolean;
  className?: string;
};

const Outer = styled.div<{ subdued?: boolean; isDisabled?: boolean }>`
  border-radius: 3px;
  background-color: ${(props) =>
    props.subdued ? "rgb(250 251 251)" : props.theme.colors.snowdrop};
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  outline: 0.1rem solid transparent;
  overflow: hidden;
  cursor: ${(props) => props.isDisabled && "not-allowed"};
  border: ${(props) =>
    props.isDisabled && `0.2rem solid ${props.theme.colors.scarletDark}`};
`;

const Heading = styled(Typography)<{
  hasTitle?: boolean;
  isDisabled?: boolean;
}>`
  padding: 25px 25px 0px;
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.colors.cloudDark
      : props.theme.colors.snowdrop};
  text-decoration: ${(props) => props.isDisabled && "line-through"};
`;

const Children = styled(Typography)`
  padding: 12px 0px 0px;
`;

export const ZoneCard = ({
  title,
  children,
  subdued,
  className,
  isDisabled = false,
}: ZoneCardProps) => {
  return (
    <Outer subdued={subdued} isDisabled={isDisabled} className={className}>
      {title && (
        <Heading use="body2" as="h2" isDisabled={isDisabled}>
          {title}
        </Heading>
      )}
      {children}
    </Outer>
  );
};

export type ZoneCardSectionProps = {
  title?: string;
  subdued?: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
  className?: string;
};

const SectionOuter = styled.div<{ subdued?: boolean; isDisabled?: boolean }>`
  padding: 13px 25px 25px 25px;
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.colors.cloudDark
      : props.subdued
      ? "#fafbfb"
      : props.theme.colors.snowdrop};
  border-top: ${(props) => (props.subdued ? "0.1rem solid #dfe3e8" : null)};
`;

const SectionTitle = styled(Typography)`
  text-transform: uppercase;
`;

export const Section = ({
  children,
  title,
  subdued,
  isDisabled = false,
  className,
}: ZoneCardSectionProps) => {
  return (
    <SectionOuter
      subdued={subdued}
      isDisabled={isDisabled}
      className={className}
    >
      <SectionTitle use="label" as="p">
        {title}
      </SectionTitle>
      <Children use="label" as="p">
        {children}
      </Children>
    </SectionOuter>
  );
};

ZoneCard.Section = Section;
