/**
 * Do not edit directly
 * Generated on Thu, 02 Sep 2021 09:47:18 GMT
 */

export const DashboardChartTextMobileFontFamily = "Sofia Pro";
export const DashboardChartTextMobileFontWeight = 400;
export const DashboardChartTextMobileFontSize = 10;
export const DashboardChartTextMobileLetterSpacing = 0;
export const DashboardChartTextMobileLineHeightPx = 12;
export const DashboardChartTextMobileTextDecoration = "initial";
export const DashboardChartTextMobileTag = "A";
export const DashboardChartTextMobileColor = "rgba(82, 88, 91, 1)";
export const DashboardChartTextDesktopFontFamily = "Sofia Pro";
export const DashboardChartTextDesktopFontWeight = 400;
export const DashboardChartTextDesktopFontSize = 10;
export const DashboardChartTextDesktopLetterSpacing = 0;
export const DashboardChartTextDesktopLineHeightPx = 12;
export const DashboardChartTextDesktopTextDecoration = "initial";
export const DashboardChartTextDesktopTag = "A";
export const DashboardChartTextDesktopColor = "rgba(82, 88, 91, 1)";
export const DashboardMicroLinkBoldInlineMobileFontFamily = "Sofia Pro";
export const DashboardMicroLinkBoldInlineMobileFontWeight = 700;
export const DashboardMicroLinkBoldInlineMobileFontSize = 12;
export const DashboardMicroLinkBoldInlineMobileLetterSpacing = 0;
export const DashboardMicroLinkBoldInlineMobileLineHeightPx = 17;
export const DashboardMicroLinkBoldInlineMobileTextDecoration = "underline";
export const DashboardMicroLinkBoldInlineMobileTag = "A";
export const DashboardMicroLinkBoldInlineMobileColor = "rgba(34, 34, 34, 1)";
export const DashboardMicroLinkBoldInlineDesktopFontFamily = "Sofia Pro";
export const DashboardMicroLinkBoldInlineDesktopFontWeight = 700;
export const DashboardMicroLinkBoldInlineDesktopFontSize = 12;
export const DashboardMicroLinkBoldInlineDesktopLetterSpacing = 0;
export const DashboardMicroLinkBoldInlineDesktopLineHeightPx = 17;
export const DashboardMicroLinkBoldInlineDesktopTextDecoration = "underline";
export const DashboardMicroLinkBoldInlineDesktopTag = "A";
export const DashboardMicroLinkBoldInlineDesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardMicroLinkRegularInlineMobileFontFamily = "Sofia Pro";
export const DashboardMicroLinkRegularInlineMobileFontWeight = 400;
export const DashboardMicroLinkRegularInlineMobileFontSize = 12;
export const DashboardMicroLinkRegularInlineMobileLetterSpacing = 0;
export const DashboardMicroLinkRegularInlineMobileLineHeightPx = 17;
export const DashboardMicroLinkRegularInlineMobileTextDecoration = "underline";
export const DashboardMicroLinkRegularInlineMobileTag = "A";
export const DashboardMicroLinkRegularInlineMobileColor = "rgba(34, 34, 34, 1)";
export const DashboardMicroLinkRegularInlineDesktopFontFamily = "Sofia Pro";
export const DashboardMicroLinkRegularInlineDesktopFontWeight = 400;
export const DashboardMicroLinkRegularInlineDesktopFontSize = 12;
export const DashboardMicroLinkRegularInlineDesktopLetterSpacing = 0;
export const DashboardMicroLinkRegularInlineDesktopLineHeightPx = 17;
export const DashboardMicroLinkRegularInlineDesktopTextDecoration = "underline";
export const DashboardMicroLinkRegularInlineDesktopTag = "A";
export const DashboardMicroLinkRegularInlineDesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardMicroCopyBoldMobileFontFamily = "Sofia Pro";
export const DashboardMicroCopyBoldMobileFontWeight = 700;
export const DashboardMicroCopyBoldMobileFontSize = 12;
export const DashboardMicroCopyBoldMobileLetterSpacing = 0;
export const DashboardMicroCopyBoldMobileLineHeightPx = 17;
export const DashboardMicroCopyBoldMobileTextDecoration = "initial";
export const DashboardMicroCopyBoldMobileTag = "P";
export const DashboardMicroCopyBoldMobileColor = "rgba(34, 34, 34, 1)";
export const DashboardMicroCopyBoldDesktopFontFamily = "Sofia Pro";
export const DashboardMicroCopyBoldDesktopFontWeight = 700;
export const DashboardMicroCopyBoldDesktopFontSize = 12;
export const DashboardMicroCopyBoldDesktopLetterSpacing = 0;
export const DashboardMicroCopyBoldDesktopLineHeightPx = 17;
export const DashboardMicroCopyBoldDesktopTextDecoration = "initial";
export const DashboardMicroCopyBoldDesktopTag = "P";
export const DashboardMicroCopyBoldDesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardMicroCopyRegularMobileFontFamily = "Sofia Pro";
export const DashboardMicroCopyRegularMobileFontWeight = 400;
export const DashboardMicroCopyRegularMobileFontSize = 12;
export const DashboardMicroCopyRegularMobileLetterSpacing = 0;
export const DashboardMicroCopyRegularMobileLineHeightPx = 17;
export const DashboardMicroCopyRegularMobileTextDecoration = "initial";
export const DashboardMicroCopyRegularMobileTag = "P";
export const DashboardMicroCopyRegularMobileColor = "rgba(34, 34, 34, 1)";
export const DashboardMicroCopyRegularDesktopFontFamily = "Sofia Pro";
export const DashboardMicroCopyRegularDesktopFontWeight = 400;
export const DashboardMicroCopyRegularDesktopFontSize = 12;
export const DashboardMicroCopyRegularDesktopLetterSpacing = 0;
export const DashboardMicroCopyRegularDesktopLineHeightPx = 17;
export const DashboardMicroCopyRegularDesktopTextDecoration = "initial";
export const DashboardMicroCopyRegularDesktopTag = "P";
export const DashboardMicroCopyRegularDesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardMicroTitleMobileFontFamily = "Sofia Pro";
export const DashboardMicroTitleMobileFontWeight = 700;
export const DashboardMicroTitleMobileFontSize = 14;
export const DashboardMicroTitleMobileLetterSpacing = 0;
export const DashboardMicroTitleMobileLineHeightPx = 20;
export const DashboardMicroTitleMobileTextDecoration = "initial";
export const DashboardMicroTitleMobileTag = "P";
export const DashboardMicroTitleMobileColor = "rgba(34, 34, 34, 1)";
export const DashboardMicroTitleDesktopFontFamily = "Sofia Pro";
export const DashboardMicroTitleDesktopFontWeight = 700;
export const DashboardMicroTitleDesktopFontSize = 14;
export const DashboardMicroTitleDesktopLetterSpacing = 0;
export const DashboardMicroTitleDesktopLineHeightPx = 20;
export const DashboardMicroTitleDesktopTextDecoration = "initial";
export const DashboardMicroTitleDesktopTag = "P";
export const DashboardMicroTitleDesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardSubtitleMobileFontFamily = "Sofia Pro";
export const DashboardSubtitleMobileFontWeight = 300;
export const DashboardSubtitleMobileFontSize = 16;
export const DashboardSubtitleMobileLetterSpacing = 0;
export const DashboardSubtitleMobileLineHeightPx = 20;
export const DashboardSubtitleMobileTextDecoration = "initial";
export const DashboardSubtitleMobileTag = "P";
export const DashboardSubtitleMobileColor = "rgba(34, 34, 34, 1)";
export const DashboardSubtitleDesktopFontFamily = "Sofia Pro";
export const DashboardSubtitleDesktopFontWeight = 300;
export const DashboardSubtitleDesktopFontSize = 16;
export const DashboardSubtitleDesktopLetterSpacing = 0;
export const DashboardSubtitleDesktopLineHeightPx = 20;
export const DashboardSubtitleDesktopTextDecoration = "initial";
export const DashboardSubtitleDesktopTag = "P";
export const DashboardSubtitleDesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardStandaloneLinkMobileFontFamily = "Sofia Pro";
export const DashboardStandaloneLinkMobileFontWeight = 700;
export const DashboardStandaloneLinkMobileFontSize = 14;
export const DashboardStandaloneLinkMobileLetterSpacing = 0;
export const DashboardStandaloneLinkMobileLineHeightPx = 20;
export const DashboardStandaloneLinkMobileTextDecoration = "underline";
export const DashboardStandaloneLinkMobileTag = "A";
export const DashboardStandaloneLinkMobileColor = "rgba(34, 34, 34, 1)";
export const DashboardStandaloneLinkDesktopFontFamily = "Sofia Pro";
export const DashboardStandaloneLinkDesktopFontWeight = 700;
export const DashboardStandaloneLinkDesktopFontSize = 14;
export const DashboardStandaloneLinkDesktopLetterSpacing = 0;
export const DashboardStandaloneLinkDesktopLineHeightPx = 20;
export const DashboardStandaloneLinkDesktopTextDecoration = "underline";
export const DashboardStandaloneLinkDesktopTag = "A";
export const DashboardStandaloneLinkDesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardBodyMobileFontFamily = "Sofia Pro";
export const DashboardBodyMobileFontWeight = 400;
export const DashboardBodyMobileFontSize = 14;
export const DashboardBodyMobileLetterSpacing = 0;
export const DashboardBodyMobileLineHeightPx = 20;
export const DashboardBodyMobileTextDecoration = "initial";
export const DashboardBodyMobileTag = "A";
export const DashboardBodyMobileColor = "rgba(34, 34, 34, 1)";
export const DashboardBodyDesktopFontFamily = "Sofia Pro";
export const DashboardBodyDesktopFontWeight = 400;
export const DashboardBodyDesktopFontSize = 14;
export const DashboardBodyDesktopLetterSpacing = 0;
export const DashboardBodyDesktopLineHeightPx = 20;
export const DashboardBodyDesktopTextDecoration = "initial";
export const DashboardBodyDesktopTag = "A";
export const DashboardBodyDesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardTitle3MobileFontFamily = "Sofia Pro";
export const DashboardTitle3MobileFontWeight = 700;
export const DashboardTitle3MobileFontSize = 18;
export const DashboardTitle3MobileLetterSpacing = 0;
export const DashboardTitle3MobileLineHeightPx = 23;
export const DashboardTitle3MobileTextDecoration = "initial";
export const DashboardTitle3MobileTag = "P";
export const DashboardTitle3MobileColor = "rgba(34, 34, 34, 1)";
export const DashboardTitle3DesktopFontFamily = "Sofia Pro";
export const DashboardTitle3DesktopFontWeight = 700;
export const DashboardTitle3DesktopFontSize = 18;
export const DashboardTitle3DesktopLetterSpacing = 0;
export const DashboardTitle3DesktopLineHeightPx = 23;
export const DashboardTitle3DesktopTextDecoration = "initial";
export const DashboardTitle3DesktopTag = "P";
export const DashboardTitle3DesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardTitle2MobileFontFamily = "Sofia Pro";
export const DashboardTitle2MobileFontWeight = 700;
export const DashboardTitle2MobileFontSize = 24;
export const DashboardTitle2MobileLetterSpacing = 0;
export const DashboardTitle2MobileLineHeightPx = 31;
export const DashboardTitle2MobileTextDecoration = "initial";
export const DashboardTitle2MobileTag = "P";
export const DashboardTitle2MobileColor = "rgba(34, 34, 34, 1)";
export const DashboardTitle2DesktopFontFamily = "Sofia Pro";
export const DashboardTitle2DesktopFontWeight = 700;
export const DashboardTitle2DesktopFontSize = 24;
export const DashboardTitle2DesktopLetterSpacing = 0;
export const DashboardTitle2DesktopLineHeightPx = 31;
export const DashboardTitle2DesktopTextDecoration = "initial";
export const DashboardTitle2DesktopTag = "P";
export const DashboardTitle2DesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardTitle1MobileFontFamily = "Sofia Pro";
export const DashboardTitle1MobileFontWeight = 700;
export const DashboardTitle1MobileFontSize = 30;
export const DashboardTitle1MobileLetterSpacing = 0;
export const DashboardTitle1MobileLineHeightPx = 36;
export const DashboardTitle1MobileTextDecoration = "initial";
export const DashboardTitle1MobileTag = "P";
export const DashboardTitle1MobileColor = "rgba(34, 34, 34, 1)";
export const DashboardTitle1DesktopFontFamily = "Sofia Pro";
export const DashboardTitle1DesktopFontWeight = 700;
export const DashboardTitle1DesktopFontSize = 30;
export const DashboardTitle1DesktopLetterSpacing = 0;
export const DashboardTitle1DesktopLineHeightPx = 36;
export const DashboardTitle1DesktopTextDecoration = "initial";
export const DashboardTitle1DesktopTag = "P";
export const DashboardTitle1DesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardCardHeaderInlineLinkMobileFontFamily = "Sofia Pro";
export const DashboardCardHeaderInlineLinkMobileFontWeight = 400;
export const DashboardCardHeaderInlineLinkMobileFontSize = 16;
export const DashboardCardHeaderInlineLinkMobileLetterSpacing = 0;
export const DashboardCardHeaderInlineLinkMobileLineHeightPx = 20;
export const DashboardCardHeaderInlineLinkMobileTextDecoration = "underline";
export const DashboardCardHeaderInlineLinkMobileTag = "P";
export const DashboardCardHeaderInlineLinkMobileColor = "rgba(34, 34, 34, 1)";
export const DashboardCardHeaderInlineLinkDesktopFontFamily = "Sofia Pro";
export const DashboardCardHeaderInlineLinkDesktopFontWeight = 400;
export const DashboardCardHeaderInlineLinkDesktopFontSize = 16;
export const DashboardCardHeaderInlineLinkDesktopLetterSpacing = 0;
export const DashboardCardHeaderInlineLinkDesktopLineHeightPx = 20;
export const DashboardCardHeaderInlineLinkDesktopTextDecoration = "underline";
export const DashboardCardHeaderInlineLinkDesktopTag = "P";
export const DashboardCardHeaderInlineLinkDesktopColor = "rgba(34, 34, 34, 1)";
export const DashboardCardHeaderMobileFontFamily = "Sofia Pro";
export const DashboardCardHeaderMobileFontWeight = 400;
export const DashboardCardHeaderMobileFontSize = 16;
export const DashboardCardHeaderMobileLetterSpacing = 0;
export const DashboardCardHeaderMobileLineHeightPx = 20;
export const DashboardCardHeaderMobileTextDecoration = "initial";
export const DashboardCardHeaderMobileTag = "P";
export const DashboardCardHeaderMobileColor = "rgba(34, 34, 34, 1)";
export const DashboardCardHeaderDesktopFontFamily = "Sofia Pro";
export const DashboardCardHeaderDesktopFontWeight = 400;
export const DashboardCardHeaderDesktopFontSize = 16;
export const DashboardCardHeaderDesktopLetterSpacing = 0;
export const DashboardCardHeaderDesktopLineHeightPx = 20;
export const DashboardCardHeaderDesktopTextDecoration = "initial";
export const DashboardCardHeaderDesktopTag = "P";
export const DashboardCardHeaderDesktopColor = "rgba(34, 34, 34, 1)";
export const TextInputLabelMobileFontFamily = "Sofia Pro";
export const TextInputLabelMobileFontWeight = 700;
export const TextInputLabelMobileFontSize = 16;
export const TextInputLabelMobileLetterSpacing = 0;
export const TextInputLabelMobileLineHeightPx = 20;
export const TextInputLabelMobileTextDecoration = "initial";
export const TextInputLabelMobileTag = "P";
export const TextInputLabelMobileColor = "rgba(34, 34, 34, 1)";
export const TextInputLabelDesktopFontFamily = "Sofia Pro";
export const TextInputLabelDesktopFontWeight = 700;
export const TextInputLabelDesktopFontSize = 16;
export const TextInputLabelDesktopLetterSpacing = 0;
export const TextInputLabelDesktopLineHeightPx = 20;
export const TextInputLabelDesktopTextDecoration = "initial";
export const TextInputLabelDesktopTag = "P";
export const TextInputLabelDesktopColor = "rgba(34, 34, 34, 1)";
export const CaptionMobileFontFamily = "Sofia Pro";
export const CaptionMobileFontWeight = 400;
export const CaptionMobileFontSize = 14;
export const CaptionMobileLetterSpacing = 0;
export const CaptionMobileLineHeightPx = 20;
export const CaptionMobileTextDecoration = "initial";
export const CaptionMobileTag = "P";
export const CaptionMobileColor = "rgba(135, 141, 147, 1)";
export const CaptionDesktopFontFamily = "Sofia Pro";
export const CaptionDesktopFontWeight = 400;
export const CaptionDesktopFontSize = 14;
export const CaptionDesktopLetterSpacing = 0;
export const CaptionDesktopLineHeightPx = 20;
export const CaptionDesktopTextDecoration = "initial";
export const CaptionDesktopTag = "P";
export const CaptionDesktopColor = "rgba(135, 141, 147, 1)";
export const LabelMobileFontFamily = "Sofia Pro";
export const LabelMobileFontWeight = 400;
export const LabelMobileFontSize = 16;
export const LabelMobileLetterSpacing = 0;
export const LabelMobileLineHeightPx = 20;
export const LabelMobileTextDecoration = "initial";
export const LabelMobileTag = "P";
export const LabelMobileColor = "rgba(34, 34, 34, 1)";
export const LabelDesktopFontFamily = "Sofia Pro";
export const LabelDesktopFontWeight = 400;
export const LabelDesktopFontSize = 16;
export const LabelDesktopLetterSpacing = 0;
export const LabelDesktopLineHeightPx = 20;
export const LabelDesktopTextDecoration = "initial";
export const LabelDesktopTag = "P";
export const LabelDesktopColor = "rgba(34, 34, 34, 1)";
export const InlineLinkMobileFontFamily = "Sofia Pro";
export const InlineLinkMobileFontWeight = 400;
export const InlineLinkMobileFontSize = 18;
export const InlineLinkMobileLetterSpacing = 0;
export const InlineLinkMobileLineHeightPx = 29;
export const InlineLinkMobileTextDecoration = "underline";
export const InlineLinkMobileTag = "A";
export const InlineLinkMobileColor = "rgba(3, 95, 72, 1)";
export const InlineLinkDesktopFontFamily = "Sofia Pro";
export const InlineLinkDesktopFontWeight = 400;
export const InlineLinkDesktopFontSize = 18;
export const InlineLinkDesktopLetterSpacing = 0;
export const InlineLinkDesktopLineHeightPx = 29;
export const InlineLinkDesktopTextDecoration = "underline";
export const InlineLinkDesktopTag = "A";
export const InlineLinkDesktopColor = "rgba(3, 95, 72, 1)";
export const StandaloneLinkMobileFontFamily = "Sofia Pro";
export const StandaloneLinkMobileFontWeight = 700;
export const StandaloneLinkMobileFontSize = 18;
export const StandaloneLinkMobileLetterSpacing = 0;
export const StandaloneLinkMobileLineHeightPx = 29;
export const StandaloneLinkMobileTextDecoration = "underline";
export const StandaloneLinkMobileTag = "A";
export const StandaloneLinkMobileColor = "rgba(34, 34, 34, 1)";
export const StandaloneLinkDesktopFontFamily = "Sofia Pro";
export const StandaloneLinkDesktopFontWeight = 700;
export const StandaloneLinkDesktopFontSize = 18;
export const StandaloneLinkDesktopLetterSpacing = 0;
export const StandaloneLinkDesktopLineHeightPx = 29;
export const StandaloneLinkDesktopTextDecoration = "underline";
export const StandaloneLinkDesktopTag = "A";
export const StandaloneLinkDesktopColor = "rgba(34, 34, 34, 1)";
export const Body3MobileFontFamily = "Sofia Pro";
export const Body3MobileFontWeight = 400;
export const Body3MobileFontSize = 14;
export const Body3MobileLetterSpacing = 0;
export const Body3MobileLineHeightPx = 20;
export const Body3MobileTextDecoration = "initial";
export const Body3MobileTag = "P";
export const Body3MobileColor = "rgba(34, 34, 34, 1)";
export const Body3DesktopFontFamily = "Sofia Pro";
export const Body3DesktopFontWeight = 400;
export const Body3DesktopFontSize = 14;
export const Body3DesktopLetterSpacing = 0;
export const Body3DesktopLineHeightPx = 20;
export const Body3DesktopTextDecoration = "initial";
export const Body3DesktopTag = "P";
export const Body3DesktopColor = "rgba(34, 34, 34, 1)";
export const Body2MobileFontFamily = "Sofia Pro";
export const Body2MobileFontWeight = 400;
export const Body2MobileFontSize = 16;
export const Body2MobileLetterSpacing = 0;
export const Body2MobileLineHeightPx = 20;
export const Body2MobileTextDecoration = "initial";
export const Body2MobileTag = "P";
export const Body2MobileColor = "rgba(34, 34, 34, 1)";
export const Body2DesktopFontFamily = "Sofia Pro";
export const Body2DesktopFontWeight = 400;
export const Body2DesktopFontSize = 16;
export const Body2DesktopLetterSpacing = 0;
export const Body2DesktopLineHeightPx = 20;
export const Body2DesktopTextDecoration = "initial";
export const Body2DesktopTag = "P";
export const Body2DesktopColor = "rgba(34, 34, 34, 1)";
export const Body1MobileFontFamily = "Sofia Pro";
export const Body1MobileFontWeight = 400;
export const Body1MobileFontSize = 18;
export const Body1MobileLetterSpacing = 0;
export const Body1MobileLineHeightPx = 29;
export const Body1MobileTextDecoration = "initial";
export const Body1MobileTag = "P";
export const Body1MobileColor = "rgba(34, 34, 34, 1)";
export const Body1DesktopFontFamily = "Sofia Pro";
export const Body1DesktopFontWeight = 400;
export const Body1DesktopFontSize = 18;
export const Body1DesktopLetterSpacing = 0;
export const Body1DesktopLineHeightPx = 29;
export const Body1DesktopTextDecoration = "initial";
export const Body1DesktopTag = "P";
export const Body1DesktopColor = "rgba(34, 34, 34, 1)";
export const Subtitle2MobileFontFamily = "Sofia Pro";
export const Subtitle2MobileFontWeight = 300;
export const Subtitle2MobileFontSize = 22;
export const Subtitle2MobileLetterSpacing = 0;
export const Subtitle2MobileLineHeightPx = 28;
export const Subtitle2MobileTextDecoration = "initial";
export const Subtitle2MobileTag = "H6";
export const Subtitle2MobileColor = "rgba(34, 34, 34, 1)";
export const Subtitle2DesktopFontFamily = "Sofia Pro";
export const Subtitle2DesktopFontWeight = 300;
export const Subtitle2DesktopFontSize = 24;
export const Subtitle2DesktopLetterSpacing = 0;
export const Subtitle2DesktopLineHeightPx = 31;
export const Subtitle2DesktopTextDecoration = "initial";
export const Subtitle2DesktopTag = "H6";
export const Subtitle2DesktopColor = "rgba(34, 34, 34, 1)";
export const Subtitle1MobileFontFamily = "Sofia Pro";
export const Subtitle1MobileFontWeight = 300;
export const Subtitle1MobileFontSize = 22;
export const Subtitle1MobileLetterSpacing = 0;
export const Subtitle1MobileLineHeightPx = 28;
export const Subtitle1MobileTextDecoration = "initial";
export const Subtitle1MobileTag = "H6";
export const Subtitle1MobileColor = "rgba(34, 34, 34, 1)";
export const Subtitle1DesktopFontFamily = "Sofia Pro";
export const Subtitle1DesktopFontWeight = 300;
export const Subtitle1DesktopFontSize = 32;
export const Subtitle1DesktopLetterSpacing = 0;
export const Subtitle1DesktopLineHeightPx = 41;
export const Subtitle1DesktopTextDecoration = "initial";
export const Subtitle1DesktopTag = "H6";
export const Subtitle1DesktopColor = "rgba(34, 34, 34, 1)";
export const ComponentTitleMobileFontFamily = "Sofia Pro";
export const ComponentTitleMobileFontWeight = 700;
export const ComponentTitleMobileFontSize = 22;
export const ComponentTitleMobileLetterSpacing = 0;
export const ComponentTitleMobileLineHeightPx = 28;
export const ComponentTitleMobileTextDecoration = "initial";
export const ComponentTitleMobileTag = "H5";
export const ComponentTitleMobileColor = "rgba(34, 34, 34, 1)";
export const ComponentTitleDesktopFontFamily = "Sofia Pro";
export const ComponentTitleDesktopFontWeight = 700;
export const ComponentTitleDesktopFontSize = 24;
export const ComponentTitleDesktopLetterSpacing = 0;
export const ComponentTitleDesktopLineHeightPx = 31;
export const ComponentTitleDesktopTextDecoration = "initial";
export const ComponentTitleDesktopTag = "H5";
export const ComponentTitleDesktopColor = "rgba(34, 34, 34, 1)";
export const SectionTitleMobileFontFamily = "Sofia Pro";
export const SectionTitleMobileFontWeight = 700;
export const SectionTitleMobileFontSize = 28;
export const SectionTitleMobileLetterSpacing = 0;
export const SectionTitleMobileLineHeightPx = 34;
export const SectionTitleMobileTextDecoration = "initial";
export const SectionTitleMobileTag = "H4";
export const SectionTitleMobileColor = "rgba(34, 34, 34, 1)";
export const SectionTitleDesktopFontFamily = "Sofia Pro";
export const SectionTitleDesktopFontWeight = 700;
export const SectionTitleDesktopFontSize = 32;
export const SectionTitleDesktopLetterSpacing = 0;
export const SectionTitleDesktopLineHeightPx = 38;
export const SectionTitleDesktopTextDecoration = "initial";
export const SectionTitleDesktopTag = "H4";
export const SectionTitleDesktopColor = "rgba(34, 34, 34, 1)";
export const FeatureTitleMobileFontFamily = "Sofia Pro";
export const FeatureTitleMobileFontWeight = 700;
export const FeatureTitleMobileFontSize = 36;
export const FeatureTitleMobileLetterSpacing = 0;
export const FeatureTitleMobileLineHeightPx = 43;
export const FeatureTitleMobileTextDecoration = "initial";
export const FeatureTitleMobileTag = "H3";
export const FeatureTitleMobileColor = "rgba(34, 34, 34, 1)";
export const FeatureTitleDesktopFontFamily = "Sofia Pro";
export const FeatureTitleDesktopFontWeight = 700;
export const FeatureTitleDesktopFontSize = 40;
export const FeatureTitleDesktopLetterSpacing = 0;
export const FeatureTitleDesktopLineHeightPx = 48;
export const FeatureTitleDesktopTextDecoration = "initial";
export const FeatureTitleDesktopTag = "H3";
export const FeatureTitleDesktopColor = "rgba(34, 34, 34, 1)";
export const PageTitleMobileFontFamily = "Sofia Pro";
export const PageTitleMobileFontWeight = 700;
export const PageTitleMobileFontSize = 40;
export const PageTitleMobileLetterSpacing = 0;
export const PageTitleMobileLineHeightPx = 48;
export const PageTitleMobileTextDecoration = "initial";
export const PageTitleMobileTag = "H2";
export const PageTitleMobileColor = "rgba(34, 34, 34, 1)";
export const PageTitleDesktopFontFamily = "Sofia Pro";
export const PageTitleDesktopFontWeight = 700;
export const PageTitleDesktopFontSize = 48;
export const PageTitleDesktopLetterSpacing = 0;
export const PageTitleDesktopLineHeightPx = 58;
export const PageTitleDesktopTextDecoration = "initial";
export const PageTitleDesktopTag = "H2";
export const PageTitleDesktopColor = "rgba(34, 34, 34, 1)";
export const HeroLightMobileFontFamily = "Sofia Pro";
export const HeroLightMobileFontWeight = 300;
export const HeroLightMobileFontSize = 40;
export const HeroLightMobileLetterSpacing = 0;
export const HeroLightMobileLineHeightPx = 48;
export const HeroLightMobileTextDecoration = "initial";
export const HeroLightMobileTag = "H1";
export const HeroLightMobileColor = "rgba(34, 34, 34, 1)";
export const HeroLightDesktopFontFamily = "Sofia Pro";
export const HeroLightDesktopFontWeight = 300;
export const HeroLightDesktopFontSize = 56;
export const HeroLightDesktopLetterSpacing = 0;
export const HeroLightDesktopLineHeightPx = 67;
export const HeroLightDesktopTextDecoration = "initial";
export const HeroLightDesktopTag = "H1";
export const HeroLightDesktopColor = "rgba(34, 34, 34, 1)";
export const HeroTitleMobileFontFamily = "Sofia Pro";
export const HeroTitleMobileFontWeight = 700;
export const HeroTitleMobileFontSize = 40;
export const HeroTitleMobileLetterSpacing = 0;
export const HeroTitleMobileLineHeightPx = 48;
export const HeroTitleMobileTextDecoration = "initial";
export const HeroTitleMobileTag = "H1";
export const HeroTitleMobileColor = "rgba(34, 34, 34, 1)";
export const HeroTitleDesktopFontFamily = "Sofia Pro";
export const HeroTitleDesktopFontWeight = 700;
export const HeroTitleDesktopFontSize = 56;
export const HeroTitleDesktopLetterSpacing = 0;
export const HeroTitleDesktopLineHeightPx = 67;
export const HeroTitleDesktopTextDecoration = "initial";
export const HeroTitleDesktopTag = "H1";
export const HeroTitleDesktopColor = "rgba(34, 34, 34, 1)";