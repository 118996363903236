import { Global, css } from "@emotion/react";

export const GlobalStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url("/fonts/sofia-pro/SofiaProLight.woff2") format("woff2"),
          url("/fonts/sofia-pro/SofiaProLight.woff") format("woff");
      }

      @font-face {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url("/fonts/sofia-pro/SofiaProRegular.woff2") format("woff2"),
          url("/fonts/sofia-pro/SofiaProRegular.woff") format("woff");
      }

      @font-face {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url("/fonts/sofia-pro/SofiaProBold.woff2") format("woff2"),
          url("/fonts/sofia-pro/SofiaProBold.woff") format("woff");
      }
    `}
  />
);
