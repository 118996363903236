import create, { GetState } from "zustand";
import { persist } from "zustand/middleware";
import { IMPACT_SHOP_MESSAGES } from "../features/impact-shop/constants";
import {
  CreateImpactShopGetters,
  CreateImpactShopSetters,
  ImpactShopState,
  ImpactShopStore,
} from "./impact-shop.d";
import {
  findProduct,
  getProductPrice,
  getTotalProductQuantity,
  updateProductQuantity,
} from "./impact-shop.utils";

export const initialImpactShopState: ImpactShopState = {
  currency: "GBP" as const,
  basket: [],
  message: {},
};

// See typings for a more easily digestible summary of all the getters and setters

const getters: CreateImpactShopGetters = (get) => ({
  totalCostOfProduct: (productToFind) => {
    const product = findProduct(get().basket, productToFind);
    return (
      product.quantity *
      product.prices.find((price) => price.currency === get().currency).amount
    );
  },
  totalNumberOfProductsInBasket: () => get().basket.length,
  totalPriceOfBasket: () =>
    get().basket.reduce((sum, product) => {
      const productPrice = getProductPrice(product.prices, get().currency);
      return sum + productPrice * product.quantity;
    }, 0),
  unitPriceOfProduct: (productToFind) => {
    const product = findProduct(get().basket, productToFind);
    return getProductPrice(product.prices, get().currency);
  },
  basketProductsWithCurrency: () => ({
    products: get().basket.map((product) => {
      return [product.id, product.quantity, product.fundedBy];
    }),
    currency: get().currency,
  }),
});

const setters: CreateImpactShopSetters = (set) => ({
  clearMessage: () => set({ message: {} }),
  addProductToBasket: (productToAdd) => {
    return set((state) => {
      const existingProduct = findProduct(state.basket, productToAdd);
      //NOTE: If it is existing product, we need to first check whether we will be able to updateProductQuantity or not due to our maxStock
      const totalProductQuantity = getTotalProductQuantity(
        state.basket,
        productToAdd
      );

      if (
        totalProductQuantity + productToAdd.quantity >
        productToAdd.maxQuantity
      ) {
        return {
          basket: state.basket,
          message: IMPACT_SHOP_MESSAGES.ExceedsStockCapacity,
        };
      }

      if (existingProduct) {
        return {
          basket: updateProductQuantity(
            state.basket,
            existingProduct,
            productToAdd.quantity
          ),
          message: IMPACT_SHOP_MESSAGES.SuccessfullyAddedToBasket,
        };
      }

      return {
        basket: [...state.basket, productToAdd],
        message: IMPACT_SHOP_MESSAGES.SuccessfullyAddedToBasket,
      };
    });
  },
  emptyBasket: () => set({ basket: [] }),
  incrementProductQuantity: (productToUpdate, amount) =>
    set((state) => ({
      basket: updateProductQuantity(state.basket, productToUpdate, amount),
    })),
  removeProductFromBasket: (productToRemove) =>
    set((state) => ({
      basket: [
        ...state.basket.filter(
          (product) =>
            !(
              product.id === productToRemove.id &&
              product.fundedBy === productToRemove.fundedBy
            )
        ),
      ],
    })),
  setCurrency: (currency) => set({ currency }),
  setProductQuantityAs: (productToUpdate, amount) =>
    set((state) => {
      const totalProductQuantity = getTotalProductQuantity(
        state.basket,
        productToUpdate
      );

      if (
        totalProductQuantity - productToUpdate.quantity + amount >
        productToUpdate.maxQuantity
      ) {
        return {
          basket: [...state.basket],
          message: IMPACT_SHOP_MESSAGES.UpdateStockCapacityExceed,
        };
      }

      if (
        totalProductQuantity - productToUpdate.quantity + amount <
        productToUpdate.minQuantity
      ) {
        return {
          basket: [...state.basket],
          message: IMPACT_SHOP_MESSAGES.UpdateStockCapacityBelow,
        };
      }

      return {
        basket: updateProductQuantity(
          state.basket,
          productToUpdate,
          amount,
          true
        ),
      };
    }),
});

const useImpactShopStore = create<ImpactShopStore>(
  // The 'persist' middleware ensures state is saved to localstorage
  persist(
    (set, get) => ({
      ...initialImpactShopState,
      ...getters(get as GetState<ImpactShopStore>),
      ...setters(set),
    }),
    {
      name: "ecologi-impact-shop-store", // Key used for localstorage
    }
  )
);

export const useFetchImpactShopStore = () => useImpactShopStore;
