export const IMPACT_SHOP_QUERY_KEY = "impact-shop-products";

export enum ImpactShopMessagesType {
  Error = "Error",
  Info = "Info",
  Success = "Success",
}

export enum ImpactShopMessages {
  ExceedsStockCapacity = "ExceedsStockCapacity",
  UpdateStockCapacityExceed = "UpdateStockCapacityExceed",
  UpdateStockCapacityBelow = "UpdateStockCapacityBelow",
  SuccessfullyAddedToBasket = "SuccessfullyAddedToBasket",
}

type ImpactShopMessageBody = {
  body: string;
  type: ImpactShopMessagesType;
};

export const IMPACT_SHOP_MESSAGES: Record<
  ImpactShopMessages,
  ImpactShopMessageBody
> = {
  ExceedsStockCapacity: {
    body: "Sorry! We couldn't add this item to your basket as we don't have enough in stock",
    type: ImpactShopMessagesType.Error,
  },
  UpdateStockCapacityExceed: {
    body: "Sorry! We couldn't update this item in your basket as we don't have enough in stock",
    type: ImpactShopMessagesType.Error,
  },
  UpdateStockCapacityBelow: {
    body: "Sorry! We couldn't update this item in your basket as it falls below our minimum quantity",
    type: ImpactShopMessagesType.Error,
  },
  SuccessfullyAddedToBasket: {
    body: "Successfully added to basket",
    type: ImpactShopMessagesType.Success,
  },
} as const;

export type ImpactShopMessage = Partial<
  (typeof IMPACT_SHOP_MESSAGES)[keyof typeof IMPACT_SHOP_MESSAGES]
>;
