import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import useMedia from "react-use/lib/useMedia";
import ReactCountryFlag from "react-country-flag";
import { Drawer, EXPANDED_WIDTH } from "./Drawer";
import { NavItem, NavItemDivider } from "./NavItem";
import { CollapsibleNavItem } from "./CollapsibleNavItemType";
import { TopAppBar, TopAppBarProps } from "./TopAppBar";
import { Typography } from "../Typography";
import { CountryChangeModal } from "../CountryChangeModal";
import { Location } from "@ecologi/shared";
import { Link } from "../Link";
import { COMPRESS_HEADER_BP } from "./Hamburger";
import { NavItemType } from "./navigation";

const ContentContainerInner = styled.main`
  width: 100%;
`;

const ContentContainer = styled.div<{ isLeft: boolean }>`
  z-index: 99;
  width: 100%;
  padding-left: ${({ isLeft }) => (isLeft ? `${EXPANDED_WIDTH(false)}px` : 0)};

  @media ${COMPRESS_HEADER_BP} {
    padding-left: ${({ isLeft }) => (isLeft ? `${EXPANDED_WIDTH(true)}px` : 0)};
  }
`;

const Root = styled.div`
  display: -webkit-box;
  min-height: 100vh;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  margin: 56px 0px 34px 20px;
  &::after {
    content: "";
    background-image: url("/images/app/nav/logo.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 51px;
    width: 100px;
  }
`;

export const CountryWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 30px;
  padding: 34px 24px;
`;

export const CountrySelect = styled.a<{ isLight: boolean | undefined }>`
  border-radius: 3px;
  padding: 3px;
  font-size: 24px;
  text-decoration: none;
  transition: background 250ms ease-in;

  &:hover {
    background: ${({ isLight, theme }) =>
      isLight
        ? theme.colors.opacity(theme.colors.snowdrop, 0.15)
        : theme.colors.opacity(theme.colors.night, 0.05)};
  }

  span {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    height: 16px;
    width: 24px;
    @media ${(props) => props.theme.bp.mobileUp} {
      height: 20px;
      width: 30px;
    }
  }
`;

export type NavigationDrawerProps = {
  /**
   * The config for navigation items
   */
  navItems: NavItemType[][];
  /**
   * The main page content
   */
  children: React.ReactNode;
  /**
   * The navigation drawer has two modes. Either as a fixed position overlay
   * or as left side bar which is in the flow of the page.
   */
  isOverlayNav: boolean;
  /**
   * The root nav item which is selected. There are two types of nav items,
   * Root top level nav items and sub navigation items.
   *
   * `selectedRootNavItemId` is used to determine whether or not the collapsible
   * sub navigation menu is opened or closed based on external state such as
   * NextJS router browser history current route.
   */
  selectedRootNavItemId: string;
  isLoggedViewingProfile: boolean;
  hideHeader?: boolean;
  currentPath: string;
  location: Location;
  displayName: string | null;
  setUserLocation: (update: { countryCode: string }) => void;
  offsetTop?: number;
} & Omit<
  TopAppBarProps,
  | "onClickHomeIcon"
  | "onClickMenuIcon"
  | "isOpen"
  | "isHamburgerVisible"
  | "isLogoVisible"
>;

export function NavigationDrawer({
  currentPath,
  displayName,
  isLight,
  isLoggedViewingProfile,
  isOverlayNav,
  location,
  navItems,
  selectedRootNavItemId,
  setUserLocation,
  hideHeader,
  offsetTop,
  ...props
}: NavigationDrawerProps) {
  // max mobile width is one pixel less than tablet break
  const isMobile = useMedia("(max-width: 767px)");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showChangeLocation, setShowChangeLocation] = useState(false);
  const [isCountryPopupVisible, setCountryPopupState] =
    useState<boolean>(false);
  // Used to determine whether the sub navigation collapsible group is open or closed
  const [_selectedRootNavItemId, setSelectedRootNavItemId] =
    useState<string>("");
  const isLeft = !isOverlayNav && !isMobile;

  useEffect(() => {
    setSelectedRootNavItemId(selectedRootNavItemId);
  }, [selectedRootNavItemId]);

  useEffect(() => {
    setShowChangeLocation(Boolean(location && !displayName));
  }, [location, displayName]);

  const _isOverlayNav = isMobile || isOverlayNav;

  /* Waits until users profile is loaded otherwise hamburger menu renders for a split second when logging in */
  const isHamburgerVisible =
    _isOverlayNav && isLoggedViewingProfile !== undefined;

  return (
    <Root id="__ecologi-root">
      <Drawer
        displayName={displayName}
        isFocusTrapDisabled={isCountryPopupVisible}
        isMobile={isMobile}
        isOpen={isOpen}
        isOverlayNav={isOverlayNav}
        onClose={() => setIsOpen(false)}
      >
        <Navigation>
          {navItems.map((navItemsSection, i) => (
            <React.Fragment key={i}>
              {navItemsSection.map((navItem) =>
                navItem.subMenuItems ? (
                  <CollapsibleNavItem
                    href={navItem.path}
                    isActive={currentPath === navItem.route}
                    isOpen={_selectedRootNavItemId === navItem.id}
                    key={navItem.id}
                    tag={navItem.tag}
                    label={navItem.label}
                    onDropdownClick={() => {
                      const isOpen = _selectedRootNavItemId === navItem.id;
                      setSelectedRootNavItemId(isOpen ? "" : navItem.id);
                    }}
                    onNavigationClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {navItem.subMenuItems.map((subNavItem) => (
                      <NavItem
                        key={subNavItem.id}
                        href={subNavItem.path}
                        isActive={currentPath === subNavItem.route}
                        tag={subNavItem.tag}
                        notification={subNavItem.notification}
                        isSubNavItem
                        onClick={(e) => {
                          e.currentTarget.blur();
                          setIsOpen(false);
                        }}
                      >
                        <Typography use="body1" as="p">
                          {subNavItem.label}
                        </Typography>
                      </NavItem>
                    ))}
                  </CollapsibleNavItem>
                ) : (
                  <NavItem
                    key={navItem.id}
                    href={navItem.path}
                    icon={navItem.icon}
                    tag={navItem.tag}
                    notification={navItem.notification}
                    isActive={currentPath.includes(navItem.path)}
                    onClick={(e) => {
                      e.currentTarget.blur();
                      setIsOpen(false);
                    }}
                    isOpenInNewTab={navItem.isOpenInNewTab}
                  >
                    <Typography use="body1" as="p">
                      {navItem.label}
                    </Typography>
                  </NavItem>
                )
              )}
              {navItems.length !== i + 1 && (
                <NavItemDivider key={`divider-${i}`} />
              )}
            </React.Fragment>
          ))}
          {!displayName && <NavItemDivider />}
        </Navigation>
        {showChangeLocation && (
          <CountryWrapper>
            <CountrySelect
              isLight={isLight}
              href="#switch-region"
              onClick={(e) => {
                e.preventDefault();
                setCountryPopupState(true);
              }}
            >
              <ReactCountryFlag
                countryCode={location?.countryCode || ""}
                aria-label="Country select button"
                style={{
                  height: "20px",
                  width: "30px",
                  verticalAlign: "inherit",
                }}
                svg
              />
            </CountrySelect>
          </CountryWrapper>
        )}
        <Link href="/" title="Go to the Ecologi homepage">
          <Logo />
        </Link>
        <CountryChangeModal
          setUserLocation={setUserLocation}
          location={location}
          onClose={() => setCountryPopupState(false)}
          isOpen={isCountryPopupVisible}
        />
      </Drawer>
      <ContentContainer isLeft={isLeft}>
        <TopAppBar
          {...props}
          isLight={isLight}
          onClickHomeIcon={() => setIsOpen(() => false)}
          onClickMenuIcon={() => setIsOpen((isOpen) => !isOpen)}
          isOpen={isOpen}
          isHamburgerVisible={isHamburgerVisible}
          isLogoVisible={_isOverlayNav && !hideHeader}
          offsetTop={offsetTop}
        />
        <ContentContainerInner>{props.children}</ContentContainerInner>
      </ContentContainer>
    </Root>
  );
}
