export function getFirstQueryParam(params: string | string[]): string {
  return Array.isArray(params) ? params[0] : params;
}

export function parseQueryObject(queryObj: {
  [key: string]: string | string[];
}): { [key: string]: string } {
  return Object.entries(queryObj).reduce((result, [key, value]) => {
    return {
      ...result,
      [key]: getFirstQueryParam(value!),
    };
  }, {});
}
