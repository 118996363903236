import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { useButton } from "@react-aria/button";
import { Banner } from "./Banner";
import { Toggle } from "../Toggle";
import { Modal } from "../DeprecatedModal";
import { Button } from "../Button";

const TableSection = styled.div`
  color: "#000000";
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 32px;
  margin-top: 24px;
  row-gap: 46px;

  @media ${(props) => props.theme.bp.tabletUp} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${(props) => props.theme.bp.desktopUp} {
    row-gap: 16px;
    margin-top: 48px;
  }

  table,
  th,
  td {
    border: 1px solid ${(props) => props.theme.colors.charcoal};
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
`;

const TableCell = styled.td`
  text-align: left;
  padding: 8px;
  &::last-of-type {
    padding-right: 0;
  }
`;

const ToggleSectionWrapper = styled.div`
  display: flex;
  max-height: 100px;
  align-items: center;
`;

const TableSectionTitle = styled(Banner.Text)`
  margin-right: auto;
  padding-bottom: 24px;
  color: ${(props) => props.theme.colors.charcoal};
  line-height: 18px !important;
  @media ${(props) => props.theme.bp.mobileDown} {
    max-width: 170px;
  }
`;

const NecessaryHeader = styled.div`
  border: 1px solid ${(props) => props.theme.colors.charcoal};
  border-bottom: 0px;
  padding: 16px 8px;
`;

const Wrapper = styled.div`
  max-height: calc(100vh - 32px);
  padding: 16px;
  max-width: 90vw;
  @media ${(props) => props.theme.bp.mobileDown} {
    padding: 24px;
  }
  @media ${(props) => props.theme.bp.mobileUp} {
    padding: 24px;
  }
  @media ${(props) => props.theme.bp.tabletUp} {
    padding: 40px;
  }
  @media ${(props) => props.theme.bp.desktopUp} {
    max-width: 1056px;
    padding: 28px 40px 2px 40px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  margin-left: auto;
  max-width: 235px;
  padding-bottom: 38px;
  @media ${(props) => props.theme.bp.mobileDown} {
    padding-bottom: 110px;
  }
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const ToggleText = styled.div<{ $disabled?: boolean }>`
  padding-bottom: 24px;
  color: ${(props) => (props.$disabled ? "#9FA6AD" : "#222")};
`;

const TitleWrapper = styled.div`
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  margin-top: 22px;
  @media ${(props) => props.theme.bp.mobileDown} {
    width: 300px;
    margin-top: 32px;
  }
  line-height: 28px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export type ManagePreferencesProps = {
  onSavePreferences: (cookieStatus: boolean) => void;
  onAccept: () => void;
  setPreferencesOpen: (p: boolean) => void;
};

export function ManagePreferences(props: ManagePreferencesProps) {
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const [cookieConsentChecked, setCookieConsentChecked] = useState(false);

  const { buttonProps: saveButtonProps } = useButton(
    { onPress: () => props.onSavePreferences(cookieConsentChecked) },
    saveButtonRef
  );

  return (
    <Modal
      isOpen
      onClose={() => {
        props.setPreferencesOpen(false);
      }}
      withCloseText
    >
      <Wrapper>
        <TitleWrapper>Manage your cookie preferences</TitleWrapper>
        <TableSection>
          <div>
            <ToggleSectionWrapper>
              <TableSectionTitle use="body2">Necessary</TableSectionTitle>
              <ToggleWrapper>
                <Toggle
                  name="Necessary"
                  onChange={() => null}
                  value
                  isDisabled
                  disableWidth
                  disabledColorVariant={"cloudDarker"}
                />
                <ToggleText $disabled>On</ToggleText>
              </ToggleWrapper>
            </ToggleSectionWrapper>
            <NecessaryHeader>
              We use these cookies to help operate the site. Because these
              cookies are necessary, you can’t turn them off.
            </NecessaryHeader>
            <table>
              <tbody>
                <tr>
                  <TableCell>Ecologi internal</TableCell>
                  <TableCell>Authentication</TableCell>
                </tr>
                <tr>
                  <TableCell>Stripe</TableCell>
                  <TableCell>Operational - payment solution</TableCell>
                </tr>
                <tr>
                  <TableCell>VWO</TableCell>
                  <TableCell>
                    Operational - Behavioural analytics and website journey
                    optimisation
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Trustpilot</TableCell>
                  <TableCell>Website design element</TableCell>
                </tr>
                <tr>
                  <TableCell>Floodlight</TableCell>
                  <TableCell>Advertising & marketing</TableCell>
                </tr>
                <tr>
                  <TableCell>Google reCAPTCHA</TableCell>
                  <TableCell>Website security element</TableCell>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <ToggleSectionWrapper>
              <TableSectionTitle use="body2">
                Analytics and marketing
              </TableSectionTitle>
              <ToggleWrapper>
                <Toggle
                  name="Analytics and marketing"
                  onChange={() =>
                    setCookieConsentChecked((prevState) => !prevState)
                  }
                  value={cookieConsentChecked}
                  disableWidth
                />
                <ToggleText>{cookieConsentChecked ? "On" : "Off"}</ToggleText>
              </ToggleWrapper>
            </ToggleSectionWrapper>

            <table>
              <tbody>
                <tr>
                  <TableCell>Google</TableCell>
                  <TableCell>Advertising &amp; analytics</TableCell>
                </tr>
                <tr>
                  <TableCell>Facebook &amp; Instagram</TableCell>
                  <TableCell>Marketing &amp; community</TableCell>
                </tr>
                <tr>
                  <TableCell>LinkedIn</TableCell>
                  <TableCell>Marketing &amp; community</TableCell>
                </tr>
                <tr>
                  <TableCell>Twitter</TableCell>
                  <TableCell>Marketing &amp; community</TableCell>
                </tr>
                <tr>
                  <TableCell>Bing</TableCell>
                  <TableCell>Marketing &amp; community</TableCell>
                </tr>
                <tr>
                  <TableCell>HubSpot</TableCell>
                  <TableCell>
                    Customer support platform, conversion tracking & analytics
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Hotjar</TableCell>
                  <TableCell>Website analytics</TableCell>
                </tr>
                <tr>
                  <TableCell>Bombora</TableCell>
                  <TableCell>Advertising &amp; analytics</TableCell>
                </tr>
                <tr>
                  <TableCell>Flashtalking</TableCell>
                  <TableCell>Ad server</TableCell>
                </tr>
              </tbody>
            </table>
          </div>
        </TableSection>
        <ButtonWrapper>
          <Button
            {...saveButtonProps}
            text="Save your preferences"
            primary
            lightGreen
            fullWidth
            ref={saveButtonRef}
          />
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
}
