export const breakDashboardPageDesktop = "1330px";
export const dashboardPageDesktop = `only screen and (min-width: ${breakDashboardPageDesktop})`;

export const breakExtraWideDesktop = "1920px";
export const breakVeryWideDesktop = "1600px";
export const breakWiderDesktop = "1440px";
export const breakWideDesktop = "1366px";
export const breakDesktop = "1024px";
export const breakTablet = "768px";
export const breakMobile = "480px";
export const breakSmallMobile = "320px";
export const extraWideDesktopUp = `only screen and (min-width: ${breakExtraWideDesktop})`;
export const veryWideDesktopUp = `only screen and (min-width: ${breakVeryWideDesktop})`;
export const widerDesktopUp = `only screen and (min-width: ${breakWiderDesktop})`;
export const wideDesktopUp = `only screen and (min-width: ${breakWideDesktop})`;
export const wideDesktopDown = `only screen and (max-width: ${breakWideDesktop})`;
export const desktopUp = `only screen and (min-width: ${breakDesktop})`;
export const desktopDown = `only screen and (max-width: ${breakDesktop})`;
export const tabletUp = `only screen and (min-width: ${breakTablet})`;
export const tabletDown = `only screen and (max-width: ${breakTablet})`;
export const mobileUp = `only screen and (min-width: ${breakMobile})`;
export const mobileDown = `only screen and (max-width: ${breakMobile})`;
export const smallMobileUp = `only screen and (min-width: ${breakSmallMobile})`;

/* [FIGMA NEW DESIGNS] - Wrapper component break points   */
export const breakExtraSmall = "582px";
export const extraSmallUp = `only screen and (min-width: ${breakExtraSmall})`;
export const extraSmallDown = `only screen and (max-width: ${breakExtraSmall})`;
