import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import * as allTokens from "@ecologi/design-system";
import { TypographyTypes } from "@ecologi/design-system/dist/all-typography-types";
import { EcologiTheme } from "../theme";

export type TypographyBaseProps = { use: TypographyTypes };

const formatTypographyType = (type: TypographyTypes) =>
  type.charAt(0).toUpperCase() + type.slice(1);

const typographyCss = (
  props: TypographyBaseProps & { theme: EcologiTheme }
) => {
  const typographyType = formatTypographyType(props.use);

  return css`
    font-family: ${allTokens[`${typographyType}MobileFontFamily`]};
    color: ${allTokens[`${typographyType}MobileColor`]};
    text-decoration: ${allTokens[`${typographyType}MobileTextDecoration`]};
    font-weight: ${allTokens[`${typographyType}MobileFontWeight`]};
    font-size: ${allTokens[`${typographyType}MobileFontSize`]}px;
    letter-spacing: ${allTokens[`${typographyType}MobileLetterSpacing`]}px;
    line-height: ${allTokens[`${typographyType}MobileLineHeightPx`]}px;

    @media ${props.theme.bp.tabletUp} {
      font-weight: ${allTokens[`${typographyType}DesktopFontWeight`]};
      font-size: ${allTokens[`${typographyType}DesktopFontSize`]}px;
      letter-spacing: ${allTokens[`${typographyType}DesktopLetterSpacing`]}px;
      line-height: ${allTokens[`${typographyType}DesktopLineHeightPx`]}px;
    }
  `;
};

export const TypographyBase = styled.p<TypographyBaseProps>`
  ${typographyCss}
`;

export type TypographyProps = {
  className?: string;
  as?: React.ElementType<any>;
  children?: React.ReactNode | React.ReactNode[];
  href?: string;
  rel?: string;
  style?: object;
  title?: string;
  target?: "_blank";
  htmlFor?: string;
  id?: string;
  dangerouslySetInnerHTML?: any;
} & TypographyBaseProps;

export function Typography(props: TypographyProps) {
  const typographyType = formatTypographyType(props.use);
  const tag = allTokens[`${typographyType}MobileTag`].toLowerCase();

  return <TypographyBase as={tag} {...props} />;
}
