import fetch from "cross-fetch";
import isEmpty from "lodash/isEmpty";

export async function getBanners() {
  try {
    const response = await fetch(`/api/banners`);
    const data = await response.json();
    return isEmpty(data) ? null : data;
  } catch (err) {
    console.error(err);
  }
}
