import { useRouter } from "next/router";
import { useEffect } from "react";
import { controlChatWidget } from "./control-chat-widget";

/** URLs which show the chatbot are configured in the HubSpot Chatflow settings.
 *
 * SPAs need to refresh the widget on route change to check the settings for the current (new) URL.
 *
 * The widget is closed before the route is changed to ensure that the widget opens correctly when next requested.
 * (Issues can arise if an 'open' request is made on a widget that was not properly closed pre-route-change)
 */
export function useTrackChatVisibility() {
  const router = useRouter();

  const closeChat = () => controlChatWidget("close");
  const refreshChat = () => controlChatWidget("refresh");

  useEffect(() => {
    router.events.on("routeChangeStart", closeChat);
    router.events.on("routeChangeComplete", refreshChat);

    return () => {
      router.events.off("routeChangeStart", closeChat);
      router.events.off("routeChangeComplete", refreshChat);
    };
  }, [router.events]);
}
