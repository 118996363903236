import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { isBrowser } from "../../lib";
import { useStore } from "../../store";
import { CookieConsentStatus } from "../../store/index.d";

export const useEmailPopup = () => {
  const router = useRouter();

  const isInstalledOnDevice =
    isBrowser() &&
    (window.navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches);

  const {
    isCheckingUserSession,
    user,
    hasSeenEmailPopup,
    cookieConsentStatus,
  } = useStore();

  const [isEmailPopupVisible, setEmailPopupVisibility] = useState(false);
  const [isChangingPopupVisibility, setIsChangingPopupVisibility] =
    useState<NodeJS.Timeout | null>(null);

  // We don't want to show the newsletter signup popup on gift redeem or sign-up pages
  const isPlanPage =
    router.pathname === "/plan" || router.pathname === "/business/checkout";
  const isBusinessPage = router.pathname === "/business";
  const isGiftingRedeemPage = router.pathname === "/redeem";
  const isImpactOnlyPage = router.pathname === "/pay-as-you-go";

  const isPopupAllowed =
    !isEmailPopupVisible &&
    !isCheckingUserSession &&
    !user &&
    !isInstalledOnDevice &&
    !hasSeenEmailPopup &&
    !isPlanPage &&
    !isImpactOnlyPage &&
    !isGiftingRedeemPage &&
    !isBusinessPage &&
    cookieConsentStatus !== CookieConsentStatus.UNKNOWN &&
    cookieConsentStatus !== CookieConsentStatus.CONSENT_REQUESTED;

  useEffect(() => {
    if (isPopupAllowed) {
      if (!isChangingPopupVisibility) {
        setIsChangingPopupVisibility(
          setTimeout(() => {
            setEmailPopupVisibility(true);
          }, 15e3)
        );
      }
    } else if (isChangingPopupVisibility !== null) {
      clearTimeout(isChangingPopupVisibility);
    }
  }, [
    isPopupAllowed,
    setEmailPopupVisibility,
    isChangingPopupVisibility,
    setIsChangingPopupVisibility,
    cookieConsentStatus,
  ]);

  return [isEmailPopupVisible, setEmailPopupVisibility] as const;
};
