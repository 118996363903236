import React, { forwardRef, Ref } from "react";
import styled from "@emotion/styled";
import CloseIconGraphic from "@ecologi/assets/icons/close-icon.svg";

const Container = styled.div`
  background: ${(props) => props.theme.colors.spruce};
  line-height: 1.4;
  padding: 7px 40px 8px 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 99;
`;

const Content = styled.div`
  color: ${(props) => props.theme.colors.snowdrop};

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const CloseIcon = styled(CloseIconGraphic)`
  cursor: pointer;
  display: block;
  fill: ${(props) => props.theme.colors.snowdrop};
  height: 16px;
  margin: auto;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;

  &:hover {
    opacity: 0.8;
  }
`;

export type BannerProps = {
  content: string;
  onClose: () => void;
};

export const Banner = forwardRef(
  ({ content, onClose }: BannerProps, ref: Ref<HTMLDivElement>) => (
    <Container ref={ref}>
      <Content dangerouslySetInnerHTML={{ __html: content }} />
      <CloseIcon onClick={onClose} />
    </Container>
  )
);
