import { useEffect } from "react";

export const useEscape = (onEscPressed: (e?: KeyboardEvent) => void) => {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onEscPressed(e);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
};
